import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioLaser from './laser.mp3';
Assets.addAudio(AudioType.ShotLaser, {
    path: audioLaser,
    mode: AudioMode.Track3D,
    volume: 0.5,
    poolSize: 4,
    single: true,
});
