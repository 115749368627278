import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { USER_ONLINE_PLAY_SCORE_RESTRICTION } from '~/shared/core/user/const';
import { Utils } from '~/shared/core/utils';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
import IconScore from './icons/score.svg';
export const PlayRestriction = () => {
    const language = useLanguage();
    return (React.createElement("div", { className: styles.container },
        React.createElement(IconLock, null),
        React.createElement("div", { className: styles.text },
            React.createElement("div", { className: styles.large },
                language('Earn'),
                React.createElement(IconScore, null),
                Utils.decorateNumber(USER_ONLINE_PLAY_SCORE_RESTRICTION)),
            React.createElement("div", { className: styles.small }, language('UnlockOnlineModes')))));
};
