import { Shot } from '../..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Line } from '~/client/core/render-item/shape/line';
import './resources';
export class Laser extends Shot {
    constructor(battle, schema) {
        const renderItem = new Line(battle.scene, {
            material: MaterialType.LaserLine,
            position: schema.position,
            positionEnd: schema.positionTarget,
            alpha: 0.5,
        });
        super(battle, {
            renderItem,
            audioEffect: AudioType.ShotLaser,
        }, schema);
        this.schema.positionTarget.onChange(() => {
            this.renderItem.setPositionEnd(schema.positionTarget);
        });
    }
}
