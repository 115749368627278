import cn from 'classnames';
import React from 'react';
import { Content } from './content';
import { Graphic } from './graphic';
import { Title } from './title';
import { Interactive } from '../../../../../core/ui/components/interactive';
import styles from './styles.module.scss';
export const Card = ({ alarm, locked, blink, disabled, children, className, onClick }) => (React.createElement(Interactive, { onClick: onClick, disabled: disabled, className: cn(styles.container, className, {
        [styles.alarm]: alarm,
        [styles.blink]: blink,
        [styles.disabled]: disabled,
        [styles.locked]: locked,
    }) }, children));
Card.Graphic = Graphic;
Card.Title = Title;
Card.Content = Content;
