import { ScreenType } from '../../../../types';
import { BUILDER_TOGGLE_KEY } from '~/client/rooms/battle/builder/ui/components/builder-screen/const';
import { UPGRADES_TOGGLE_KEY } from '~/client/rooms/battle/entity/unit/player/ui/components/upgrades-screen/const';
import IconBuilder from './icons/builder.svg';
import IconUpgrades from './icons/upgrades.svg';
export const SCREEN_BUTTON_ICON_MAP = {
    [ScreenType.Builder]: IconBuilder,
    [ScreenType.Upgrades]: IconUpgrades,
};
export const SCREEN_BUTTON_HOTKEYS = {
    [ScreenType.Builder]: BUILDER_TOGGLE_KEY,
    [ScreenType.Upgrades]: UPGRADES_TOGGLE_KEY,
};
