import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ControlItem } from './item';
import { ActiveOverlay } from '../overlays/state';
import { OverlayType } from '../overlays/types';
import styles from './styles.module.scss';
import IconDiscord from './icons/discord.svg';
import IconSettings from './icons/settings.svg';
export const Controls = () => {
    const [overlay, setOverlay] = useRecoilState(ActiveOverlay);
    const handleClickSettings = useCallback(() => {
        setOverlay(overlay === OverlayType.Settings ? null : OverlayType.Settings);
    }, [overlay]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(ControlItem, { link: "https://discord.gg/cnFAdMsRxn" },
            React.createElement(IconDiscord, null)),
        React.createElement(ControlItem, { onClick: handleClickSettings },
            React.createElement(IconSettings, null))));
};
