import { BuildingAmmunition } from '../variants/ammunition';
import { BuildingGenerator } from '../variants/generator';
import { BuildingRadar } from '../variants/radar';
import { BuildingSpawnerLarge } from '../variants/spawner/large';
import { BuildingSpawnerMedium } from '../variants/spawner/medium';
import { BuildingSpawnerSmall } from '../variants/spawner/small';
import { BuildingTowerFire } from '../variants/tower/fire';
import { BuildingTowerFrost } from '../variants/tower/frost';
import { BuildingTowerLaser } from '../variants/tower/laser';
import { BuildingTrap } from '../variants/trap';
import { BuildingWall } from '../variants/wall';
import { BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
export const BUILDING_INSTANCES = {
    [BuildingVariant.Wall]: BuildingWall,
    [BuildingVariant.TowerFrost]: BuildingTowerFrost,
    [BuildingVariant.TowerFire]: BuildingTowerFire,
    [BuildingVariant.TowerLaser]: BuildingTowerLaser,
    [BuildingVariant.Generator]: BuildingGenerator,
    [BuildingVariant.Ammunition]: BuildingAmmunition,
    [BuildingVariant.Radar]: BuildingRadar,
    [BuildingVariant.SpawnerSmall]: BuildingSpawnerSmall,
    [BuildingVariant.SpawnerMedium]: BuildingSpawnerMedium,
    [BuildingVariant.SpawnerLarge]: BuildingSpawnerLarge,
    [BuildingVariant.Trap]: BuildingTrap,
};
