var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Mesh } from 'three';
import { RenderItem } from '..';
import { SceneLayer } from '../../../rooms/battle/scene/types';
import { Assets } from '../../assets';
export class Shape extends RenderItem {
    constructor(scene, _a) {
        var { geometry, material, alpha = 1.0 } = _a, config = __rest(_a, ["geometry", "material", "alpha"]);
        const object = new Mesh(geometry, Assets.getMaterial(material));
        super(scene, Object.assign(Object.assign({}, config), { object }));
        if (alpha < 1.0) {
            this.setAlpha(alpha);
        }
        this.object.layers.set(SceneLayer.Misc);
    }
    setAlpha(alpha) {
        const material = this.object.material;
        material.transparent = (alpha < 1.0);
        material.opacity = alpha;
        material.depthTest = !material.transparent;
        material.depthWrite = !material.transparent;
    }
}
