import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { RoomContext } from '../room/ui/context';
import './styles';
export class Interface {
    static inject(containerId, Component) {
        const game = document.getElementById('game-system-ui');
        if (!game) {
            throw Error('System UI container isn`t found');
        }
        const container = document.createElement('div');
        container.id = containerId;
        game.prepend(container);
        const root = createRoot(container);
        root.render(Component);
        return {
            remove: () => {
                root.unmount();
                container.remove();
            },
        };
    }
    static mount(Component, room) {
        if (!this.root) {
            const container = document.getElementById('game-ui');
            this.root = createRoot(container);
        }
        this.root.render(React.createElement(RecoilRoot, null,
            React.createElement(RoomContext.Provider, { value: room },
                React.createElement(Component, null))));
    }
    static unmount() {
        var _a;
        (_a = this.root) === null || _a === void 0 ? void 0 : _a.unmount();
        delete this.root;
    }
}
