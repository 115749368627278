import { BuildingTower } from '..';
import { ModelType } from '~/client/core/assets/types';
import './resources';
export class BuildingTowerLaser extends BuildingTower {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingTowerLaser,
        }, schema);
        if (this.selfOwn) {
            this.addParameter(schema.weapon.shot, 'damage');
        }
    }
}
