import React, { useEffect, useState } from 'react';
import { useRoom } from '../../../../../core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Entities = () => {
    const battle = useRoom();
    const [components, setComponents] = useState(battle.entitiesUI);
    const handleEntityChangeUI = () => {
        setComponents(new Map(battle.entitiesUI));
    };
    useEffect(() => {
        const eventEntityChangeUI = battle.events.onEntityChangeUI.on(handleEntityChangeUI);
        return () => {
            eventEntityChangeUI.off();
        };
    }, []);
    return (React.createElement("div", { className: styles.translator }, Array.from(components).map(([entity, Component]) => (React.createElement(Component, { key: entity.renderItem.id, target: entity })))));
};
