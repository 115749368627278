import { Assets } from '../../../../../../../../../core/assets';
import { ModelType } from '../../../../../../../../../core/assets/types';
import bin from './turtle.bin';
import gltf from './turtle.gltf';
Assets.addModel(ModelType.MobTurtle, {
    gltf,
    bin: {
        origin: 'turtle.bin',
        path: bin,
    },
});
