import React, { useEffect } from 'react';
import { useRoom } from '../../../../../../../core/room/ui/hooks/use-room';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { RelayRequest } from '~/shared/rooms/relay/types';
import styles from './styles.module.scss';
import IconSearching from './icons/searching.svg';
export const SearchingOpponent = () => {
    const room = useRoom();
    const language = useLanguage();
    useEffect(() => {
        return () => {
            room.sendRequest(RelayRequest.StopSearchingOpponent);
        };
    }, []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(IconSearching, null),
        React.createElement("div", { className: styles.label }, language('SearchingOpponent'))));
};
