import { UNIT_ROTATE_LERP } from './const';
import { Indicator } from './indicator';
import { Entity } from '..';
import { SceneLayer } from '../../scene/types';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Model } from '~/client/core/render-item/model';
import { VectorUtils } from '~/shared/core/vector-utils';
import { LiveDamageType } from '~/shared/rooms/battle/entity/addons/live/types';
import './resources';
export class Unit extends Entity {
    constructor(battle, config, schema) {
        const renderItem = new Model(battle.scene, Object.assign(Object.assign({}, config), { material: MaterialType.Unit }));
        renderItem.eachMeshes((mesh) => {
            mesh.layers.set(SceneLayer.Unit);
        });
        super(battle, renderItem, schema);
        this.indicator = null;
    }
    destroy() {
        this.removeIndicator();
        super.destroy();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        this.rotateToVelocity();
    }
    onSceneRender() {
        super.onSceneRender();
        this.updateIndicator();
    }
    createIndicator() {
        if (!this.indicator) {
            this.indicator = new Indicator(this);
        }
    }
    updateIndicator() {
        if (this.indicator) {
            this.indicator.update();
        }
    }
    removeIndicator() {
        if (this.indicator) {
            this.indicator.destroy();
            this.indicator = null;
        }
    }
    rotate(angle) {
        this.renderItem.rotate(angle, UNIT_ROTATE_LERP);
    }
    rotateToVelocity() {
        const velocity = VectorUtils.reuse(this.velocity).normalize();
        if (velocity.length() > 0) {
            const angle = Math.atan2(velocity.x, velocity.z);
            this.rotate(angle);
        }
    }
    rotateToPosition(position) {
        const vector = VectorUtils.reuse(position).sub(this.renderItem.position);
        const angle = Math.atan2(vector.x, vector.z);
        this.rotate(angle);
    }
    // private validateSize() {
    //   const round = (size: Vector3Like) => {
    //     const roundTo = 100;
    //     return new Vector3(
    //       Number((Math.round(size.x * roundTo) / roundTo).toFixed(2)),
    //       Number((Math.round(size.y * roundTo) / roundTo).toFixed(2)),
    //       Number((Math.round(size.z * roundTo) / roundTo).toFixed(2)),
    //     );
    //   };
    //   const originSize = round(this.renderItem.size);
    //   const sizeFromConfig = round(this.schema.size);
    //   if (!originSize.equals(sizeFromConfig)) {
    //     Logger.warn([
    //       `Incorrect size for unit '${this.constructor.name}'`,
    //       `Origin: ${originSize.x}, ${originSize.y}, ${originSize.z}`,
    //       `Config: ${sizeFromConfig.x}, ${sizeFromConfig.y}, ${sizeFromConfig.z}`,
    //     ].join('\n'));
    //   }
    // }
    onDamage({ type }) {
        let audio = null;
        switch (type) {
            case LiveDamageType.Fire: {
                audio = AudioType.UnitHitFire;
                break;
            }
            case LiveDamageType.Bite: {
                audio = AudioType.UnitHitBite;
                break;
            }
            case LiveDamageType.Punch: {
                audio = AudioType.UnitHitPunch;
                break;
            }
            default: {
                return;
            }
        }
        this.battle.scene.audio.play3D(audio, {
            parent: this.battle.scene,
            position: this.renderItem.position,
        });
    }
}
