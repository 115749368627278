import { InventoryItemType } from '~/shared/core/user/inventory/item/types';
import { DroidVariant } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/rooms/battle/entity/unit/player/skill/types';
export const INVENTORY_ITEMS = {};
Object.keys(DroidVariant).forEach((variant) => {
    INVENTORY_ITEMS[variant] = InventoryItemType.Droid;
});
Object.keys(SkillVariant).forEach((variant) => {
    INVENTORY_ITEMS[variant] = InventoryItemType.Skill;
});
