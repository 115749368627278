import { BUILDINGS_SHARED_CONFIG } from '../const';
export class BuildingUtils {
    static getUpgradeCost(building, level) {
        const cost = BUILDINGS_SHARED_CONFIG[building.variant].cost;
        const levelCost = cost / 2;
        return levelCost * (level !== null && level !== void 0 ? level : building.level);
    }
    static getRepairCost(building) {
        const damaged = 1 - (building.live.health / building.live.maxHealth);
        return Math.ceil(this.getTotalCost(building) * damaged);
    }
    static getTotalCost(building) {
        let { cost } = BUILDINGS_SHARED_CONFIG[building.variant];
        for (let i = 0; i < building.level - 1; i++) {
            cost += this.getUpgradeCost(building, i + 1);
        }
        return cost;
    }
    static isMaxLevel(building) {
        return building.level >= building.maxLevel;
    }
    static getLevelToUpgrade(building) {
        const minLevel = BUILDINGS_SHARED_CONFIG[building.variant].minLevel;
        return (minLevel || 1) + building.level;
    }
    static hasLevelToUpgrade(building, player) {
        return this.getLevelToUpgrade(building) <= player.level;
    }
}
