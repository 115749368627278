import { MaterialType } from '~/client/core/assets/materials/types';
import { Circle } from '~/client/core/render-item/shape/circle';
import './resources';
export class ActionArea extends Circle {
    constructor(building, radius) {
        super(building.battle.scene, {
            material: MaterialType.ActionArea,
            radius,
            alpha: 0.1,
            visible: false,
        });
        building.renderItem.object.add(this.object);
    }
}
