import { DroidVariant } from './types';
export const DROIDS_SHARED_CONFIG = {
    [DroidVariant.Combat]: {
        cost: 0,
    },
    [DroidVariant.Mechanic]: {
        cost: 1000,
    },
    [DroidVariant.Medic]: {
        cost: 2000,
    },
};
