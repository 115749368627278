import { Vector3 } from 'three';
import { AttackArea } from './attack-area';
import { PLAYER_ATTACK_KEY, PLAYER_MOVEMENT_DIRECTIONS, PLAYER_STEP_DELAY } from './const';
import { PlayerDirection } from './types';
import { PlayerUI } from './ui';
import { Unit } from '..';
import { Light } from '../../../terrain/fog/light';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { AudioType } from '~/client/core/audio/types';
import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { Settings } from '~/client/core/settings';
import { SettingsType } from '~/client/core/settings/types';
import { Delay } from '~/shared/core/delay';
import { DeviceType } from '~/shared/core/device/types';
import { VectorUtils } from '~/shared/core/vector-utils';
import { PLAYER_VISIBLE_DISTANCE } from '~/shared/rooms/battle/entity/unit/player/const';
import { PlayerCheat, PlayerMessage } from '~/shared/rooms/battle/entity/unit/player/types';
import { UnitUtils } from '~/shared/rooms/battle/entity/unit/utils';
import { EntityUtils } from '~/shared/rooms/battle/entity/utils';
import { BattleStage } from '~/shared/rooms/battle/types';
import './resources';
export class Player extends Unit {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.Player,
        }, schema);
        this.directions = new Set();
        this.movingVector = new Vector3();
        this.lastKeys = '';
        this.light = null;
        this.stepsDelay = new Delay(PLAYER_STEP_DELAY);
        this.renderItem.animator.play('idle');
        this.renderItem.setMaterial(this.selfOwn ? MaterialType.Self : MaterialType.Opponent, 'BodyMesh');
        if (!this.schema.bot) {
            this.setUI(PlayerUI);
        }
        this.createAttackArea();
        this.createMarker(1.25);
        if (this.selfOwn) {
            if (this.battle.state.stage !== BattleStage.Finished) {
                this.bindControls();
                this.messages.send(PlayerMessage.Move, {
                    tick: 0,
                    delta: 0,
                });
            }
            const tutorialEnabled = Settings.getBoolean(SettingsType.Tutorial, true);
            this.toggleTutorial(tutorialEnabled);
            this.enablePrediction();
            this.createLight();
            this.battle.scene.audio.setTarget(this.renderItem);
            this.battle.scene.setCameraTarget(this.renderItem);
        }
        else {
            this.enableInterpolation();
            this.createIndicator();
        }
        this.messages.on(PlayerMessage.Attack, () => {
            this.onAttack();
        });
        this.schema.live.listen('health', (health) => {
            if (health <= 0) {
                this.onDead();
            }
        });
        this.onBattleFinish = this.onBattleFinish.bind(this);
        this.battle.events.onFinish.on(this.onBattleFinish);
    }
    destroy() {
        if (this.selfOwn) {
            this.unbindControls();
        }
        this.removeLight();
        this.removeAttackArea();
        this.battle.events.onFinish.off(this.onBattleFinish);
        super.destroy();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        this.updateAttackArea();
        this.playSteps();
        if (this.selfOwn) {
            this.handleMovement();
        }
    }
    createLight() {
        this.light = new Light(this.battle, {
            radius: PLAYER_VISIBLE_DISTANCE,
            target: this.renderItem,
        });
    }
    removeLight() {
        if (this.light) {
            this.light.destroy();
            this.light = null;
        }
    }
    useSkill(variant) {
        this.messages.send(PlayerMessage.UseSkill, { variant });
    }
    upgrade(variant) {
        this.messages.send(PlayerMessage.Upgrade, { variant });
    }
    toggleTutorial(enabled) {
        if (this.schema.tutorial.enabled !== enabled) {
            this.messages.send(PlayerMessage.ToggleTutorial, { enabled });
        }
    }
    attack() {
        this.messages.send(PlayerMessage.Attack, void {});
    }
    bindControls() {
        if (Device.type === DeviceType.Mobile) {
            return;
        }
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onScreenHide = this.onScreenHide.bind(this);
        InputKeyboard.events.onKeyDown.on(this.onKeyDown);
        InputKeyboard.events.onKeyUp.on(this.onKeyUp);
        Device.events.onScreenHide.on(this.onScreenHide);
    }
    unbindControls() {
        if (Device.type === DeviceType.Mobile) {
            return;
        }
        InputKeyboard.events.onKeyDown.off(this.onKeyDown);
        InputKeyboard.events.onKeyUp.off(this.onKeyUp);
        Device.events.onScreenHide.off(this.onScreenHide);
    }
    playSteps() {
        if (this.stepsDelay.isPast() && this.moving) {
            this.playAudio(AudioType.Step);
            this.stepsDelay.next();
        }
    }
    createAttackArea() {
        this.attackArea = new AttackArea(this);
    }
    updateAttackArea() {
        this.attackArea.update();
    }
    removeAttackArea() {
        this.attackArea.destroy();
    }
    getUpgradableValue(progression, type) {
        var _a;
        const level = (_a = this.schema.upgrades.get(type)) !== null && _a !== void 0 ? _a : 1;
        return progression.get(level);
    }
    handleMovement() {
        this.setVelocity(this.movingVector);
        const delta = this.battle.scene.delta;
        if (this.velocity.lengthSq() > 0) {
            this.move(this.velocity, delta);
        }
        const tick = this.prediction.addState(this.velocity, delta);
        this.messages.send(PlayerMessage.Move, { tick, delta });
    }
    move(velocity, delta) {
        const nextPosition = EntityUtils.getNextPosition(this.renderItem.position, velocity, this.schema.speed, delta);
        const lookUpPosition = UnitUtils.getLookUpPosition(nextPosition, this.velocity, this.schema.size);
        if (this.handleCollide(lookUpPosition)) {
            return;
        }
        this.setPosition(nextPosition);
    }
    handleCollide(position) {
        const point = position.clone().round();
        return this.battle.state.terrain.matrix.has(VectorUtils.encode2d(point));
    }
    setMovingVector(vector) {
        this.movingVector.copy(vector);
        this.messages.send(PlayerMessage.ChangeMovingVector, {
            vector: {
                x: vector.x,
                y: vector.z,
            },
        });
    }
    getVectorByDirections() {
        const velocity = new Vector3();
        if (this.directions.has(PlayerDirection.Up)) {
            velocity.x -= 1;
            velocity.z -= 1;
        }
        else if (this.directions.has(PlayerDirection.Down)) {
            velocity.x += 1;
            velocity.z += 1;
        }
        if (this.directions.has(PlayerDirection.Left)) {
            velocity.x -= 1;
            velocity.z += 1;
        }
        else if (this.directions.has(PlayerDirection.Right)) {
            velocity.x += 1;
            velocity.z -= 1;
        }
        if (velocity.length() > 0) {
            velocity.normalize();
        }
        return velocity;
    }
    applyPrediction() {
        this.prediction.useState(this.schema.tickMove, (velocity, delta) => {
            this.move(velocity, delta);
        });
    }
    stopMoving() {
        this.directions.clear();
        this.setMovingVector(this.getVectorByDirections());
    }
    toggleMoveDirection(direction, state) {
        if (state) {
            this.directions.add(direction);
        }
        else {
            this.directions.delete(direction);
        }
        this.setMovingVector(this.getVectorByDirections());
    }
    onScreenHide() {
        this.stopMoving();
    }
    onDead() {
        this.renderItem.animator.stopAll();
        this.renderItem.animator.play('die', {
            repeat: false,
            clamp: true,
        });
    }
    onBattleFinish() {
        if (this.selfOwn) {
            this.stopMoving();
            this.unbindControls();
        }
    }
    onKeyDown(event) {
        if (event.code.length === 4) {
            if (this.lastKeys.length === 4) {
                this.lastKeys = this.lastKeys.substring(1);
            }
            this.lastKeys += event.code[3];
            if (this.lastKeys.length === 4) {
                this.useCheatCode(this.lastKeys);
            }
        }
        if (event.code === PLAYER_ATTACK_KEY) {
            event.preventDefault();
            if (this.battle.state.paused) {
                return;
            }
            this.attack();
        }
        else {
            const direction = PLAYER_MOVEMENT_DIRECTIONS[event.code];
            if (direction) {
                event.preventDefault();
                if (!this.directions.has(direction)) {
                    this.toggleMoveDirection(direction, true);
                }
            }
        }
    }
    onKeyUp(event) {
        const direction = PLAYER_MOVEMENT_DIRECTIONS[event.code];
        if (direction) {
            event.preventDefault();
            if (this.directions.has(direction)) {
                this.toggleMoveDirection(direction, false);
            }
        }
    }
    onAttack() {
        this.renderItem.animator.play('attack', {
            repeat: false,
            timeScale: 2.0,
        });
        this.attackArea.display();
        this.playAudio(AudioType.Attack);
    }
    onChangeMoveState(moving) {
        if (moving) {
            this.renderItem.animator.change('idle', 'run', 0.25);
        }
        else {
            this.renderItem.animator.change('run', 'idle', 0.25);
        }
    }
    useCheatCode(code) {
        if (Object.values(PlayerCheat).includes(code)) {
            this.messages.send(PlayerMessage.UseCheatCode, {
                code: code,
            });
        }
    }
}
