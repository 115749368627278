import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './tower-laser.bin';
import gltf from './tower-laser.gltf';
Assets.addModel(ModelType.BuildingTowerLaser, {
    gltf,
    bin: {
        origin: 'tower-laser.bin',
        path: bin,
    },
});
