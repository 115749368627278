import React from 'react';
import { useRecoilValue } from 'recoil';
import { InventoryCategory } from './category';
import { InventorySelection } from './selection';
import { ActiveInventorySelection } from './state';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { InventoryItemType } from '~/shared/core/user/inventory/item/types';
import { SKILL_MAX_SLOTS } from '~/shared/rooms/battle/entity/unit/player/skill/const';
import styles from './styles.module.scss';
export const PageInventory = () => {
    const inventorySelection = useRecoilValue(ActiveInventorySelection);
    return (React.createElement("div", { className: styles.wrapper }, inventorySelection ? (React.createElement(InventorySelection, null)) : (React.createElement(Scrollbar, null,
        React.createElement("div", { className: styles.categories },
            React.createElement(InventoryCategory, { type: InventoryItemType.Droid, slotsCount: 1 }),
            React.createElement(InventoryCategory, { type: InventoryItemType.Skill, slotsCount: SKILL_MAX_SLOTS }))))));
};
