export var PlayerMovementKey;
(function (PlayerMovementKey) {
    PlayerMovementKey["KeyW"] = "KeyW";
    PlayerMovementKey["KeyA"] = "KeyA";
    PlayerMovementKey["KeyS"] = "KeyS";
    PlayerMovementKey["KeyD"] = "KeyD";
    PlayerMovementKey["ArrowUp"] = "ArrowUp";
    PlayerMovementKey["ArrowDown"] = "ArrowDown";
    PlayerMovementKey["ArrowLeft"] = "ArrowLeft";
    PlayerMovementKey["ArrowRight"] = "ArrowRight";
})(PlayerMovementKey || (PlayerMovementKey = {}));
export var PlayerDirection;
(function (PlayerDirection) {
    PlayerDirection["Up"] = "Up";
    PlayerDirection["Down"] = "Down";
    PlayerDirection["Left"] = "Left";
    PlayerDirection["Right"] = "Right";
})(PlayerDirection || (PlayerDirection = {}));
