import { useEffect } from 'react';
export function useEntityUpdate(entity, callback) {
    useEffect(() => {
        callback();
        const eventOnUpdate = entity.events.onUpdate.on(callback);
        return () => {
            eventOnUpdate.off();
        };
    }, []);
}
