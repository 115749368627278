import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useState } from 'react';
import { useRoom } from '../../../../../../core/room/ui/hooks/use-room';
import { Section } from '../../../../../../core/ui/components/section';
import { useOpponentPlayer } from '../../../hooks/use-opponent-player';
import { useSelfPlayerSchema } from '../../../hooks/use-self-player-schema';
import { Button } from '../../button';
import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { BattleMode } from '~/shared/rooms/battle/types';
import { RoomType } from '~/shared/rooms/types';
import styles from './styles.module.scss';
import IconCrystal from './icons/crystal.svg';
import IconScore from './icons/score.svg';
export const StageFinished = () => {
    const battle = useRoom();
    const language = useLanguage();
    const player = useSelfPlayerSchema();
    const opponentPlayer = useOpponentPlayer();
    const opponentConnected = useSchemaValue(opponentPlayer, 'connected');
    const [waitingOpponent, setWaitingOpponent] = useState(false);
    const winner = battle.state.winnerId === player.id;
    const loseText = battle.state.mode === BattleMode.Online
        ? language('YouLose')
        : language('YouDied');
    const canPlayAgain = (battle.state.mode === BattleMode.Single ||
        (opponentConnected && !waitingOpponent));
    const handleClickPlay = () => {
        battle.restart();
        if (battle.state.mode === BattleMode.Online) {
            setWaitingOpponent(true);
        }
        else {
            Client.setLoading();
        }
    };
    const handleClickMenu = () => {
        Client.joinRoomByType(RoomType.Relay);
    };
    return (React.createElement("div", { className: cn(styles.screen, {
            [styles.winner]: winner,
        }) },
        React.createElement(Section, { direction: "vertical", align: "center", gap: 10 },
            React.createElement("div", { className: styles.label }, winner ? language('YouWin') : loseText),
            React.createElement(Section, { direction: "vertical", align: "center", gap: 48 },
                React.createElement(Section, { direction: "horizontal", gap: 8 },
                    React.createElement("div", { className: styles.reward },
                        React.createElement(IconCrystal, null),
                        player.crystals),
                    React.createElement("div", { className: styles.reward },
                        React.createElement(IconScore, null),
                        player.score)),
                battle.state.mode === BattleMode.Online && (opponentConnected ? (waitingOpponent && (React.createElement("div", { className: styles.status }, language('WaitingOpponent')))) : (React.createElement("div", { className: styles.status }, language('OpponentDisconnected')))),
                React.createElement(Section, { direction: "vertical", gap: 4 },
                    canPlayAgain && (React.createElement(Button, { onClick: handleClickPlay, size: "large", view: "accent" }, language('PlayAgain'))),
                    React.createElement(Button, { onClick: handleClickMenu, size: "large" }, language('MainMenu')))))));
};
