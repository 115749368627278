import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Translator } from '~/client/rooms/battle/ui/components/translator';
import styles from './styles.module.scss';
import IconConfirm from './icons/confirm.svg';
import IconDecline from './icons/decline.svg';
export const BuildControls = () => {
    const { builder } = useRoom();
    const [variant, setVariant] = useState(builder.variant);
    const [validState, setValidState] = useState(builder.validState);
    const preview = variant && builder.previewBuilding;
    const handleClickConfirm = useCallback(() => {
        builder.build();
    }, []);
    const handleClickDecline = useCallback(() => {
        builder.setVariant(null);
    }, []);
    useEffect(() => {
        const eventChangeVariant = builder.events.onChangeVariant.on(setVariant);
        const eventChangeValidState = builder.events.onChangeValidState.on(setValidState);
        return () => {
            eventChangeVariant.off();
            eventChangeValidState.off();
        };
    }, []);
    return preview && (React.createElement(Translator, { renderItem: preview, body: React.createElement("div", { className: styles.wrapper },
            React.createElement(Interactive, { onClick: handleClickConfirm, className: cn(styles.button, styles.confirm, {
                    [styles.disabled]: !validState,
                }) },
                React.createElement(IconConfirm, null)),
            React.createElement(Interactive, { onClick: handleClickDecline, className: cn(styles.button, styles.decline) },
                React.createElement(IconDecline, null))) }));
};
