import { Droid } from '../..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { Line } from '~/client/core/render-item/shape/line';
import './resources';
export class DroidMechanic extends Droid {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.DroidMechanic,
        }, schema);
        this.laser = new Line(this.battle.scene, {
            material: MaterialType.RepairLine,
            position: this.renderItem.position.clone()
                .add({ x: 0, y: 0.4, z: 0 }),
            positionEnd: this.schema.positionTarget,
            visible: this.schema.repairing,
            alpha: 0.5,
        });
        this.schema.listen('activeLaser', (activeLaser) => {
            this.laser.setVisible(activeLaser);
        });
    }
    destroy() {
        this.laser.destroy();
        super.destroy();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        if (this.laser.visible) {
            this.laser.setPosition(this.renderItem.position.clone().add({ x: 0, y: 0.4, z: 0 }));
            this.laser.setPositionEnd(this.schema.positionTarget);
        }
    }
    rotateToVelocity() {
        if (this.schema.repairing) {
            this.rotateToPosition(this.schema.positionTarget);
        }
        else {
            super.rotateToVelocity();
        }
    }
}
