import { MobCrab } from '../variants/crab';
import { MobDemon } from '../variants/demon';
import { MobEye } from '../variants/eye';
import { MobEyeChild } from '../variants/eye-child';
import { MobGolem } from '../variants/golem';
import { MobOrc } from '../variants/orc';
import { MobSiren } from '../variants/siren';
import { MobSpider } from '../variants/spider';
import { MobStingray } from '../variants/stingray';
import { MobTurtle } from '../variants/turtle';
import { MobVariant } from '~/shared/rooms/battle/entity/unit/npc/mob/types';
export const MOB_INSTANCES = {
    [MobVariant.Turtle]: MobTurtle,
    [MobVariant.Spider]: MobSpider,
    [MobVariant.Siren]: MobSiren,
    [MobVariant.Orc]: MobOrc,
    [MobVariant.Golem]: MobGolem,
    [MobVariant.Eye]: MobEye,
    [MobVariant.EyeChild]: MobEyeChild,
    [MobVariant.Crab]: MobCrab,
    // @ts-ignore
    [MobVariant.Stingray]: MobStingray,
    [MobVariant.Demon]: MobDemon,
};
