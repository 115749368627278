import { Entity } from '..';
export class Shot extends Entity {
    constructor(battle, { renderItem, audioEffect }, schema) {
        super(battle, renderItem, schema);
        this.listenSchemaActive(audioEffect);
    }
    listenSchemaActive(audioEffect) {
        let prevActive = true;
        this.schema.listen('active', (active) => {
            if (active !== prevActive) {
                if (active) {
                    this.playAudio(audioEffect);
                }
                else {
                    this.stopAudio(audioEffect);
                }
            }
            prevActive = active;
        });
    }
}
