import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { ProgressBar } from '../../../../../../ui/components/progress-bar';
import { useMob } from '../../hooks/use-mob';
export const ArmourBar = () => {
    const mob = useMob();
    const armour = useSchemaValue(mob.schema.live, 'armour');
    const maxArmour = useSchemaValue(mob.schema.live, 'maxArmour');
    return (armour > 0) && (React.createElement(ProgressBar, { value: armour, maxValue: maxArmour, color: "#69cde3" }));
};
