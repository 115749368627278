import { Vector2 } from 'three';
import { INPUT_TOUCH_SHIFT_DISTANCE } from '../const';
import { Device } from '~/client/core/device';
import { EventStream } from '~/shared/core/event-stream';
export class InputTouchChannel {
    constructor({ touch, event }) {
        this.position = new Vector2();
        this.normalizedPosition = new Vector2();
        this.beginPosition = new Vector2();
        this.events = {
            onMove: new EventStream(),
            onRelease: new EventStream(),
        };
        this.taken = false;
        this.shifted = false;
        this.target = event.target;
        this.targets = event.composedPath();
        this.identifier = touch.identifier;
        this.updatePosition(touch);
        this.beginPosition.copy(this.position);
    }
    takeUp() {
        this.taken = true;
    }
    updatePosition(touch) {
        const positionOnScreen = Device.getPositionOnScreen({
            x: touch.clientX,
            y: touch.clientY,
        });
        this.position.copy(positionOnScreen);
        this.normalizedPosition.copy(Device.normalizePosition(this.position));
    }
    checkShifting() {
        if (!this.shifted) {
            const distance = this.beginPosition.distanceTo(this.position);
            this.shifted = distance > INPUT_TOUCH_SHIFT_DISTANCE;
        }
    }
}
