import React, { useEffect, useRef, useState } from 'react';
import { useMob } from '../../../hooks/use-mob';
export const StateIcon = () => {
    const mob = useMob();
    const [visible, setVisible] = useState(Boolean(mob.stateIcon));
    const refIcon = useRef(mob.stateIcon);
    const IconComponent = visible && refIcon.current;
    const handleChangeStateIcon = (icon) => {
        refIcon.current = icon;
        setVisible(Boolean(icon));
    };
    useEffect(() => {
        const eventChangeStateIcon = mob.events.onChangeStateIcon.on(handleChangeStateIcon);
        return () => {
            eventChangeStateIcon.off();
        };
    }, []);
    return IconComponent ? React.createElement(IconComponent, null) : null;
};
