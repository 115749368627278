import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { DamageLabel } from '../damage-label';
import { ProgressBar } from '../progress-bar';
import styles from './styles.module.scss';
export const HealthBar = ({ unit }) => {
    if (!unit.schema.live) {
        return null;
    }
    const health = useSchemaValue(unit.schema.live, 'health');
    const maxHealth = useSchemaValue(unit.schema.live, 'maxHealth');
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(ProgressBar, { value: health, maxValue: maxHealth, color: unit.selfOwn ? '#4ed83e' : '#ff5151' }),
        React.createElement(DamageLabel, { live: unit.schema.live })));
};
