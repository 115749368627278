import cn from 'classnames';
import React from 'react';
import { useUserData } from '../../../../hooks/use-user-data';
import { Utils } from '~/shared/core/utils';
import styles from './styles.module.scss';
import IconScore from '../icons/score.svg';
export const Leader = ({ place, name, score }) => {
    const currentName = useUserData('name');
    return (React.createElement("div", { className: cn(styles.wrapper, styles[`place${place}`]) },
        React.createElement("div", { className: styles.place }, place),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.gradient }),
            React.createElement("div", { className: styles.info },
                React.createElement("div", { className: cn(styles.name, {
                        [styles.self]: name === currentName,
                    }) }, name === currentName ? 'YOU' : name),
                React.createElement("div", { className: styles.score },
                    React.createElement(IconScore, null),
                    Utils.decorateNumber(score))))));
};
