import { Building } from '../..';
import { ModelType } from '../../../../../../core/assets/types';
import './resources';
export class BuildingTrap extends Building {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingTrap,
        }, schema);
        if (this.selfOwn) {
            this.addParameter(schema, 'reflection', {
                transform: (value) => `${Math.round(value * 100)}%`,
            });
        }
    }
}
