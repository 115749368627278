import { useRef, useEffect } from 'react';
import { Device } from '../../device';
export function useRelativeScreen() {
    const refElement = useRef(null);
    const refTimeout = useRef(null);
    const handleScreenResize = () => {
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
        }
        refTimeout.current = setTimeout(() => {
            refTimeout.current = null;
            if (!refElement.current) {
                return;
            }
            refElement.current.style.removeProperty('transform');
            refElement.current.style.removeProperty('transformOrigin');
            refElement.current.style.removeProperty('width');
            refElement.current.style.removeProperty('height');
            const originalWidth = refElement.current.clientWidth;
            const originalHeight = refElement.current.clientHeight;
            const zoom = Device.getScale();
            refElement.current.style.transform = `scale(${zoom})`;
            refElement.current.style.transformOrigin = '0 0';
            refElement.current.style.width = `${originalWidth / zoom}px`;
            refElement.current.style.height = `${originalHeight / zoom}px`;
        }, 50);
    };
    useEffect(() => {
        handleScreenResize();
        const eventScreenResize = Device.events.onScreenResize.on(handleScreenResize);
        return () => {
            eventScreenResize.off();
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, []);
    return refElement;
}
