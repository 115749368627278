import React from 'react';
import styles from './styles.module.scss';
export const ProgressBar = ({ value, maxValue, color }) => {
    const progress = (value / maxValue) * 100;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.progress, style: {
                width: `${progress}%`,
                backgroundColor: color,
            } })));
};
