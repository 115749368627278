import { useSchemaListener, useSchemaValue } from 'colyseus-schema-hooks';
import React, { useState } from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Card } from '~/client/rooms/battle/ui/components/card';
import { Cost } from '~/client/rooms/battle/ui/components/cost';
import { useSelfPlayerSchema } from '~/client/rooms/battle/ui/hooks/use-self-player-schema';
import { BUILDER_TUTORIAL_FLOW } from '~/shared/rooms/battle/builder/const';
import { BuilderUtils } from '~/shared/rooms/battle/builder/utils';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/rooms/battle/entity/building/const';
import { BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
import { SkillVariant } from '~/shared/rooms/battle/entity/unit/player/skill/types';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const Variant = ({ variant, onSelect }) => {
    const { builder, scene, state } = useRoom();
    const player = useSelfPlayerSchema();
    const resources = useSchemaValue(player, 'resources');
    const language = useLanguage();
    const [hint, setHint] = useState(null);
    let cost = BUILDINGS_SHARED_CONFIG[variant].cost;
    if (variant === BuildingVariant.Wall) {
        const skill = player.skills.get(SkillVariant.DiscountWalls);
        if (skill) {
            cost *= skill.multiplier;
        }
    }
    const limitExceeded = BuilderUtils.isLimitExceeded(variant, player, state.buildings);
    const restrictionLevel = BuilderUtils.getRestrictionLevel(variant, player, state.buildings);
    const avaialble = !limitExceeded && player.level >= restrictionLevel;
    const hasResources = resources >= cost;
    const locked = !avaialble || Boolean((hint === null || hint === void 0 ? void 0 : hint.strict) && hint.need !== variant);
    const handleClickVariant = () => {
        builder.setVariant(variant);
        onSelect();
    };
    useSchemaListener(player.tutorial.activeSteps, {
        onAdd: (step) => {
            const targetVariant = BUILDER_TUTORIAL_FLOW[step];
            if (targetVariant) {
                setHint(targetVariant);
            }
        },
        onRemove: (step) => {
            const targetVariant = BUILDER_TUTORIAL_FLOW[step];
            if (targetVariant) {
                setHint(null);
            }
        },
    });
    return (React.createElement(Card, { onClick: handleClickVariant, disabled: locked || !hasResources, locked: locked, alarm: !locked && !hasResources, blink: (hint === null || hint === void 0 ? void 0 : hint.need) === variant },
        React.createElement(Card.Graphic, null, avaialble ? (React.createElement("img", { src: scene.snaps.get(variant) })) : (React.createElement(React.Fragment, null,
            React.createElement(IconLock, null),
            React.createElement("div", { className: styles.lock }, limitExceeded ? (React.createElement(React.Fragment, null, "MAX")) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: styles.level }, restrictionLevel),
                " LVL")))))),
        React.createElement(Card.Content, null,
            React.createElement(Card.Title, null, language(`${variant}Name`)),
            React.createElement("div", { className: styles.cost },
                React.createElement(Cost, { amount: cost, check: avaialble })))));
};
