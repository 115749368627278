import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { UPGRADE_ICON_MAP } from './const';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Card } from '~/client/rooms/battle/ui/components/card';
import { useSelfPlayerSchema } from '~/client/rooms/battle/ui/hooks/use-self-player-schema';
import { UPGRADE_MAX_LEVEL, UPGRADE_COSTS } from '~/shared/rooms/battle/entity/unit/player/upgrades/const';
import styles from './styles.module.scss';
export const Variant = ({ variant }) => {
    var _a;
    const battle = useRoom();
    const player = useSelfPlayerSchema();
    const audio = useAudio();
    const language = useLanguage();
    const upgrades = useSchemaValue(player, 'upgrades');
    const experience = useSchemaValue(player, 'experience');
    const stages = Array(UPGRADE_MAX_LEVEL).fill(0);
    const level = (_a = upgrades.get(variant)) !== null && _a !== void 0 ? _a : 1;
    const cost = UPGRADE_COSTS[variant].get(level);
    const hasExperience = experience >= cost;
    const hasMaxLevel = level === UPGRADE_MAX_LEVEL;
    const IconComponent = UPGRADE_ICON_MAP[variant];
    const handleClick = () => {
        audio.play(AudioType.Upgrade);
        battle.getSelfPlayer().upgrade(variant);
    };
    return (React.createElement(Card, { onClick: handleClick, disabled: hasMaxLevel || !hasExperience, locked: hasMaxLevel, alarm: !hasMaxLevel && !hasExperience },
        React.createElement(Card.Graphic, null,
            React.createElement(IconComponent, null)),
        React.createElement(Card.Content, null,
            React.createElement(Card.Title, null, language(variant)),
            hasMaxLevel ? (React.createElement("div", { className: styles.maxLevel }, "MAX LEVEL")) : (React.createElement("div", { className: cn(styles.cost, {
                    [styles.less]: !hasExperience,
                }) },
                React.createElement("div", { className: styles.amount }, cost),
                React.createElement("div", { className: styles.postfix }, "EXP")))),
        React.createElement("div", { className: styles.stages }, stages.map((_, i) => (React.createElement("div", { key: i, className: cn(styles.stage, {
                [styles.active]: level > i,
            }) }))))));
};
