import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
export const Indicator = ({ entity, message, children, onDisplay }) => {
    const [active, setActive] = useState(false);
    const refTimeout = useRef(null);
    useEffect(() => {
        if (entity.disposed) {
            return;
        }
        // @ts-ignore
        entity.messages.on(message, () => {
            setActive(true);
            onDisplay === null || onDisplay === void 0 ? void 0 : onDisplay();
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
            refTimeout.current = setTimeout(() => {
                setActive(false);
                refTimeout.current = null;
            }, 1500);
        });
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, []);
    return active && React.createElement("div", { className: styles.container }, children);
};
