export var MobVariant;
(function (MobVariant) {
    // Basic
    MobVariant["Turtle"] = "Turtle";
    MobVariant["Spider"] = "Spider";
    MobVariant["Siren"] = "Siren";
    // Extra
    MobVariant["Orc"] = "Orc";
    MobVariant["Golem"] = "Golem";
    MobVariant["Eye"] = "Eye";
    MobVariant["EyeChild"] = "EyeChild";
    // Ultra
    MobVariant["Crab"] = "Crab";
    MobVariant["Stingray"] = "Stingray";
    MobVariant["Demon"] = "Demon";
})(MobVariant || (MobVariant = {}));
/**
 * Messages
 */
export var MobMessage;
(function (MobMessage) {
    MobMessage["Attack"] = "Mob:Attack";
    MobMessage["Freeze"] = "Mob:Freeze";
    MobMessage["Boost"] = "Mob:Boost";
})(MobMessage || (MobMessage = {}));
