import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
import IconPlayers from './icons/players.svg';
export const PlayersOnline = () => {
    const room = useRoom();
    const language = useLanguage();
    const online = useSchemaValue(room.state, 'online');
    return (React.createElement("div", { className: styles.container },
        React.createElement(IconPlayers, null),
        React.createElement("div", { className: styles.text },
            React.createElement("b", null, online),
            React.createElement("span", null, online === 1
                ? language('PlayerOnline')
                : language('PlayersOnline')))));
};
