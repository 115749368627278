import { Building } from '../..';
import { ModelType } from '../../../../../../core/assets/types';
import './resources';
export class BuildingWall extends Building {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingWall,
        }, schema);
    }
}
