import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useUserData } from '../../../hooks/use-user-data';
import { ActiveOverlay } from '../../overlays/state';
import { OverlayType } from '../../overlays/types';
import { PAGES } from '../../pages/const';
import { ActivePage } from '../../pages/state';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const MenuItem = ({ type }) => {
    const authorized = useUserData('authorized');
    const language = useLanguage();
    const [activePage, setPage] = useRecoilState(ActivePage);
    const [, setOverlay] = useRecoilState(ActiveOverlay);
    const { authorizedOnly, icon } = PAGES[type];
    const IconComponent = icon;
    const handleClick = useCallback(() => {
        if (!authorizedOnly || authorized) {
            setPage(type);
        }
        else {
            setOverlay(OverlayType.Login);
        }
    }, [authorized]);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.container, {
            [styles.active]: activePage === type,
        }) },
        React.createElement("div", { className: styles.icon },
            React.createElement(IconComponent, null)),
        React.createElement("div", { className: styles.label }, language(type))));
};
