export class LoggerUtils {
    static formatErrorMessage(message, error) {
        return error
            ? `${message}: ${error.message.replace(/^\w*Error:\s/, '')}`
            : message;
    }
    static formatStack(stack) {
        const [, ...parts] = stack.replaceAll('    at', '├ at').split('\n');
        parts[parts.length - 1] = parts[parts.length - 1].replace('├', '└');
        return parts.join('\n');
    }
}
