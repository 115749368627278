import { DroidUI } from './ui';
import { NPC } from '..';
import { MaterialType } from '~/client/core/assets/materials/types';
export class Droid extends NPC {
    constructor(battle, config, schema) {
        super(battle, config, schema);
        this.setUI(DroidUI);
        this.renderItem.animator.play('fly', {
            timeScale: 0.25,
        });
        this.renderItem.setMaterial(this.selfOwn ? MaterialType.Self : MaterialType.Opponent, 'Cube_2');
    }
}
