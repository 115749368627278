import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '../../auth/button';
import { ErrorPlate } from '../../auth/error-plate';
import { InputPassword } from '../../auth/inputs/password';
import { InputUsername } from '../../auth/inputs/username';
import { ActiveOverlay } from '../state';
import { OverlayType } from '../types';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Checkbox } from '~/client/core/ui/components/checkbox';
import { Section } from '~/client/core/ui/components/section';
import { useError } from '~/client/core/ui/hooks/use-error';
import { RelayRequest } from '~/shared/rooms/relay/types';
import styles from './styles.module.scss';
export const OverlayRegistration = () => {
    const room = useRoom();
    const audio = useAudio();
    const error = useError();
    const language = useLanguage();
    const [, setOverlay] = useRecoilState(ActiveOverlay);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [acceptPolicy, setAcceptPolicy] = useState(true);
    const handleClickLogIn = useCallback(() => {
        setOverlay(OverlayType.Login);
    }, []);
    const handleSubmit = useCallback(() => {
        room.sendRequest(RelayRequest.Register, { username, password })
            .then((token) => {
            Client.setAuthToken(token);
            room.remountInterface();
        })
            .catch(({ message }) => {
            audio.play(AudioType.Error);
            error.show(language(message));
        });
    }, [username, password]);
    return (React.createElement("form", { onSubmit: handleSubmit, autoComplete: "false" },
        React.createElement(Section, { direction: "vertical", gap: 48 },
            React.createElement(Section, { direction: "vertical", gap: 24 },
                React.createElement(Section, { direction: "vertical", gap: 8 },
                    React.createElement(ErrorPlate, { error: error }),
                    React.createElement(InputUsername, { onChange: setUsername }),
                    React.createElement(InputPassword, { onChange: setPassword })),
                React.createElement(Checkbox, { checked: acceptPolicy, onChange: setAcceptPolicy },
                    "I accept the terms of the",
                    ' ',
                    React.createElement("a", { href: "https://izowave.io/policy", target: "_blank", className: styles.policy }, "privacy policy"))),
            React.createElement(Section, { direction: "horizontal", gap: 8 },
                React.createElement(Button, { label: language('SignUp'), onClick: handleSubmit, type: "primiary", disabled: !username || !password || !acceptPolicy }),
                React.createElement(Button, { label: language('LogIn'), onClick: handleClickLogIn, type: "secondary" })))));
};
