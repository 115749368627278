import { Vector3 } from 'three';
export class UnitUtils {
    static getLookUpPosition(position, velocity, size) {
        return new Vector3()
            .copy(velocity)
            .normalize()
            .multiplyScalar(size.z * 0.5)
            .add(position);
    }
}
