import { useRoom } from '~/client/core/room/ui/hooks/use-room';
export function useAudio() {
    try {
        const room = useRoom();
        return {
            play: (type) => {
                room.scene.audio.play2D(type);
            },
        };
    }
    catch (e) {
        return {
            play: (type) => {
                void type;
            },
        };
    }
}
