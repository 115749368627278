var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { WebGLRenderer, PerspectiveCamera, AmbientLight, Clock, Scene as OriginScene, } from 'three';
import { SCENE_CAMERA_FOV, SCENE_CANVAS_ID } from './const';
import { SceneResolution } from './types';
import { Audio } from '../audio';
import { Device } from '../device';
import { Settings } from '../settings';
import { SettingsType } from '../settings/types';
import { RATE } from '~/shared/core/const';
import { DeviceType } from '~/shared/core/device/types';
import { EventStream } from '~/shared/core/event-stream';
import { Utils } from '~/shared/core/utils';
import './resources';
export class Scene extends OriginScene {
    constructor({ backgroundColor, light }) {
        super();
        this.paused = false;
        this.clock = new Clock();
        this.events = {
            onUpdate: new EventStream(),
            onRender: new EventStream(),
        };
        this.deltaTime = 0;
        this.delta = 1.0;
        this.lastUpdateTimestamp = 0;
        this.fps = 0.0;
        this.fpsLoopTime = 0;
        this.fpsTick = 0;
        this.fpsLimit = 45;
        this.audio = new Audio();
        this.resolution = SceneResolution.Medium;
        this.disposed = false;
        this.backgroundColor = backgroundColor;
        this.onScreenResize = this.onScreenResize.bind(this);
        this.addLight(light);
        this.addCamera();
        this.createRender(Settings.getEnum(SettingsType.Resolution, SceneResolution, SceneResolution.Medium));
        this.setFpsLimit(Settings.getInteger(SettingsType.FpsLimit, 45));
        const audioEffects = Settings.getBoolean(SettingsType.AudioEffects, true);
        if (audioEffects) {
            this.audio.enable();
        }
        else {
            this.audio.disable();
        }
        this.runRenderLoop();
        Device.events.onScreenResize.on(this.onScreenResize);
    }
    destroy() {
        this.remove();
        this.removeRenderer();
        this.audio.destroy();
        this.disposed = true;
        Device.events.onScreenResize.off(this.onScreenResize);
    }
    createRender(resolution) {
        this.resolution = resolution !== null && resolution !== void 0 ? resolution : (Device.type === DeviceType.Mobile
            ? SceneResolution.High
            : SceneResolution.Medium);
        const canvas = document.createElement('canvas');
        canvas.id = SCENE_CANVAS_ID;
        Device.getWrapper().append(canvas);
        this.renderer = new WebGLRenderer({
            canvas,
            antialias: resolution !== SceneResolution.Low,
        });
        const pixelRatio = resolution === SceneResolution.High
            ? Device.pixelRatio
            : 1;
        this.renderer.setPixelRatio(pixelRatio);
        this.renderer.setClearColor(this.backgroundColor);
        this.updateScreenSize();
        this.render();
    }
    render() {
        this.renderer.render(this, this.camera);
    }
    removeRenderer() {
        var _a;
        this.renderer.dispose();
        (_a = document.getElementById(SCENE_CANVAS_ID)) === null || _a === void 0 ? void 0 : _a.remove();
    }
    updateScreenSize() {
        const screenSize = Device.getScreenSize();
        this.renderer.setSize(screenSize.x, screenSize.y);
        this.camera.aspect = screenSize.x / screenSize.y;
        this.camera.updateProjectionMatrix();
    }
    addCamera() {
        this.camera = new PerspectiveCamera(SCENE_CAMERA_FOV);
        this.camera.layers.enableAll();
    }
    addLight(color) {
        const ambientLight = new AmbientLight(color);
        this.add(ambientLight);
    }
    setFpsLimit(limit) {
        this.fpsLimit = Utils.clamp(limit, 30, 60);
    }
    setResolution(resolution) {
        this.removeRenderer();
        this.createRender(resolution);
    }
    runRenderLoop() {
        let deltaStack = 0;
        const loop = () => {
            if (this.disposed) {
                return;
            }
            requestAnimationFrame(loop);
            const limit = 1 / this.fpsLimit;
            deltaStack += this.clock.getDelta();
            if (deltaStack > limit) {
                const now = performance.now();
                this.deltaTime = now - this.lastUpdateTimestamp;
                this.delta = this.deltaTime / RATE.UPDATE;
                this.update();
                this.measureFps();
                this.lastUpdateTimestamp = now;
                deltaStack %= limit;
            }
        };
        loop();
    }
    measureFps() {
        this.fpsLoopTime += this.deltaTime;
        this.fpsTick++;
        if (this.fpsLoopTime >= 1000) {
            this.fps = this.fpsTick;
            this.fpsTick = 0;
            this.fpsLoopTime = 0;
        }
    }
    update() {
        var _a;
        if (this.paused) {
            return;
        }
        this.events.onUpdate.invoke();
        (_a = this.syncCamera) === null || _a === void 0 ? void 0 : _a.call(this);
        this.events.onRender.invoke();
        this.render();
    }
    onScreenResize() {
        this.updateScreenSize();
    }
    isFullscreen() {
        return Boolean(document.fullscreenElement);
    }
    toggleFullscreen(state) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (state) {
                    yield document.documentElement.requestFullscreen();
                }
                else {
                    yield document.exitFullscreen();
                }
                this.render();
            }
            catch (_a) {
                //
            }
        });
    }
    toggleAudio(state) {
        if (state) {
            this.audio.enable();
        }
        else {
            this.audio.disable();
        }
    }
}
