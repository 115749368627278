import { Building } from '../..';
import { ModelType } from '../../../../../../core/assets/types';
import './resources';
export class BuildingRadar extends Building {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingRadar,
        }, schema);
    }
    onReady() {
        super.onReady();
        this.renderItem.animator.play('rotate', { timeScale: 0.1 });
    }
}
