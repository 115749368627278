import React, { useEffect, useRef, useState } from 'react';
import { DAMAGE_LABEL_DELAY } from './const';
import styles from './styles.module.scss';
export const DamageLabel = ({ live }) => {
    const [damageStack, setDamageStack] = useState([]);
    const prevHealth = useRef(0);
    const hideTimeout = useRef();
    useEffect(() => {
        const unlisten = live.listen('health', (newHealth) => {
            const amount = prevHealth.current - newHealth;
            if (amount > 0) {
                setDamageStack((current) => {
                    const records = [...current];
                    const lastRecord = records[records.length - 1];
                    const now = performance.now();
                    if (lastRecord && now - lastRecord.timestamp <= DAMAGE_LABEL_DELAY) {
                        lastRecord.amount = amount;
                    }
                    else {
                        records.push({
                            amount,
                            timestamp: now,
                        });
                    }
                    return records;
                });
                if (hideTimeout.current) {
                    clearTimeout(hideTimeout.current);
                }
                hideTimeout.current = setTimeout(() => {
                    setDamageStack([]);
                }, DAMAGE_LABEL_DELAY);
            }
            prevHealth.current = newHealth;
        });
        return () => {
            unlisten();
            if (hideTimeout.current) {
                clearTimeout(hideTimeout.current);
            }
        };
    }, []);
    return (React.createElement("div", null, damageStack.map(({ amount, timestamp }) => (React.createElement("div", { key: timestamp, className: styles.label }, amount)))));
};
