import { Assets } from '../../../../../../../core/assets';
import { ModelType } from '../../../../../../../core/assets/types';
import binRock1 from './rock1.bin';
import gltfRock1 from './rock1.gltf';
import binRock2 from './rock2.bin';
import gltfRock2 from './rock2.gltf';
import binRock3 from './rock3.bin';
import gltfRock3 from './rock3.gltf';
import binRock4 from './rock4.bin';
import gltfRock4 from './rock4.gltf';
Assets.addModel(ModelType.Rock1, {
    gltf: gltfRock1,
    bin: {
        origin: 'rock1.bin',
        path: binRock1,
    },
});
Assets.addModel(ModelType.Rock2, {
    gltf: gltfRock2,
    bin: {
        origin: 'rock2.bin',
        path: binRock2,
    },
});
Assets.addModel(ModelType.Rock3, {
    gltf: gltfRock3,
    bin: {
        origin: 'rock3.bin',
        path: binRock3,
    },
});
Assets.addModel(ModelType.Rock4, {
    gltf: gltfRock4,
    bin: {
        origin: 'rock4.bin',
        path: binRock4,
    },
});
