import { MaterialType } from '~/client/core/assets/materials/types';
import { Circle } from '~/client/core/render-item/shape/circle';
import { BUILDER_RADIUS } from '~/shared/rooms/battle/builder/const';
import { UpgradeVariant } from '~/shared/rooms/battle/entity/unit/player/upgrades/types';
import './resources';
export class BuildArea extends Circle {
    constructor(player) {
        super(player.battle.scene, {
            material: MaterialType.BuildArea,
            radius: BUILDER_RADIUS.defaultValue,
            alpha: 0.1,
        });
        this.player = player;
        this.player.renderItem.object.add(this.object);
        this.updateRadius();
        this.player.schema.upgrades.onChange(() => {
            this.updateRadius();
        });
    }
    updateRadius() {
        const radius = this.player.getUpgradableValue(BUILDER_RADIUS, UpgradeVariant.BuildRadius);
        this.setRadius(radius);
    }
}
