import React, { useState } from 'react';
import { Param } from '..';
import { Option } from '../option';
import { Settings } from '~/client/core/settings';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const ParamInteger = ({ type, defaultValue, options, save = true, onChange, }) => {
    const [value, setValue] = useState(defaultValue);
    const handleChange = (newValue) => {
        setValue(newValue);
        onChange(newValue);
        if (save) {
            Settings.setInteger(type, newValue);
        }
    };
    return (React.createElement(Param, { type: type },
        React.createElement(Section, { direction: "horizontal", gap: 1, className: styles.wrapper }, options.map((option) => (React.createElement(Option, { key: option, value: option, active: value === option, onClick: () => handleChange(option) }))))));
};
