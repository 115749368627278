import { DICTIONARIES } from './const';
import { LanguageType } from './types';
import { Settings } from '../settings';
import { SettingsType } from '../settings/types';
export class Language {
    static getType() {
        const systemType = this.getSystemType();
        return Settings.getEnum(SettingsType.Language, LanguageType, systemType);
    }
    static getSystemType() {
        var _a;
        try {
            const type = (_a = (Intl.DateTimeFormat().resolvedOptions().locale ||
                navigator.language.split('-')[0])) === null || _a === void 0 ? void 0 : _a.toUpperCase();
            if (!type) {
                throw Error('Unable to get system language');
            }
            if (!(type in DICTIONARIES)) {
                throw Error('System language isn`t supported');
            }
            return type;
        }
        catch (_b) {
            return LanguageType.EN;
        }
    }
}
