import { useSchemaListener } from 'colyseus-schema-hooks';
import { useState } from 'react';
import { useUserData } from '../../../../hooks/use-user-data';
import { useBatch } from '~/client/core/ui/hooks/use-batch';
import { InventoryUtils } from '~/shared/core/user/utils';
export function useInventorySlot(type, slot) {
    const inventory = useUserData('inventory');
    const getItem = () => InventoryUtils.getItem(inventory, type, slot);
    const [item, setItem] = useState(getItem);
    const update = useBatch(() => {
        setItem(getItem());
    });
    useSchemaListener(inventory.slots, {
        onAdd: update,
        onChange: update,
        onRemove: update,
    });
    return item;
}
