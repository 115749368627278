var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SphereGeometry } from 'three';
import { Shape } from '..';
const _SphereGeometry = new SphereGeometry(1.0, 6, 4);
export class Sphere extends Shape {
    constructor(scene, _a) {
        var { radius = 1.0 } = _a, config = __rest(_a, ["radius"]);
        super(scene, Object.assign(Object.assign({}, config), { geometry: _SphereGeometry }));
        if (radius !== 1.0) {
            this.setRadius(radius);
        }
    }
    setRadius(radius) {
        this.setScale({ x: radius, y: radius, z: radius });
    }
}
