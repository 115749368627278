export var BuildingVariant;
(function (BuildingVariant) {
    BuildingVariant["Wall"] = "Wall";
    BuildingVariant["Generator"] = "Generator";
    BuildingVariant["SpawnerSmall"] = "SpawnerSmall";
    BuildingVariant["SpawnerMedium"] = "SpawnerMedium";
    BuildingVariant["SpawnerLarge"] = "SpawnerLarge";
    BuildingVariant["TowerFire"] = "TowerFire";
    BuildingVariant["TowerFrost"] = "TowerFrost";
    BuildingVariant["TowerLaser"] = "TowerLaser";
    BuildingVariant["Radar"] = "Radar";
    BuildingVariant["Ammunition"] = "Ammunition";
    BuildingVariant["Trap"] = "Trap";
})(BuildingVariant || (BuildingVariant = {}));
export var BuildingCategory;
(function (BuildingCategory) {
    BuildingCategory["Resources"] = "Resources";
    BuildingCategory["Spawners"] = "Spawners";
    BuildingCategory["Towers"] = "Towers";
    BuildingCategory["Defense"] = "Defense";
})(BuildingCategory || (BuildingCategory = {}));
/**
 * Messages
 */
export var BuildingMessage;
(function (BuildingMessage) {
    BuildingMessage["DoRepair"] = "Building:DoRepair";
    BuildingMessage["DoUpgrade"] = "Building:DoUpgrade";
    BuildingMessage["DoBreak"] = "Building:DoBreak";
    BuildingMessage["Upgrade"] = "Building:Upgrade";
    BuildingMessage["Boost"] = "Building:Boost";
})(BuildingMessage || (BuildingMessage = {}));
