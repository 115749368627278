import React from 'react';
import { Controls } from './components/controls';
import { Logotype } from './components/logotype';
import { Menu } from './components/menu';
import { Overlay } from './components/overlays';
import { Page } from './components/pages';
import { PlayersOnline } from './components/players-online';
import { UserPanel } from './components/user-panel';
import { VersionTag } from './components/version-tag';
import { Section } from '~/client/core/ui/components/section';
import { useRelativeScreen } from '~/client/core/ui/hooks/use-relative-screen';
import styles from './styles.module.scss';
export const RelayScreen = () => {
    const refScreen = useRelativeScreen();
    return (React.createElement("div", { ref: refScreen, className: styles.screen },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.sidebar },
                React.createElement(Section, { direction: "vertical", gap: 32 },
                    React.createElement(Logotype, null),
                    React.createElement(UserPanel, null),
                    React.createElement(Menu, null)),
                React.createElement(PlayersOnline, null)),
            React.createElement("div", { className: styles.content },
                React.createElement(Page, null))),
        React.createElement(Controls, null),
        React.createElement(Overlay, null),
        React.createElement("div", { className: styles.shadow }),
        React.createElement(VersionTag, null)));
};
