import { DecorationType } from '../../../../../server/rooms/battle/terrain/decoration/types';
import { ModelType } from '../../../../core/assets/types';
export const DECORATION_PRESETS = {
    [DecorationType.Tree]: {
        models: [ModelType.Tree1, ModelType.Tree2],
        scale: [30.0, 80.0],
    },
    [DecorationType.Mushroom]: {
        models: [ModelType.Mushroom1, ModelType.Mushroom2],
        scale: [3.0, 4.0],
    },
    [DecorationType.Rock]: {
        models: [ModelType.Rock1, ModelType.Rock2, ModelType.Rock3, ModelType.Rock4],
        scale: [8.0, 12.0],
    },
};
