import { PREDICTION_MAX_BUFFER_SIZE } from './const';
// https://www.gabrielgambetta.com/client-side-prediction-server-reconciliation.html
export class Prediction {
    constructor() {
        this.tick = 0;
        this.buffer = [];
    }
    addState(velocity, delta) {
        if (this.buffer.length === PREDICTION_MAX_BUFFER_SIZE) {
            this.buffer.splice(0, 1);
        }
        this.buffer.push({
            tick: ++this.tick,
            velocity: Object.assign({}, velocity),
            delta,
        });
        return this.tick;
    }
    useState(tick, callback) {
        let j = 0;
        while (j < this.buffer.length) {
            const state = this.buffer[j];
            if (state.tick <= tick) {
                this.buffer.splice(j, 1);
            }
            else {
                callback(state.velocity, state.delta);
                j++;
            }
        }
    }
}
