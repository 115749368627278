export var RelayRequest;
(function (RelayRequest) {
    RelayRequest["UpdateInvetorySlot"] = "UpdateInvetorySlot";
    RelayRequest["BuyInvetoryItem"] = "BuyInvetoryItem";
    RelayRequest["GetLeaderboard"] = "GetLeaderboard";
    RelayRequest["StartSearchingOpponent"] = "StartSearchingOpponent";
    RelayRequest["StopSearchingOpponent"] = "StopSearchingOpponent";
    RelayRequest["PlaySingle"] = "PlaySingle";
    RelayRequest["GenerateFriendId"] = "GenerateFriendId";
    RelayRequest["ClearFriendId"] = "ClearFriendId";
    RelayRequest["JoinFriendId"] = "JoinFriendId";
    RelayRequest["Register"] = "Register";
    RelayRequest["Login"] = "Login";
    RelayRequest["Logout"] = "Logout";
})(RelayRequest || (RelayRequest = {}));
export var RelayMessage;
(function (RelayMessage) {
    RelayMessage["JoinBattle"] = "Relay:JoinBattle";
})(RelayMessage || (RelayMessage = {}));
