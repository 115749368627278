import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { useSelfPlayerSchema } from '../../../../../../ui/hooks/use-self-player-schema';
import styles from './styles.module.scss';
export const LevelPanel = () => {
    const player = useSelfPlayerSchema();
    const level = useSchemaValue(player, 'level');
    const experienceProgress = useSchemaValue(player, 'experienceProgress');
    const experience = useSchemaValue(player, 'experience');
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.level, style: { '--progress': `${experienceProgress}%` } }, level),
        React.createElement("div", { className: styles.experience },
            React.createElement("div", { className: styles.label }, "EXP"),
            React.createElement("div", { className: styles.value }, experience))));
};
