// @ts-ignore
import { MeshLineMaterial } from 'three.meshline';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import bin from './mechanic.bin';
import gltf from './mechanic.gltf';
Assets.addModel(ModelType.DroidMechanic, {
    gltf,
    bin: {
        origin: 'mechanic.bin',
        path: bin,
    },
});
Assets.addMaterial(MaterialType.RepairLine, new MeshLineMaterial({
    color: 0xffffff,
    lineWidth: 0.1,
}));
