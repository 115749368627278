import { DEVICE_SCREEN_SCALE, DEVICE_TARGET_SCREEN_SIZE } from './const';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';
import { DeviceType } from '~/shared/core/device/types';
import { EventStream } from '~/shared/core/event-stream';
import { Utils } from '~/shared/core/utils';
export class Device {
    static get isLandscape() {
        return window.innerWidth > window.innerHeight;
    }
    static get pixelRatio() {
        return window.devicePixelRatio || 1;
    }
    static getWrapper() {
        const wrapper = document.getElementById('game');
        if (!wrapper) {
            throw Error('Device game wrapper isn`t found');
        }
        return wrapper;
    }
    static getScreenSize() {
        return this.isLandscape ? {
            x: window.innerWidth,
            y: window.innerHeight,
        } : {
            x: window.innerHeight,
            y: window.innerWidth,
        };
    }
    static getPositionOnScreen(position) {
        return this.isLandscape ? {
            x: position.x,
            y: position.y,
        } : {
            x: position.y,
            y: window.innerWidth - position.x,
        };
    }
    static normalizePosition(position) {
        const screenSize = this.getScreenSize();
        return {
            x: (position.x / screenSize.x) * 2 - 1,
            y: -(position.y / screenSize.y) * 2 + 1,
        };
    }
    static getScale() {
        const screenSize = Device.getScreenSize();
        const scaleX = Utils.clamp(screenSize.x / DEVICE_TARGET_SCREEN_SIZE.width, DEVICE_SCREEN_SCALE.min, DEVICE_SCREEN_SCALE.max);
        const scaleY = Utils.clamp(screenSize.y / DEVICE_TARGET_SCREEN_SIZE.height, DEVICE_SCREEN_SCALE.min, DEVICE_SCREEN_SCALE.max);
        return Math.min(scaleX, scaleY);
    }
    static addPlatformLabel() {
        const label = this.type.toLowerCase();
        this.getWrapper().setAttribute('data-device', label);
    }
    static addListeners() {
        this.calculateViewportSize();
        window.addEventListener('resize', () => {
            this.calculateViewportSize();
            this.events.onScreenResize.invoke();
        });
        window.addEventListener('blur', () => {
            this.events.onScreenHide.invoke();
        });
        window.addEventListener('beforeunload', () => {
            this.events.onScreenHide.invoke();
            this.events.onExit.invoke();
        });
        window.addEventListener('focus', () => {
            this.events.onScreenRelease.invoke();
        });
    }
    static isWebGLSupport() {
        try {
            const canvas = document.createElement('canvas');
            return Boolean(window.WebGLRenderingContext &&
                (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
        }
        catch (e) {
            return false;
        }
    }
    static checkSupports() {
        const webgl = this.isWebGLSupport();
        if (!webgl) {
            SystemMessageUtils.render('invalid-browser', {
                type: SystemMessageType.Error,
                sign: '⚠️',
                title: 'Unsupported Browser',
                message: 'Your browser do not support WebGL feature',
            });
        }
        return webgl;
    }
    static calculateViewportSize() {
        const rootStyle = document.documentElement.style;
        rootStyle.setProperty('--viewport-width', `${window.innerWidth}px`);
        rootStyle.setProperty('--viewport-height', `${window.innerHeight}px`);
    }
}
Device.events = {
    onScreenResize: new EventStream(),
    onScreenRelease: new EventStream(),
    onScreenHide: new EventStream(),
    onExit: new EventStream(),
};
Device.type = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? DeviceType.Mobile
    : DeviceType.Desktop);
