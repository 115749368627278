import cn from 'classnames';
import React, { useCallback } from 'react';
import { INVENTORY_ITEM_ICON_MAP } from '../../const';
import { useInventorySelection } from '../../hooks/use-inventory-selection';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import { useUserData } from '~/client/rooms/relay/ui/hooks/use-user-data';
import { InventoryUtils } from '~/shared/core/user/utils';
import { Utils } from '~/shared/core/utils';
import { RelayRequest } from '~/shared/rooms/relay/types';
import styles from './styles.module.scss';
import IconCrystal from './icons/crystal.svg';
import IconLock from './icons/lock.svg';
export const InventoryItem = ({ item, have }) => {
    const room = useRoom();
    const language = useLanguage();
    const { slot, closeSelection } = useInventorySelection();
    const inventory = useUserData('inventory');
    const crystals = useUserData('crystals');
    const itemCurrentSlot = InventoryUtils.getItemSlot(inventory, item);
    const active = itemCurrentSlot === slot;
    const IconComponent = INVENTORY_ITEM_ICON_MAP[item];
    const cost = InventoryUtils.getItemCost(item);
    const allow = crystals >= cost;
    const handleClick = useCallback(() => {
        if (have) {
            if (active) {
                closeSelection();
                return;
            }
            room.sendRequest(RelayRequest.UpdateInvetorySlot, { item, slot })
                .then(closeSelection);
        }
        else {
            if (!allow) {
                return false;
            }
            room.sendRequest(RelayRequest.BuyInvetoryItem, { item });
        }
    }, [have, active, allow]);
    return (React.createElement(Interactive, { className: cn(styles.wrapper, {
            [styles.have]: have,
            [styles.active]: active,
            [styles.allow]: allow,
        }), onClick: handleClick },
        React.createElement("div", { className: styles.meta },
            React.createElement("div", { className: styles.frame },
                React.createElement(IconComponent, { className: styles.icon })),
            !have && (React.createElement("div", { className: styles.cost },
                React.createElement(IconCrystal, null),
                Utils.decorateNumber(cost)))),
        React.createElement("div", { className: styles.info },
            React.createElement("div", { className: styles.name },
                !have && React.createElement(IconLock, null),
                language(`${item}Name`)),
            React.createElement("div", { className: styles.description }, language(`${item}Description`)))));
};
