import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { DamageDisplayState } from './types';
import { useSelfPlayerSchema } from '../../../../../../ui/hooks/use-self-player-schema';
import styles from './styles.module.scss';
export const DamageEffect = () => {
    const player = useSelfPlayerSchema();
    const [state, setState] = useState(DamageDisplayState.None);
    const prevHealth = useRef(0);
    const timeout = useRef(null);
    useEffect(() => {
        const unlisten = player.live.listen('health', (newHealth) => {
            if (newHealth < prevHealth.current) {
                if (timeout.current) {
                    clearTimeout(timeout.current);
                }
                timeout.current = setTimeout(() => {
                    setState(DamageDisplayState.End);
                    timeout.current = setTimeout(() => {
                        setState(DamageDisplayState.None);
                        timeout.current = null;
                    }, 500);
                }, 500);
                setState(DamageDisplayState.Beg);
            }
            prevHealth.current = newHealth;
        });
        return () => {
            unlisten();
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);
    return state !== DamageDisplayState.None && (React.createElement("div", { className: cn(styles.effect, {
            [styles.beg]: state === DamageDisplayState.Beg,
            [styles.end]: state === DamageDisplayState.End,
        }) }));
};
