import { InputKeyboard } from './keyboard';
import { InputMouse } from './mouse';
import { InputTouch } from './touch';
import { SCENE_CANVAS_ID } from '../scene/const';
export class Input {
    static addListeners() {
        InputKeyboard.addListeners();
        InputMouse.addListeners();
        InputTouch.addListeners();
    }
    static isWorldTarget(event) {
        const element = event.target;
        return element.id === SCENE_CANVAS_ID;
    }
    static isNativeTarget(event) {
        return event.target.nodeName === 'INPUT';
    }
}
