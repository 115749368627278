import { Droid } from '../..';
import { ModelType } from '../../../../../../../../core/assets/types';
import './resources';
export class DroidMedic extends Droid {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.DroidMedic,
        }, schema);
    }
}
