import { Vector2 } from 'three';
import { Input } from '..';
import { Device } from '../../device';
import { EventStream } from '~/shared/core/event-stream';
export class InputMouse {
    static addListeners() {
        this.listenMouseMove();
        this.listenMouseDown();
        this.listenMouseUp();
        this.listenMouseWheel();
    }
    static setCursorPointer(state) {
        Device.getWrapper().style.cursor = state ? 'pointer' : '';
    }
    static updatePosition(event) {
        const positionOnScreen = Device.getPositionOnScreen({
            x: event.clientX,
            y: event.clientY,
        });
        this.position.copy(positionOnScreen);
        this.normalizedPosition.copy(Device.normalizePosition(this.position));
    }
    static listenMouseMove() {
        document.addEventListener('mousemove', (event) => {
            this.updatePosition(event);
            this.events.onMouseMove.invoke(event);
            if (Input.isWorldTarget(event)) {
                this.events.onMouseMoveWorld.invoke(event);
            }
        });
    }
    static listenMouseDown() {
        document.addEventListener('mousedown', (event) => {
            this.events.onMouseClick.invoke(event);
            if (Input.isWorldTarget(event)) {
                this.events.onMouseClickWorld.invoke(event);
            }
        });
    }
    static listenMouseUp() {
        document.addEventListener('mouseup', (event) => {
            this.events.onMouseRelease.invoke(event);
        });
    }
    static listenMouseWheel() {
        document.addEventListener('wheel', (event) => {
            this.events.onMouseWheel.invoke(event);
        }, {
            passive: false,
        });
    }
}
InputMouse.position = new Vector2();
InputMouse.normalizedPosition = new Vector2();
InputMouse.events = {
    onMouseMove: new EventStream(),
    onMouseMoveWorld: new EventStream(),
    onMouseClick: new EventStream(),
    onMouseClickWorld: new EventStream(),
    onMouseRelease: new EventStream(),
    onMouseWheel: new EventStream(),
};
