import { Builder } from './builder';
import { BATTLE_PING_LISTEN_RATE } from './const';
import { Minimap } from './minimap';
import { BattleScene } from './scene';
import { Terrain } from './terrain';
import { BattleScreen } from './ui';
import { Wave } from './wave';
import { Room } from '../../core/room';
import { Interface } from '../../core/ui';
import { AudioType } from '~/client/core/audio/types';
import { Client } from '~/client/core/client';
import { Messages } from '~/client/core/messages';
import { MessagesBuffer } from '~/client/core/messages/buffer';
import { SDK } from '~/client/core/sdk';
import { EventStream } from '~/shared/core/event-stream';
import { BattleMessage, BattleMode, BattleStage } from '~/shared/rooms/battle/types';
import './resources';
export class Battle extends Room {
    constructor(room) {
        super(room);
        this.wave = null;
        this.ping = 0;
        this.entitiesUI = new Map();
        this.events = {
            onStart: new EventStream(),
            onFinish: new EventStream(),
            onEntityChangeUI: new EventStream(),
        };
        this.messagesBuffer = new MessagesBuffer();
        this.messages = new Messages(this.origin, this.messagesBuffer);
        this.scene = new BattleScene(this);
        this.builder = new Builder(this);
        this.terrain = new Terrain(this);
        this.minimap = new Minimap(this);
        if (this.state.wave) {
            this.wave = new Wave(this);
        }
        this.scene.bindSchemaWithEntities();
        this.listenPing();
        Interface.mount(BattleScreen, this);
        this.state.listen('stage', (stage) => {
            switch (stage) {
                case BattleStage.Started: {
                    this.start();
                    Client.hideLoading();
                    break;
                }
                case BattleStage.Finished: {
                    this.finish();
                    Client.hideLoading();
                    break;
                }
            }
        });
        this.origin.onLeave(() => {
            SDK.togglePlaying(false);
        });
        Client.unmarkNewbie();
    }
    destroy() {
        clearInterval(this.pingListener);
        this.messagesBuffer.clear();
        this.scene.destroy();
        this.terrain.destroy();
        this.builder.destroy();
        super.destroy();
    }
    getSelfPlayer() {
        const schema = this.getSelfPlayerSchema();
        const player = this.scene.getEntity(schema.id);
        if (!player) {
            throw Error('Unknown self player');
        }
        return player;
    }
    getSelfPlayerSchema() {
        const player = this.state.players.get(this.sessionId);
        if (!player) {
            throw Error('Unknown self player schema');
        }
        return player;
    }
    getOpponentPlayerSchema() {
        const player = Array.from(this.state.players.values()).find((player) => (player.id !== this.sessionId));
        if (!player) {
            throw Error('Unknown opponent player');
        }
        return player;
    }
    listenPing() {
        this.pingListener = setInterval(() => {
            this.messages.send(BattleMessage.Ping, {
                stamp: performance.now(),
            });
        }, BATTLE_PING_LISTEN_RATE);
        this.messages.on(BattleMessage.Ping, ({ stamp }) => {
            const now = performance.now();
            this.ping = now - stamp;
        });
    }
    restart() {
        this.messages.send(BattleMessage.Restart, void {});
    }
    start() {
        SDK.togglePlaying(true);
        this.events.onStart.invoke();
    }
    togglePause(paused) {
        if (this.state.mode === BattleMode.Online ||
            this.state.paused === paused) {
            return;
        }
        SDK.togglePlaying(!paused);
        this.messages.send(BattleMessage.TogglePause, { paused });
    }
    finish() {
        SDK.togglePlaying(false);
        this.events.onFinish.invoke();
        const audio = this.state.winnerId === this.sessionId
            ? AudioType.Win
            : AudioType.GameOver;
        this.scene.audio.play2D(audio);
    }
    setEntityUI(entity, ui) {
        if (ui) {
            this.entitiesUI.set(entity, ui);
        }
        else {
            this.entitiesUI.delete(entity);
        }
        this.events.onEntityChangeUI.invoke();
    }
}
Battle.allowReconnection = true;
