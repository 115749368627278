import cn from 'classnames';
import React from 'react';
import { useUserData } from '../../../../hooks/use-user-data';
import { Utils } from '~/shared/core/utils';
import styles from './styles.module.scss';
import IconScore from '../icons/score.svg';
export const Record = ({ place, name, score }) => {
    const currentName = useUserData('name');
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: cn(styles.place, {
                [styles.self]: name === currentName,
            }) }, place),
        React.createElement("div", { className: styles.name }, name),
        React.createElement("div", { className: styles.score },
            Utils.decorateNumber(score),
            React.createElement(IconScore, null))));
};
