import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { SCROLLBAR_DRAG_FACTOR, SCROLLBAR_SMOOTH } from './const';
import { useDraggable } from '../../hooks/use-draggable';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { DeviceType } from '~/shared/core/device/types';
import { Utils } from '~/shared/core/utils';
import styles from './styles.module.scss';
export const Scrollbar = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const refWrapper = useRef(null);
    const refContent = useRef(null);
    const refThumb = useRef(null);
    const refBeginPosition = useRef(0);
    const { dragging } = useDraggable({
        active: visible,
        target: refWrapper,
        control: refThumb,
        onStart: () => {
            refBeginPosition.current = getCurrentPosition();
        },
        onDrag: (distance) => {
            const wrapper = refWrapper.current;
            if (!wrapper) {
                return;
            }
            const factor = Device.type == DeviceType.Mobile ? SCROLLBAR_DRAG_FACTOR : 1.0;
            const offset = (distance * factor) / wrapper.clientHeight;
            const newPosition = refBeginPosition.current + offset;
            updatePosition(newPosition);
        },
    });
    const getRatio = () => {
        const wrapper = refWrapper.current;
        const content = refContent.current;
        return wrapper && content
            ? Math.max(wrapper.clientHeight / content.clientHeight, 0)
            : 0;
    };
    const updateSize = (ratio) => {
        const thumb = refThumb.current;
        if (!thumb) {
            return;
        }
        thumb.style.height = `${ratio * 100}%`;
    };
    const getCurrentPosition = () => {
        const thumb = refThumb.current;
        return (thumb === null || thumb === void 0 ? void 0 : thumb.style.top) ? parseFloat(thumb.style.top) / 100 : 0;
    };
    const updatePosition = (position, ratio = getRatio()) => {
        const content = refContent.current;
        const thumb = refThumb.current;
        if (!content || !thumb) {
            return;
        }
        const normalPosition = Utils.clamp(position, 0, 1 - ratio);
        thumb.style.top = `${normalPosition * 100}%`;
        content.style.top = `${-normalPosition * (1 / ratio) * 100}%`;
    };
    const handleScreenResize = () => {
        const wrapper = refWrapper.current;
        const content = refContent.current;
        if (!wrapper || !content) {
            return;
        }
        const currentVisible = content.clientHeight > wrapper.clientHeight;
        setVisible(currentVisible);
        if (currentVisible) {
            const ratio = getRatio();
            const currentPosition = getCurrentPosition();
            updateSize(ratio);
            updatePosition(currentPosition, ratio);
        }
    };
    const handleMouseWheel = (event) => {
        const wrapper = refWrapper.current;
        if (!wrapper || !event.composedPath().includes(wrapper)) {
            return;
        }
        const currentPosition = getCurrentPosition();
        const newPosition = currentPosition + (event.deltaY / SCROLLBAR_SMOOTH);
        updatePosition(newPosition);
    };
    useEffect(() => {
        const content = refContent.current;
        if (!content) {
            return;
        }
        handleScreenResize();
        const observer = new MutationObserver(() => {
            handleScreenResize();
        });
        observer.observe(content, {
            childList: true,
            subtree: true,
        });
        const eventScreenResize = Device.events.onScreenResize.on(handleScreenResize);
        return () => {
            eventScreenResize.off();
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        if (!visible || Device.type === DeviceType.Mobile) {
            return;
        }
        const eventMouseWheel = InputMouse.events.onMouseWheel.on(handleMouseWheel);
        return () => {
            eventMouseWheel.off();
        };
    }, [visible]);
    return (React.createElement("div", { ref: refWrapper, className: styles.wrapper },
        React.createElement("div", { ref: refContent, className: styles.content }, children),
        React.createElement("div", { className: cn(styles.track, {
                [styles.visible]: visible,
            }) },
            React.createElement("div", { ref: refThumb, className: styles.thumb },
                React.createElement("div", { className: cn(styles.shape, {
                        [styles.dragging]: dragging,
                    }) })))));
};
