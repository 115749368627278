import React, { useCallback } from 'react';
import { useRoom } from '../../../../../../core/room/ui/hooks/use-room';
import { Interactive } from '../../../../../../core/ui/components/interactive';
import { Section } from '../../../../../../core/ui/components/section';
import { useUserData } from '../../../hooks/use-user-data';
import { Client } from '~/client/core/client';
import { RelayRequest } from '~/shared/rooms/relay/types';
import styles from './styles.module.scss';
import IconCrystal from './icons/crystal.svg';
import IconLogout from './icons/logout.svg';
import IconScore from './icons/score.svg';
export const UserInfo = () => {
    const room = useRoom();
    const name = useUserData('name');
    const crystals = useUserData('crystals');
    const score = useUserData('score');
    const handleClickLogout = useCallback(() => {
        room.sendRequest(RelayRequest.Logout)
            .then(() => {
            Client.removeAuthToken();
            room.remountInterface();
        });
    }, []);
    return (React.createElement("div", { className: styles.container },
        React.createElement(Section, { direction: "vertical", align: "left", gap: 8 },
            React.createElement("div", { className: styles.name }, name),
            React.createElement(Section, { direction: "horizontal", gap: 16 },
                React.createElement("div", { className: styles.amount },
                    React.createElement(IconCrystal, null),
                    crystals),
                React.createElement("div", { className: styles.amount },
                    React.createElement(IconScore, null),
                    score))),
        React.createElement(Interactive, { onClick: handleClickLogout, className: styles.logout },
            React.createElement(IconLogout, null))));
};
