import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ActiveScreen } from '../../../../state';
import { ScreenType } from '../../../../types';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconMenu from './icons/menu.svg';
export const MenuButton = () => {
    const [screen, setScreen] = useRecoilState(ActiveScreen);
    const handleClick = useCallback(() => {
        setScreen(screen === ScreenType.Menu ? null : ScreenType.Menu);
    }, [screen]);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.active]: screen === ScreenType.Menu,
        }) },
        React.createElement(IconMenu, null)));
};
