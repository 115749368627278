var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { VERSION_CHECK_UPDATING_INTERVAL } from './const';
import pkg from '../../../../package.json';
import { Request } from '../request';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';
import { VERSION_ROUTE_PATH } from '~/shared/core/version/const';
export class Version {
    static checkClientVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            const clientVersion = pkg.version;
            const serverVersion = yield this.getServerVersion();
            if (!serverVersion) {
                this.waitServerUpdating();
                return false;
            }
            const currentVersion = this.normalizeVersion(clientVersion);
            const targetVersion = this.normalizeVersion(serverVersion);
            for (let i = 0; i < 3; i++) {
                if (currentVersion[i] < targetVersion[i]) {
                    SystemMessageUtils.render('invalid-version', {
                        type: SystemMessageType.Error,
                        sign: '⚠️',
                        title: 'Invalid Version',
                        message: [
                            `Target game version is ${serverVersion}, but your client version is ${clientVersion}`,
                            'Please, clear cache and try again',
                        ],
                    });
                    return false;
                }
                else if (currentVersion[i] > targetVersion[i]) {
                    this.waitServerUpdating();
                    SystemMessageUtils.render('server-updating', {
                        type: SystemMessageType.Info,
                        sign: '🔄',
                        title: 'Updating',
                        message: [
                            'Game server is updating',
                            'Please, wait...',
                        ],
                    });
                    return false;
                }
            }
            return true;
        });
    }
    static waitServerUpdating() {
        setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            const valid = yield this.checkClientVersion();
            if (valid) {
                window.location.reload();
            }
        }), VERSION_CHECK_UPDATING_INTERVAL);
    }
    static normalizeVersion(rawVersion) {
        const [version] = rawVersion.split('-');
        return version.split('.').map((part) => Number(part));
    }
    static getServerVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            return Request.get(VERSION_ROUTE_PATH)
                .then((res) => res.version)
                .catch(() => null);
        });
    }
}
