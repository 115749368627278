import { useSchemaListener } from 'colyseus-schema-hooks';
import { useState } from 'react';
import { useSelfPlayerSchema } from '../hooks/use-self-player-schema';
export function useTutorialSteps() {
    const player = useSelfPlayerSchema();
    const [steps, setSteps] = useState(new Set());
    useSchemaListener(player.tutorial.activeSteps, {
        onAdd: (step) => {
            setSteps((current) => {
                const newHints = new Set(current);
                newHints.add(step);
                return newHints;
            });
        },
        onRemove: (step) => {
            setSteps((current) => {
                const newHints = new Set(current);
                newHints.delete(step);
                return newHints;
            });
        },
    });
    return steps;
}
