import React from 'react';
import { DIFFICULT_ICON_MAP } from './const';
import { Kind } from '../kind';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { BattleDifficult } from '~/shared/rooms/battle/types';
import { RelayRequest } from '~/shared/rooms/relay/types';
export const SelectDifficult = () => {
    const room = useRoom();
    const language = useLanguage();
    const modes = Object.keys(BattleDifficult);
    const handleClick = (difficult) => {
        room.sendRequest(RelayRequest.PlaySingle, { difficult });
    };
    return (React.createElement(Section, { direction: "vertical", gap: 10 }, modes.map((difficult) => (React.createElement(Kind, { key: difficult, label: language(`Difficult${difficult}`), description: language(`Difficult${difficult}Description`), icon: DIFFICULT_ICON_MAP[difficult], onClick: () => handleClick(difficult) })))));
};
