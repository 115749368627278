import { Color, MeshLambertMaterial } from 'three';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
Assets.addMaterial(MaterialType.MarkerSelf, new MeshLambertMaterial({
    color: new Color(0x00ff00),
    reflectivity: 0,
}));
Assets.addMaterial(MaterialType.MarkerOpponent, new MeshLambertMaterial({
    color: new Color(0xff0000),
    reflectivity: 0,
}));
