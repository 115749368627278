import { SceneLayer } from '../../../scene/types';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Cube } from '~/client/core/render-item/shape/cube';
export class Platform extends Cube {
    constructor(building) {
        super(building.battle.scene, {
            scale: { x: 1.2, y: 0.075, z: 1.2 },
            position: { x: 0.0, y: 0.07, z: 0 },
            material: building.selfOwn
                ? MaterialType.Self
                : MaterialType.Opponent,
        });
        this.object.layers.set(SceneLayer.Misc);
        building.renderItem.object.add(this.object);
    }
}
