import React, { useEffect, useRef } from 'react';
import { useRoom } from '../../../../core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Minimap = () => {
    const { minimap } = useRoom();
    const refCanvas = useRef(null);
    useEffect(() => {
        if (!refCanvas.current) {
            return;
        }
        minimap.enable(refCanvas.current);
        return () => {
            minimap.disable();
        };
    }, []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("canvas", { ref: refCanvas })));
};
