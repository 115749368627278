import { PageInventory } from './inventory';
import { PageLeaderboard } from './leaderboard';
import { PagePlay } from './play';
import { PageType } from './types';
import IconInventory from './icons/inventory.svg';
import IconLeaderboard from './icons/leaderboard.svg';
import IconPlay from './icons/play.svg';
export const PAGES = {
    [PageType.Play]: {
        component: PagePlay,
        icon: IconPlay,
    },
    [PageType.Inventory]: {
        component: PageInventory,
        icon: IconInventory,
        authorizedOnly: true,
    },
    [PageType.Leaderboard]: {
        component: PageLeaderboard,
        icon: IconLeaderboard,
    },
};
