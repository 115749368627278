import { InstancedMesh, Mesh } from 'three';
import { Decoration } from './decoration';
import { Fog } from './fog';
import { Tile } from './tile';
import { TILE_OFFSET } from './tile/const';
import { SceneLayer } from '../scene/types';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { TerrainBiomeType } from '~/shared/rooms/battle/terrain/types';
import './resources';
export class Terrain {
    constructor(battle) {
        this.tileGroups = [];
        this.decorations = new Map();
        this.battle = battle;
        this.fog = new Fog(this.battle);
        this.battle.scene.audio.play2D(AudioType.Ambient);
        this.battle.state.terrain.onChange(() => {
            this.reset();
            this.create();
        });
    }
    destroy() {
        this.fog.destroy();
        this.reset();
    }
    create() {
        this.battle.state.terrain.tiles.forEach((tile) => {
            new Tile(this.battle, tile);
        });
        this.battle.state.terrain.decorations.forEach((decoration) => {
            new Decoration(this.battle, decoration);
        });
        this.createTileMeshes();
        this.createDecorationMeshes();
    }
    reset() {
        this.tileGroups.forEach((group) => {
            group.geometry.dispose();
            group.children.forEach((tile) => {
                tile.destroy();
            });
        });
        this.tileGroups = [];
        this.decorations.forEach((decorations) => {
            decorations.forEach((decoration) => {
                decoration.destroy();
            });
        });
        this.decorations.clear();
    }
    createTileMeshes() {
        this.tileGroups.forEach(({ geometry, biomeIndex, biomeType, children }) => {
            const material = Assets.getMaterial(`${biomeType}${biomeIndex}`);
            const mesh = this.createInstancedMesh(children, geometry, material);
            mesh.name = biomeType;
            mesh.position.setY(TILE_OFFSET[biomeType]);
            switch (biomeType) {
                case TerrainBiomeType.Liquid: {
                    mesh.layers.set(SceneLayer.Liquid);
                    break;
                }
                case TerrainBiomeType.Mounts: {
                    mesh.layers.set(SceneLayer.Mounts);
                    break;
                }
                default: {
                    mesh.layers.set(SceneLayer.Ground);
                    break;
                }
            }
        });
    }
    createDecorationMeshes() {
        const material = Assets.getMaterial(MaterialType.Decoration);
        this.decorations.forEach((decorations, type) => {
            if (decorations.length === 0) {
                return;
            }
            let geometry = null;
            Assets.getModel(type).scene.traverse((mesh) => {
                if (mesh instanceof Mesh) {
                    geometry = mesh.geometry;
                }
            });
            if (!geometry) {
                throw Error(`Undefined geometry of deocartion '${type}'`);
            }
            const mesh = this.createInstancedMesh(decorations, geometry, material);
            mesh.layers.set(SceneLayer.Misc);
        });
    }
    createInstancedMesh(items, geometry, material) {
        const mesh = new InstancedMesh(geometry, material, items.length);
        items.forEach((item, index) => {
            item.initialize(mesh, index);
        });
        this.battle.scene.add(mesh);
        return mesh;
    }
}
