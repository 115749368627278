import { Bullet } from '..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import './resources';
export class BulletSimple extends Bullet {
    constructor(battle, schema) {
        super(battle, {
            size: 0.5,
            material: MaterialType.BulletSimple,
            audioEffect: AudioType.ShotSimple,
        }, schema);
    }
}
