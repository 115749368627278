import { BufferGeometry, DirectionalLight, Float32BufferAttribute, Points, Vector2, Vector3, } from 'three';
import { RELAY_SCENE_BACKGROUND_COLOR, RELAY_SCENE_LIGHT, RELAY_SCENE_SPACE_DUST_COUNT, RELAY_SCENE_SPACE_DUST_LAYERS, RELAY_SCENE_SPACE_DUST_SPREAD, } from './const';
import { Assets } from '../../../core/assets';
import { ModelType } from '../../../core/assets/types';
import { Device } from '../../../core/device';
import { Model } from '../../../core/render-item/model';
import { Scene } from '../../../core/scene';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Client } from '~/client/core/client';
import { InputMouse } from '~/client/core/input/mouse';
import { InventoryItemType } from '~/shared/core/user/inventory/item/types';
import { InventoryUtils } from '~/shared/core/user/utils';
import { Utils } from '~/shared/core/utils';
import { DroidVariant } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
import './resources';
export class RelayScene extends Scene {
    constructor(relay) {
        super({
            light: RELAY_SCENE_LIGHT,
            backgroundColor: RELAY_SCENE_BACKGROUND_COLOR,
        });
        this.models = new Set();
        this.cameraLookUp = new Vector3();
        this.relay = relay;
        this.setCameraPosition();
        this.addLocalLight();
        this.addDecorationModels();
        this.addSpaceDust();
        this.onUpdate = this.onUpdate.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.events.onUpdate.on(this.onUpdate);
        InputMouse.events.onMouseMove.on(this.onMouseMove);
    }
    destroy() {
        this.models.forEach((model) => {
            model.destroy();
        });
        this.models.clear();
        this.events.onUpdate.off(this.onUpdate);
        InputMouse.events.onMouseMove.off(this.onMouseMove);
        super.destroy();
    }
    onUpdate() {
        this.models.forEach((model) => {
            model.update();
        });
        this.animateSpaceDust();
    }
    onMouseMove(event) {
        const screenSize = Device.getScreenSize();
        const halfSize = new Vector2().copy(screenSize).divideScalar(2);
        const point = {
            x: ((event.clientX - halfSize.x) / halfSize.x) * 0.1,
            y: ((event.clientY - halfSize.y) / halfSize.y) * 0.1,
        };
        this.cameraLookUp.set(0 + point.x, 1.2 - point.y, 9);
    }
    syncCamera() {
        this.camera.position.lerp(this.cameraLookUp, 0.025);
    }
    setCameraPosition() {
        this.cameraLookUp.set(0, 1.2, 9);
        this.camera.position.set(0, 1.2, 6);
        this.camera.lookAt(new Vector3(0, 1.2, 0));
    }
    addLocalLight() {
        const directionalLight = new DirectionalLight(0xffffff, 0.7);
        directionalLight.position.set(0, 10, 0);
        this.add(directionalLight);
    }
    addPlayerModel() {
        const player = new Model(this, {
            model: ModelType.Player,
            material: MaterialType.Unit,
            position: { x: 0.5, y: 0, z: 1 },
        });
        player.setMaterial(MaterialType.Self, 'BodyMesh');
        player.animator.play('idle', {
            timeScale: 0.75,
        });
        this.models.add(player);
    }
    addDroidModel() {
        const user = this.relay.state.users.get(Client.sessionId);
        if (!user) {
            throw Error('Undefiend current user');
        }
        const getVariant = () => {
            var _a;
            return ((_a = InventoryUtils.getItem(user.inventory, InventoryItemType.Droid)) !== null && _a !== void 0 ? _a : DroidVariant.Combat);
        };
        let currentVariant = getVariant();
        const create = () => {
            this.droid = new Model(this, {
                model: ModelType[`Droid${currentVariant}`],
                material: MaterialType.Unit,
                position: { x: -0.05, y: 1.2, z: -1 },
            });
            this.droid.setRotation({ x: 0, y: -Math.PI / 8, z: 0 });
            this.droid.setMaterial(MaterialType.Self, 'Cube_2');
            this.droid.animator.play('fly', {
                timeScale: 0.1,
            });
            this.models.add(this.droid);
        };
        user.inventory.onChange(() => {
            const newVariant = getVariant();
            if (currentVariant !== newVariant) {
                currentVariant = newVariant;
                this.models.delete(this.droid);
                this.droid.destroy();
                create();
            }
        });
        create();
    }
    addDecorationModels() {
        this.models.add(new Model(this, {
            model: ModelType.Tree1,
            material: MaterialType.Decoration,
            position: { x: 2, y: -0.4, z: -8 },
            rotation: { x: 0, y: Math.PI, z: 0 },
            scale: 0.8,
        }));
        this.models.add(new Model(this, {
            model: ModelType.Tree2,
            material: MaterialType.Decoration,
            position: { x: -0.2, y: -0.6, z: -8 },
            rotation: { x: 0.4, y: -0.2, z: 0 },
            scale: 0.6,
        }));
        this.models.add(new Model(this, {
            model: ModelType.Tree2,
            material: MaterialType.Decoration,
            position: { x: 0.8, y: -0.4, z: -8 },
            rotation: { x: 0, y: -Math.PI / 4, z: 0 },
            scale: 0.8,
        }));
    }
    addSpaceDust() {
        const vertices = [];
        for (let i = 0; i < RELAY_SCENE_SPACE_DUST_COUNT * 3; i++) {
            vertices.push(Utils.randomBetween(-RELAY_SCENE_SPACE_DUST_SPREAD, RELAY_SCENE_SPACE_DUST_SPREAD), Utils.randomBetween(-RELAY_SCENE_SPACE_DUST_SPREAD, RELAY_SCENE_SPACE_DUST_SPREAD), Utils.randomBetween(-RELAY_SCENE_SPACE_DUST_SPREAD, RELAY_SCENE_SPACE_DUST_SPREAD));
        }
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        RELAY_SCENE_SPACE_DUST_LAYERS.forEach((depth, i) => {
            // @ts-ignore
            const material = Assets.getMaterial(MaterialType[`Dust${i}`]);
            const particles = new Points(geometry, material);
            particles.rotation.x = Math.random() * Math.PI * 2;
            particles.rotation.y = Math.random() * Math.PI * 2;
            particles.rotation.z = Math.random() * Math.PI * 2;
            particles.position.set(0, 0, depth);
            this.add(particles);
        });
    }
    animateSpaceDust() {
        for (let i = 0; i < this.children.length; i++) {
            const object = this.children[i];
            if (object instanceof Points) {
                object.rotation.y += 0.0001;
            }
        }
    }
}
