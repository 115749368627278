export var MaterialType;
(function (MaterialType) {
    MaterialType["Personal"] = "Personal";
    MaterialType["Self"] = "Self";
    MaterialType["Opponent"] = "Opponent";
    MaterialType["IndicatorSelf"] = "IndicatorSelf";
    MaterialType["IndicatorOpponent"] = "IndicatorOpponent";
    MaterialType["MarkerSelf"] = "MarkerSelf";
    MaterialType["MarkerOpponent"] = "MarkerOpponent";
    MaterialType["Building"] = "Building";
    MaterialType["BuildingInvalid"] = "BuildingInvalid";
    MaterialType["Unit"] = "Unit";
    MaterialType["UnitTransparent"] = "UnitTransparent";
    MaterialType["UnitFrost"] = "UnitFrost";
    MaterialType["Fog"] = "Fog";
    MaterialType["Decoration"] = "Decoration";
    MaterialType["Dust0"] = "Dust0";
    MaterialType["Dust1"] = "Dust1";
    MaterialType["Dust2"] = "Dust2";
    MaterialType["Dust3"] = "Dust3";
    MaterialType["AttackArea"] = "AttackArea";
    MaterialType["ActionArea"] = "ActionArea";
    MaterialType["BuildArea"] = "BuildArea";
    MaterialType["LaserLine"] = "LaserLine";
    MaterialType["RepairLine"] = "RepairLine";
    MaterialType["BulletSimple"] = "BulletSimple";
    MaterialType["BulletFire"] = "BulletFire";
    MaterialType["BulletFrost"] = "BulletFrost";
    MaterialType["Liquid0"] = "Liquid0";
    MaterialType["Liquid1"] = "Liquid1";
    MaterialType["Liquid2"] = "Liquid2";
    MaterialType["Liquid3"] = "Liquid3";
    MaterialType["Coast0"] = "Coast0";
    MaterialType["Coast1"] = "Coast1";
    MaterialType["Coast2"] = "Coast2";
    MaterialType["Fields0"] = "Fields0";
    MaterialType["Fields1"] = "Fields1";
    MaterialType["Fields2"] = "Fields2";
    MaterialType["Mounts0"] = "Mounts0";
    MaterialType["Mounts1"] = "Mounts1";
    MaterialType["Mounts2"] = "Mounts2";
})(MaterialType || (MaterialType = {}));
