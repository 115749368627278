import React from 'react';
import { PARAMETER_ICON_MAP } from './const';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const Parameter = ({ type, value }) => {
    const IconComponent = PARAMETER_ICON_MAP[type];
    return (React.createElement(Section, { direction: "horizontal", align: "center", gap: 8 },
        React.createElement(IconComponent, null),
        React.createElement("div", { className: styles.amount }, value)));
};
