import React, { useEffect, useState } from 'react';
import { DEBUG_UPDATE_PARAMETERS_RATE } from './const';
import { Parameter } from './parameter';
import { useRoom } from '../../../../../core/room/ui/hooks/use-room';
import { Section } from '../../../../../core/ui/components/section';
export const Debug = () => {
    const battle = useRoom();
    const [fps, setFps] = useState(0);
    const [memory, setMemory] = useState(0);
    const [ping, setPing] = useState(0);
    // const [geometries, setGeometries] = useState(0);
    // const [textures, setTextures] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            var _a;
            setFps(battle.scene.fps);
            setPing(Math.ceil(battle.ping));
            // setGeometries(battle.scene.renderer.info.memory.geometries);
            // setTextures(battle.scene.renderer.info.memory.textures);
            // @ts-ignore
            const heapSize = (_a = performance === null || performance === void 0 ? void 0 : performance.memory) === null || _a === void 0 ? void 0 : _a.usedJSHeapSize;
            if (heapSize) {
                setMemory(Math.round(heapSize / 1024 / 1024));
            }
        }, DEBUG_UPDATE_PARAMETERS_RATE);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (React.createElement(Section, { direction: 'vertical', gap: 2 },
        React.createElement(Parameter, { label: 'fps', value: fps, warn: fps < 30, alert: fps < 20 }),
        React.createElement(Parameter, { label: 'ms', value: ping, warn: ping > 50, alert: ping > 100 }),
        React.createElement(Parameter, { label: 'mb', value: memory, warn: memory > 256, alert: memory > 512 })));
};
