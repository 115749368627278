import { AudioType } from '~/client/core/audio/types';
import { Messages } from '~/client/core/messages';
import { WaveMessage } from '~/shared/rooms/battle/wave/types';
import './resources';
export class Wave {
    constructor(battle) {
        this.battle = battle;
        this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);
        this.messages.on(WaveMessage.Start, () => {
            this.battle.scene.audio.play2D(AudioType.WaveStart);
        });
    }
}
