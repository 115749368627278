import { Assets } from '../../../../../../../../../core/assets';
import { ModelType } from '../../../../../../../../../core/assets/types';
import bin from './stingray.bin';
import gltf from './stingray.gltf';
Assets.addModel(ModelType.MobStingray, {
    gltf,
    bin: {
        origin: 'stingray.bin',
        path: bin,
    },
});
