import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Controls } from './controls';
import { HealthBar } from './health-bar';
import { LevelBar } from './level-bar';
import { Parameters } from './parameters';
import { Section } from '../../../../../../../core/ui/components/section';
import { ActiveScreen } from '../../../../../ui/state';
import { BuildingPanelVisible } from '../../../types';
import { useBuilding } from '../../hooks/use-building';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import styles from './styles.module.scss';
export const BuildingPanel = () => {
    const building = useBuilding();
    const language = useLanguage();
    const screen = useRecoilValue(ActiveScreen);
    const [visible, setVisible] = useState(building.panelVisible);
    useEffect(() => {
        const eventChangePanelVisible = building.events.onChangePanelVisible.on(setVisible);
        return () => {
            eventChangePanelVisible.off();
        };
    }, []);
    if (visible === BuildingPanelVisible.None
        || screen) {
        return;
    }
    return (React.createElement("div", { className: styles.translator },
        React.createElement("div", { className: cn(styles.wrapper, {
                [styles.full]: visible === BuildingPanelVisible.Full,
            }) },
            React.createElement("div", { className: styles.container },
                React.createElement("div", { className: cn(styles.head, {
                        [styles.selfOwn]: building.selfOwn,
                        [styles.arrow]: visible === BuildingPanelVisible.Compact,
                    }) },
                    React.createElement("div", { className: styles.name }, language(`${building.schema.variant}Name`)),
                    React.createElement(Section, { direction: "vertical", gap: 4 },
                        React.createElement(HealthBar, null),
                        React.createElement(LevelBar, null))),
                visible === BuildingPanelVisible.Full && building.parameters.length > 0 && (React.createElement(Parameters, null))),
            visible === BuildingPanelVisible.Full && (React.createElement(Controls, null)))));
};
