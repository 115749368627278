import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { Interactive } from '../../../../../../../core/ui/components/interactive';
import { useUserData } from '../../../../hooks/use-user-data';
import { ActiveOverlay } from '../../../overlays/state';
import { OverlayType } from '../../../overlays/types';
import styles from './styles.module.scss';
export const Kind = ({ label, description, icon, authorizedOnly, accent, onClick, }) => {
    const authorized = useUserData('authorized');
    const [, setOverlay] = useRecoilState(ActiveOverlay);
    const IconComponent = icon;
    const handleClick = useCallback(() => {
        if (!authorizedOnly || authorized) {
            onClick();
        }
        else {
            setOverlay(OverlayType.Login);
        }
    }, [authorized]);
    return (React.createElement(Interactive, { onClick: handleClick, withAudio: true, className: cn(styles.container, {
            [styles.accent]: accent,
        }) },
        React.createElement(IconComponent, null),
        React.createElement("div", { className: styles.data },
            React.createElement("div", { className: styles.label }, label),
            React.createElement("div", { className: styles.description }, description))));
};
