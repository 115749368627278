import React from 'react';
import { CATEGORY_ICON_MAP } from './const';
import { Variant } from './variant';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { CardsList } from '~/client/rooms/battle/ui/components/cards-list';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/rooms/battle/entity/building/const';
import { BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
export const Category = ({ category, onSelect }) => {
    const battle = useRoom();
    const language = useLanguage();
    const variants = Object.values(BuildingVariant).filter((variant) => (BUILDINGS_SHARED_CONFIG[variant].category === category &&
        battle.builder.isBuildingEnabled(variant)));
    if (variants.length === 0) {
        return null;
    }
    return (React.createElement(CardsList, { icon: CATEGORY_ICON_MAP[category], label: language(category) }, variants.map((variant) => (React.createElement(Variant, { key: variant, variant: variant, onSelect: onSelect })))));
};
