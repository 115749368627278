import { Building } from '../..';
import { ModelType } from '../../../../../../core/assets/types';
import './resources';
export class BuildingGenerator extends Building {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingGenerator,
        }, schema);
    }
    onReady() {
        super.onReady();
        this.renderItem.animator.play('spin1', { timeScale: 0.5 });
        this.renderItem.animator.play('spin2', { timeScale: 0.5 });
    }
}
