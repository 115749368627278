export const SHADER_TINT_FRAGMENT = `
  varying vec2 vUv;

  uniform vec3 tintColor;
  uniform sampler2D targetTexture;
  uniform float intensity;

  void main() {
    gl_FragColor = vec4(tintColor, intensity);
  }
`;
