import React from 'react';
import { AlertLabel } from './components/alert-label';
import { BuildProgress } from './components/build-progress';
import { Indicators } from './components/indicators';
import { BuildingPanel } from './components/panel';
import { BuildingContext } from './context';
import { DamageLabel } from '../../ui/components/damage-label';
import { EntityPanel } from '../../ui/components/entity-panel';
export const BuildingUI = ({ target }) => (React.createElement(BuildingContext.Provider, { value: target },
    React.createElement(EntityPanel, { renderItem: target.renderItem, indicators: React.createElement(React.Fragment, null,
            React.createElement(DamageLabel, { live: target.schema.live }),
            React.createElement(Indicators, null)), meta: React.createElement(BuildingPanel, null), bars: React.createElement(BuildProgress, null) },
        React.createElement(AlertLabel, null))));
