import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useState } from 'react';
import { Label } from './label';
import { Section } from '../../../../../../../../core/ui/components/section';
import { usePlayer } from '../../hooks/use-player';
export const SkillLabels = () => {
    const player = usePlayer();
    const skills = useSchemaValue(player.schema, 'skills');
    const [usingSkills, setUsingSkills] = useState(new Set());
    useEffect(() => {
        const listeners = [];
        skills.forEach((skill, variant) => {
            const unlisten = skill.listen('using', (using) => {
                setUsingSkills((current) => {
                    const set = new Set(current);
                    if (using) {
                        set.add(variant);
                    }
                    else {
                        set.delete(variant);
                    }
                    return set;
                });
            });
            listeners.push(unlisten);
        });
        return () => {
            listeners.forEach((unlisten) => {
                unlisten();
            });
        };
    }, []);
    return (React.createElement(Section, { direction: "horizontal", gap: 4 }, Array.from(usingSkills).map((variant) => (React.createElement(Label, { key: variant, variant: variant })))));
};
