import { Easing, Tween } from '@tweenjs/tween.js';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Circle } from '~/client/core/render-item/shape/circle';
import { PLAYER_ATTACK_AREA_DURATION, PLAYER_ATTACK_DISTANCE } from '~/shared/rooms/battle/entity/unit/player/const';
import { UpgradeVariant } from '~/shared/rooms/battle/entity/unit/player/upgrades/types';
import './resources';
export class AttackArea extends Circle {
    constructor(player) {
        super(player.battle.scene, {
            material: MaterialType.AttackArea,
            radius: player.getUpgradableValue(PLAYER_ATTACK_DISTANCE, UpgradeVariant.AttackRadius),
            alpha: 0.2,
            visible: false,
        });
        this.tween = null;
        this.player = player;
        this.player.renderItem.object.add(this.object);
    }
    update() {
        var _a;
        this.setVisible(Boolean(this.tween));
        (_a = this.tween) === null || _a === void 0 ? void 0 : _a.update();
    }
    display() {
        this.setRadius(0);
        const radius = this.player.getUpgradableValue(PLAYER_ATTACK_DISTANCE, UpgradeVariant.AttackRadius);
        const state = { radius: 0 };
        this.tween = new Tween(state, false)
            .to({ radius }, PLAYER_ATTACK_AREA_DURATION)
            .easing(Easing.Quadratic.In)
            .onUpdate(({ radius }) => {
            this.setRadius(radius);
        })
            .onComplete(() => {
            this.tween = null;
        })
            .start();
    }
}
