import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRoom } from '../../../../../../../../core/room/ui/hooks/use-room';
import { Interactive } from '../../../../../../../../core/ui/components/interactive';
import { useTutorialSteps } from '../../../../../../ui/hooks/use-tutorial-steps';
import { TutorialStep } from '~/shared/rooms/battle/entity/unit/player/tutorial/types';
import styles from './styles.module.scss';
import IconAttack from './icons/attack.svg';
export const ButtonAttack = () => {
    const battle = useRoom();
    const steps = useTutorialSteps();
    const handleClick = useCallback(() => {
        battle.getSelfPlayer().attack();
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.blink]: steps.has(TutorialStep.Attack),
        }) },
        React.createElement(IconAttack, null)));
};
