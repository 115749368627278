import { Logger } from '../logger';
export class Messages {
    constructor(origin, buffer) {
        this.channel = null;
        this.origin = origin;
        this.buffer = buffer;
    }
    setChannel(channel) {
        this.channel = channel;
    }
    on(message, callback) {
        if (this.channel) {
            this.buffer.onMessage(this.origin, this.channel, message.toString(), callback);
        }
        else {
            this.origin.onMessage(message.toString(), callback);
        }
    }
    send(message, payload) {
        if (!this.origin.connection.isOpen) {
            if (__MODE === 'development') {
                Logger.warn(`Unable to send message '${message.toString()}' from disconnected room`);
            }
            return;
        }
        this.origin.send(message.toString(), Object.assign(Object.assign({}, payload), { _channel: this.channel }));
    }
    clear() {
        if (!this.channel) {
            Logger.warn('Unable to clear global messages');
            return;
        }
        this.buffer.clear(this.channel);
    }
}
