import { BulletFire } from '../variants/bullet/fire';
import { BulletFrost } from '../variants/bullet/frost';
import { BulletSimple } from '../variants/bullet/simple';
import { Laser } from '../variants/laser';
import { ShotVariant } from '~/shared/rooms/battle/entity/shot/types';
export const SHOT_INSTANCES = {
    // @ts-ignore
    [ShotVariant.BulletSimple]: BulletSimple,
    // @ts-ignore
    [ShotVariant.BulletFire]: BulletFire,
    // @ts-ignore
    [ShotVariant.BulletFrost]: BulletFrost,
    // @ts-ignore
    [ShotVariant.Laser]: Laser,
};
