import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Hint } from './hint';
import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useTutorialSteps } from '~/client/rooms/battle/ui/hooks/use-tutorial-steps';
import { ActiveScreen } from '~/client/rooms/battle/ui/state';
import { DeviceType } from '~/shared/core/device/types';
import { TutorialStep } from '~/shared/rooms/battle/entity/unit/player/tutorial/types';
import styles from './styles.module.scss';
export const TutorialHints = () => {
    const { builder } = useRoom();
    const language = useLanguage();
    const screen = useRecoilValue(ActiveScreen);
    const steps = useTutorialSteps();
    const [isBuild, setBuild] = useState(false);
    useEffect(() => {
        const eventToggleBuild = builder.events.onToggleBuild.on(setBuild);
        return () => {
            eventToggleBuild.off();
        };
    }, []);
    return (!screen && (React.createElement("div", { className: styles.wrapper }, Array.from(steps).map((step) => (() => {
        switch (step) {
            case TutorialStep.Attack:
                return (Device.type === DeviceType.Desktop && (React.createElement(Hint, { key: step },
                    language('Press'),
                    ' ',
                    React.createElement("span", null, "SPACE"),
                    ' ',
                    language('HintAttack'))));
            case TutorialStep.BuildGenerator:
            case TutorialStep.BuildSpawner:
            case TutorialStep.BuildAmmunition:
            case TutorialStep.BuildTower:
            case TutorialStep.BuildRadar:
                return (!isBuild && (React.createElement(Hint, { key: step }, Device.type === DeviceType.Mobile ? (React.createElement(React.Fragment, null,
                    React.createElement("span", null, "SWIPE LEFT"),
                    ' ',
                    language(`Hint${step}`))) : (React.createElement(React.Fragment, null,
                    language('Hold'),
                    ' ',
                    React.createElement("span", null, "SHIFT"),
                    ' ',
                    language(`Hint${step}`))))));
        }
    })()))));
};
