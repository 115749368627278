var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Confirmation } from './confirmation';
import { MENU_TOGGLE_KEY } from './const';
import { MenuPageType } from './types';
import { ActiveScreen } from '../../../../state';
import { ScreenType } from '../../../../types';
import { Button } from '../../../button';
import { Client } from '~/client/core/client';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Section } from '~/client/core/ui/components/section';
import { SettingsPanel } from '~/client/core/ui/components/settings-panel';
import { BattleMode } from '~/shared/rooms/battle/types';
import { RoomType } from '~/shared/rooms/types';
export const MenuScreen = () => {
    const battle = useRoom();
    const language = useLanguage();
    const [screen, setScreen] = useRecoilState(ActiveScreen);
    const [page, setPage] = useState(MenuPageType.Main);
    const visible = screen === ScreenType.Menu;
    const handleClickBack = () => {
        setPage(MenuPageType.Main);
    };
    const handleClickLeave = () => {
        Client.joinRoomByType(RoomType.Relay);
    };
    const handleClickContinue = () => __awaiter(void 0, void 0, void 0, function* () {
        setScreen(null);
    });
    const handleClickRestart = () => __awaiter(void 0, void 0, void 0, function* () {
        Client.setLoading();
        if (battle.state.mode === BattleMode.Single) {
            yield SDK.showAd(SDKAdType.Commercial);
        }
        battle.restart();
        setScreen(null);
    });
    const handleKeyDown = useCallback((event) => {
        if (event.code !== MENU_TOGGLE_KEY) {
            return;
        }
        event.preventDefault();
        if (screen) {
            setScreen(null);
        }
        else {
            setScreen(ScreenType.Menu);
            setPage(MenuPageType.Main);
        }
    }, [screen]);
    useEffect(() => {
        const eventKeyDown = InputKeyboard.events.onKeyDown.on(handleKeyDown);
        return () => {
            eventKeyDown.off();
        };
    }, [handleKeyDown]);
    return (visible &&
        (() => {
            switch (page) {
                case MenuPageType.Main:
                    return (React.createElement(Section, { direction: "vertical", gap: 8 },
                        React.createElement(Button, { onClick: handleClickContinue, size: "large", view: "accent" }, language('Continue')),
                        battle.state.mode === BattleMode.Single && (React.createElement(Button, { onClick: () => setPage(MenuPageType.Restart), size: "large" }, language('Restart'))),
                        React.createElement(Button, { onClick: () => setPage(MenuPageType.Settings), size: "large" }, language('Settings')),
                        React.createElement("br", null),
                        React.createElement(Button, { onClick: () => setPage(MenuPageType.Leave), size: "large", view: "negative" }, language('MainMenu'))));
                case MenuPageType.Settings:
                    return (React.createElement(Section, { direction: "vertical", align: "center", gap: 48 },
                        React.createElement(SettingsPanel, null),
                        React.createElement(Button, { onClick: handleClickBack, size: "large" }, language('Back'))));
                case MenuPageType.Restart:
                    return (React.createElement(Confirmation, { text: language('RestartConfirm'), onConfirm: handleClickRestart, onDecline: handleClickBack }));
                case MenuPageType.Leave:
                    return (React.createElement(Confirmation, { text: language('LeaveConfirm'), onConfirm: handleClickLeave, onDecline: handleClickBack }));
            }
        })());
};
