import { PageType } from '../../../rooms/relay/ui/components/pages/types';
import { SettingsType } from '../../settings/types';
import { LanguageType } from '../types';
import { OverlayType } from '~/client/rooms/relay/ui/components/overlays/types';
import { UserAuthError } from '~/shared/core/user/auth/types';
import { InventoryItemType } from '~/shared/core/user/inventory/item/types';
import { BuildingCategory, BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
import { DroidVariant } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/rooms/battle/entity/unit/player/skill/types';
import { TutorialStep } from '~/shared/rooms/battle/entity/unit/player/tutorial/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/rooms/battle/entity/unit/player/upgrades/types';
import { BattleDifficult } from '~/shared/rooms/battle/types';
const PHRASES = {
    [PageType.Play]: 'Jogar',
    [PageType.Inventory]: 'Inventário',
    [PageType.Leaderboard]: 'Líderes',
    [OverlayType.Settings]: 'Configurações',
    [OverlayType.Login]: 'Entrar',
    [OverlayType.Registration]: 'Registrar-se',
    Profile: 'Perfil',
    LogIn: 'Entrar',
    SignUp: 'Registrar-se',
    Username: 'Nome de utilizador',
    Password: 'Palavra-passe',
    [UserAuthError.AlreadyAuthorized]: 'Já está autorizado',
    [UserAuthError.ExistUsername]: 'O nome de utilizador especificado já está em uso',
    [UserAuthError.InvalidToken]: 'Token de autorização inválido',
    [UserAuthError.InvalidCredentials]: 'Nome de utilizador ou palavra-passe incorretos',
    [UserAuthError.InvalidUsername]: 'Nome de utilizador inválido',
    [UserAuthError.InvalidPassword]: 'Palavra-passe inválida',
    MainMenu: 'Menu principal',
    Back: 'Voltar',
    Continue: 'Continuar',
    Restart: 'Reiniciar',
    Settings: 'Configurações',
    RestartConfirm: 'Confirma que deseja reiniciar esta batalha?',
    LeaveConfirm: 'Confirma que deseja sair desta batalha?',
    PlayAgain: 'Jogar novamente',
    WaitingOpponent: 'À espera do oponente...',
    OpponentDisconnected: 'O oponente abandonou a batalha',
    YouWin: 'Você venceu',
    YouLose: 'Você perdeu',
    YouDied: 'Você morreu',
    [`Difficult${BattleDifficult.Normal}`]: 'Modo Normal',
    [`Difficult${BattleDifficult.Normal}Description`]: 'Para principiantes',
    [`Difficult${BattleDifficult.Hard}`]: 'Modo Difícil',
    [`Difficult${BattleDifficult.Hard}Description`]: 'Para jogadores experientes',
    PlayerOnline: 'Jogador online',
    PlayersOnline: 'Jogadores online',
    YouRanked: 'Está classificado',
    PlaceOf: 'lugar de',
    Earn: 'Ganhe',
    UnlockOnlineModes: 'para desbloquear os modos online',
    SearchingOpponent: 'À procura de\num oponente',
    PlaySingle: 'Jogo individual',
    PlaySingleDescription: 'Jogue no modo de ondas infinitas',
    PlayOnline: 'Jogo online',
    PlayOnlineDescription: 'Jogue com um oponente aleatório',
    PlayOnlinePrivate: 'Jogo privado',
    PlayOnlinePrivateDescription: 'Jogue com um amigo',
    YourId: 'O seu ID',
    FriendId: 'ID do amigo',
    Used: 'Usado',
    Select: 'Selecionar',
    Selected: 'Selecionado',
    Disable: 'Desativar',
    Buy: 'Comprar',
    OrHold: 'Ou segure',
    Yes: 'Sim',
    No: 'Não',
    On: 'Ligado',
    Off: 'Desligado',
    Low: 'Baixo',
    Medium: 'Médio',
    High: 'Alto',
    Press: 'Pressione',
    Hold: 'Segure',
    HintAttack: 'para\natacar',
    [`Hint${TutorialStep.BuildGenerator}`]: 'para\nconstruir gerador',
    [`Hint${TutorialStep.BuildSpawner}`]: 'para\nconstruir invocador',
    [`Hint${TutorialStep.BuildAmmunition}`]: 'para\nconstruir munições',
    [`Hint${TutorialStep.BuildRadar}`]: 'para\nconstruir radar',
    [`Hint${TutorialStep.BuildTower}`]: 'para\nconstruir torre de fogo',
    Experience: 'Experiência',
    Upgrades: 'Melhorias',
    SkillDuration: 'Duração %1 s',
    SkillRecovery: 'Recuperação %1 s',
    PassiveSkill: 'Passivo',
    NewBuildingsAvailable: 'Novos edifícios disponíveis',
    [`Category${InventoryItemType.Droid}`]: 'Droid',
    [`Category${InventoryItemType.Skill}`]: 'Habilidades',
    [`${DroidVariant.Combat}Name`]: 'Combate',
    [`${DroidVariant.Combat}Description`]: 'Ataca mobs inimigos dentro de um certo raio',
    [`${DroidVariant.Medic}Name`]: 'Médico',
    [`${DroidVariant.Medic}Description`]: 'Cura o personagem em intervalos regulares',
    [`${DroidVariant.Mechanic}Name`]: 'Mecânico',
    [`${DroidVariant.Mechanic}Description`]: 'Repara edifícios danificados dentro de um certo raio',
    [`${SkillVariant.Vampire}Name`]: 'Vampirismo',
    [`${SkillVariant.Vampire}Description`]: 'Restaura a saúde em 10% do dano causado',
    [`${SkillVariant.BoostSpeed}Name`]: 'Aumentar velocidade',
    [`${SkillVariant.BoostSpeed}Description`]: 'Aumenta a velocidade em 50%',
    [`${SkillVariant.InspirationDroid}Name`]: 'Droid inspirador',
    [`${SkillVariant.InspirationDroid}Description`]: 'Aumenta as características do droid em 30%',
    [`${SkillVariant.InspirationMobs}Name`]: 'Inspiração de mobs',
    [`${SkillVariant.InspirationMobs}Description`]: 'Aumenta o dano e a velocidade dos mobs em 10%',
    [`${SkillVariant.InspirationTowers}Name`]: 'Inspiração de torres',
    [`${SkillVariant.InspirationTowers}Description`]: 'Aumenta o dano e a velocidade de ataque das torres em 20%',
    [`${SkillVariant.Sacrifice}Name`]: 'Sacrifício',
    [`${SkillVariant.Sacrifice}Description`]: 'Mata os seus mobs e aumenta a sua saúde',
    [`${SkillVariant.DiscountWalls}Name`]: 'Desconto em paredes',
    [`${SkillVariant.DiscountWalls}Description`]: 'Reduz o custo das paredes em 50%',
    [`${SkillVariant.DamageReflection}Name`]: 'Reflexão de dano',
    [`${SkillVariant.DamageReflection}Description`]: 'Reflete 10% do dano recebido',
    [`${SkillVariant.BoostGenerators}Name`]: 'Aumentar geradores',
    [`${SkillVariant.BoostGenerators}Description`]: 'Aumenta a velocidade de geração de recursos em 10%',
    [`${SkillVariant.CallingMobs}Name`]: 'Invocar mobs',
    [`${SkillVariant.CallingMobs}Description`]: 'Gera mobs perto do personagem',
    [`${SkillVariant.AttackRecovery}Name`]: 'Ataques rápidos',
    [`${SkillVariant.AttackRecovery}Description`]: 'Aumenta a velocidade de recuperação dos ataques em 15%',
    [`${SkillVariant.HighDamage}Name`]: 'Dano elevado',
    [`${SkillVariant.HighDamage}Description`]: 'Aumenta o dano do personagem em 15%',
    [BuildingCategory.Defense]: 'Defesa',
    [BuildingCategory.Spawners]: 'Invocadores',
    [BuildingCategory.Resources]: 'Recursos',
    [BuildingCategory.Towers]: 'Torres',
    [`${BuildingVariant.Wall}Name`]: 'Parede',
    [`${BuildingVariant.Wall}Description`]: '',
    [`${BuildingVariant.Ammunition}Name`]: 'Munições',
    [`${BuildingVariant.Ammunition}Description`]: '',
    [`${BuildingVariant.Radar}Name`]: 'Radar',
    [`${BuildingVariant.Radar}Description`]: '',
    [`${BuildingVariant.Trap}Name`]: 'Armadilha',
    [`${BuildingVariant.Trap}Description`]: '',
    [`${BuildingVariant.Generator}Name`]: 'Gerador',
    [`${BuildingVariant.Generator}Description`]: '',
    [`${BuildingVariant.SpawnerSmall}Name`]: 'Invocador',
    [`${BuildingVariant.SpawnerSmall}Description`]: '',
    [`${BuildingVariant.SpawnerMedium}Name`]: 'Invocador Extra',
    [`${BuildingVariant.SpawnerMedium}Description`]: '',
    [`${BuildingVariant.SpawnerLarge}Name`]: 'Invocador Ultra',
    [`${BuildingVariant.SpawnerLarge}Description`]: '',
    [`${BuildingVariant.TowerFire}Name`]: 'Torre de Fogo',
    [`${BuildingVariant.TowerFire}Description`]: '',
    [`${BuildingVariant.TowerFrost}Name`]: 'Torre de Gelo',
    [`${BuildingVariant.TowerFrost}Description`]: '',
    [`${BuildingVariant.TowerLaser}Name`]: 'Torre de Laser',
    [`${BuildingVariant.TowerLaser}Description`]: '',
    [UpgradeCategory.Main]: 'Principal',
    [UpgradeCategory.Attack]: 'Ataque',
    [UpgradeCategory.Build]: 'Construção',
    [UpgradeVariant.MainMaxHealth]: 'Vida Máxima',
    [UpgradeVariant.MainSpeed]: 'Velocidade de Movimento',
    [UpgradeVariant.MainDroid]: 'Eficiência do Droid',
    [UpgradeVariant.AttackRecovery]: 'Recuperação de Ataque',
    [UpgradeVariant.AttackRadius]: 'Raio de Ataque',
    [UpgradeVariant.AttackDamage]: 'Dano',
    [UpgradeVariant.BuildSpeed]: 'Velocidade de Construção',
    [UpgradeVariant.BuildRadius]: 'Raio de Construção',
    [SettingsType.FpsLimit]: 'Limite de FPS',
    [SettingsType.Resolution]: 'Qualidade gráfica',
    [SettingsType.VisualEffects]: 'Efeitos visuais',
    [SettingsType.AudioEffects]: 'Efeitos de áudio',
    [SettingsType.Tutorial]: 'Tutorial',
    [SettingsType.Fullscreen]: 'Ecrã completo',
    [SettingsType.Language]: 'Idioma',
    [LanguageType.EN]: 'English',
    [LanguageType.RU]: 'Русский',
    [LanguageType.PT]: 'Português',
    [LanguageType.FR]: 'Français',
};
export default PHRASES;
