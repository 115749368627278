import { MeshBasicMaterial } from 'three';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import textureIndicatorOpponent from './indicator-opponent.png';
import textureIndicatorSelf from './indicator-self.png';
Assets.addMaterial(MaterialType.IndicatorSelf, new MeshBasicMaterial({
    map: Assets.createTexture(textureIndicatorSelf),
    transparent: true,
    opacity: 0.35,
}));
Assets.addMaterial(MaterialType.IndicatorOpponent, new MeshBasicMaterial({
    map: Assets.createTexture(textureIndicatorOpponent),
    transparent: true,
    opacity: 0.35,
}));
