import { Assets } from '../../../../../../../../../core/assets';
import { ModelType } from '../../../../../../../../../core/assets/types';
import bin from './siren.bin';
import gltf from './siren.gltf';
Assets.addModel(ModelType.MobSiren, {
    gltf,
    bin: {
        origin: 'siren.bin',
        path: bin,
    },
});
