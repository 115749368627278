import { DroidCombat } from '../variants/combat';
import { DroidMechanic } from '../variants/mechanic';
import { DroidMedic } from '../variants/medic';
import { DroidVariant } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
export const DROID_INSTANCES = {
    // @ts-ignore
    [DroidVariant.Combat]: DroidCombat,
    // @ts-ignore
    [DroidVariant.Mechanic]: DroidMechanic,
    // @ts-ignore
    [DroidVariant.Medic]: DroidMedic,
};
