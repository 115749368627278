import cn from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
export const ErrorPlate = ({ error }) => {
    if (!error.message) {
        return null;
    }
    return (React.createElement("div", { className: cn(styles.error, {
            [styles.animate]: error.active,
        }) }, error.message));
};
