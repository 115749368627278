export class Storage {
    static get(key) {
        var _a;
        try {
            return localStorage.getItem(key);
        }
        catch (_b) {
            return (_a = this.values.get(key)) !== null && _a !== void 0 ? _a : null;
        }
    }
    static set(key, value) {
        this.values.set(key, value);
        try {
            localStorage.setItem(key, value);
        }
        catch (_a) {
            //
        }
    }
    static remove(key) {
        this.values.delete(key);
        try {
            localStorage.removeItem(key);
        }
        catch (_a) {
            //
        }
    }
}
Storage.values = new Map();
