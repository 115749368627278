import { Audio as OriginAudio } from 'three';
import { AudioTrack } from '..';
import { AUDIO_VOLUME } from '../../const';
export class AudioTrack2D extends AudioTrack {
    constructor(type, listener, { buffer, volume = 1.0, loop = false, }) {
        super(type, listener);
        this.paused = false;
        this.audio = new OriginAudio(listener);
        this.audio.setBuffer(buffer);
        this.audio.loop = loop !== null && loop !== void 0 ? loop : false;
        this.volume = AUDIO_VOLUME * volume;
        this.audio.setVolume(this.volume);
    }
    destroy() {
        this.audio.stop();
        this.audio.removeFromParent();
    }
    play() {
        if (this.audio.isPlaying) {
            this.audio.stop();
        }
        this.audio.play();
    }
    stop() {
        if (!this.audio.isPlaying
            && !this.paused) {
            return;
        }
        this.audio.stop();
        this.paused = false;
    }
    disable() {
        this.audio.setVolume(0);
    }
    enable() {
        this.audio.setVolume(this.volume);
    }
    setPaused(paused) {
        if (paused) {
            if (!this.audio.isPlaying) {
                return;
            }
            this.audio.pause();
            this.paused = true;
        }
        else if (this.paused) {
            this.audio.play();
            this.paused = false;
        }
    }
}
