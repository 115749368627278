import { SkillType, SkillVariant } from './types';
export const SKILL_MAX_SLOTS = 3;
export const SKILLS_SHARED_CONFIG = {
    [SkillVariant.BoostSpeed]: {
        type: SkillType.Active,
        recovery: 10000,
        duration: 3000,
        default: true,
        cost: 0,
    },
    [SkillVariant.InspirationDroid]: {
        type: SkillType.Active,
        recovery: 10000,
        duration: 5000,
        default: true,
        cost: 0,
    },
    [SkillVariant.DiscountWalls]: {
        type: SkillType.Passive,
        default: true,
        cost: 0,
    },
    [SkillVariant.DamageReflection]: {
        type: SkillType.Passive,
        cost: 50,
    },
    [SkillVariant.Vampire]: {
        type: SkillType.Passive,
        cost: 100,
    },
    [SkillVariant.CallingMobs]: {
        type: SkillType.Active,
        recovery: 20000,
        cost: 200,
    },
    [SkillVariant.AttackRecovery]: {
        type: SkillType.Passive,
        cost: 200,
    },
    [SkillVariant.InspirationTowers]: {
        type: SkillType.Active,
        recovery: 30000,
        duration: 5000,
        cost: 250,
    },
    [SkillVariant.BoostGenerators]: {
        type: SkillType.Passive,
        cost: 250,
    },
    [SkillVariant.HighDamage]: {
        type: SkillType.Passive,
        cost: 300,
    },
    [SkillVariant.InspirationMobs]: {
        type: SkillType.Active,
        recovery: 40000,
        duration: 5000,
        cost: 500,
    },
    [SkillVariant.Sacrifice]: {
        type: SkillType.Active,
        recovery: 20000,
        cost: 500,
    },
};
