import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useMemo } from 'react';
import { PARAMETER_ICON_MAP } from './const';
import styles from './styles.module.scss';
export const Parameter = ({ schema, prop, transform, alert }) => {
    const rawValue = useSchemaValue(schema, prop);
    const value = useMemo(() => (transform ? transform(rawValue) : rawValue), [rawValue]);
    const Icon = PARAMETER_ICON_MAP[prop];
    return (React.createElement("div", { className: cn(styles.container, {
            [styles.alert]: alert === null || alert === void 0 ? void 0 : alert(rawValue),
        }) },
        React.createElement("div", { className: styles.icon }, Icon && React.createElement(Icon, null)),
        React.createElement("div", { className: styles.data },
            React.createElement("div", { className: styles.label }, prop),
            React.createElement("div", { className: styles.value }, value))));
};
