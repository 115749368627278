import { SETTINGS_KEY_PREFIX } from './const';
import { SettingsSwitch } from './types';
import { Storage } from '../storage';
import { EventStream } from '~/shared/core/event-stream';
export class Settings {
    static getEnum(type, as, defaultValue) {
        const value = Storage.get(`${SETTINGS_KEY_PREFIX}${type}`);
        if (!value) {
            return defaultValue;
        }
        return Object.values(as).includes(value)
            ? value
            : defaultValue;
    }
    static setEnum(type, value) {
        Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, value);
        this.events.onUpdate.invoke(type);
    }
    static getBoolean(type, defaultValue) {
        const value = Storage.get(`${SETTINGS_KEY_PREFIX}${type}`);
        if (!value) {
            return defaultValue;
        }
        return value === SettingsSwitch.On;
    }
    static setBoolean(type, value) {
        const state = value ? SettingsSwitch.On : SettingsSwitch.Off;
        Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, state);
        this.events.onUpdate.invoke(type);
    }
    static getInteger(type, defaultValue) {
        const value = Storage.get(`${SETTINGS_KEY_PREFIX}${type}`);
        if (!value) {
            return defaultValue;
        }
        return Number(value);
    }
    static setInteger(type, value) {
        Storage.set(`${SETTINGS_KEY_PREFIX}${type}`, value.toString());
        this.events.onUpdate.invoke(type);
    }
}
Settings.events = {
    onUpdate: new EventStream(),
};
