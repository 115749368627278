import ammo from './icons/ammo.svg';
import damage from './icons/damage.svg';
import delay from './icons/delay.svg';
import freeze from './icons/freeze.svg';
import health from './icons/health.svg';
import radius from './icons/radius.svg';
import reflection from './icons/reflection.svg';
import spawned from './icons/spawned.svg';
import speed from './icons/speed.svg';
export const PARAMETER_ICON_MAP = {
    freeze, delay, damage, radius, ammo, health, speed, spawned, reflection,
};
