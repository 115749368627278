import { PositionalAudio } from 'three';
import { AUDIO_MAX_DISTANCE } from './const';
import { AudioTrack } from '..';
import { Logger } from '../../../logger';
import { AUDIO_VOLUME } from '../../const';
import { Utils } from '~/shared/core/utils';
export class AudioTrack3D extends AudioTrack {
    constructor(type, listener, { buffers, poolSize = 1, volume = 1.0, single = false, loop = false, }) {
        super(type, listener);
        this.playings = new Map();
        this.pool = new Set();
        this.buffers = [];
        this.single = false;
        this.buffers = buffers;
        this.single = single;
        this.volume = AUDIO_VOLUME * volume;
        for (let i = 0; i < poolSize; i++) {
            const audio = new PositionalAudio(listener);
            audio.setVolume(this.volume);
            audio.loop = loop !== null && loop !== void 0 ? loop : false;
            this.pool.add(audio);
        }
    }
    destroy() {
        this.playings.forEach((audio) => {
            audio.stop();
        });
        this.pool.forEach((audio) => {
            audio.removeFromParent();
        });
    }
    play({ parent, position }) {
        if (!this.isNormalDistance(position !== null && position !== void 0 ? position : parent.position)) {
            return;
        }
        const buffer = Utils.randomItem(this.buffers);
        if (!buffer) {
            Logger.warn('Unable to get buffer of 3D audio track');
            return;
        }
        const hash = this.getHash(parent);
        if (this.single && this.playings.has(hash)) {
            return;
        }
        const audio = this.topFromPool();
        if (!audio) {
            return;
        }
        audio.setBuffer(buffer);
        if (position) {
            audio.position.copy(position);
        }
        this.playings.set(hash, audio);
        parent.add(audio);
        audio.onEnded = () => {
            this.playings.delete(hash);
            audio.removeFromParent();
            this.returnToPool(audio);
            audio.isPlaying = false;
            // @ts-ignore
            delete audio.onEnded; // ?
        };
        audio.isPlaying = false;
        audio.play();
    }
    stop() {
        this.playings.forEach((audio) => {
            audio.onEnded();
            audio.stop();
        });
    }
    disable() {
        this.pool.forEach((audio) => {
            audio.setVolume(0);
        });
    }
    enable() {
        this.pool.forEach((audio) => {
            audio.setVolume(this.volume);
        });
    }
    setPaused(paused) {
        this.playings.forEach((audio) => {
            if (paused) {
                audio.pause();
            }
            else {
                audio.play();
            }
        });
    }
    isNormalDistance(position) {
        if (!this.listener.parent) {
            return;
        }
        const distance = this.listener.parent.position.distanceTo(position);
        return distance <= AUDIO_MAX_DISTANCE;
    }
    topFromPool() {
        var _a;
        const audio = (_a = Array.from(this.pool)[0]) !== null && _a !== void 0 ? _a : null;
        if (audio) {
            this.pool.delete(audio);
        }
        return audio;
    }
    returnToPool(audio) {
        this.pool.add(audio);
    }
    getHash(parent) {
        return `${parent.uuid}@${this.type}`;
    }
}
