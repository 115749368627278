import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './medic.bin';
import gltf from './medic.gltf';
Assets.addModel(ModelType.DroidMedic, {
    gltf,
    bin: {
        origin: 'medic.bin',
        path: bin,
    },
});
