import { Vector2, Vector3 } from 'three';
export class VectorUtils {
    static reuse(vector) {
        if ('z' in vector) {
            return this.reusableVector3.copy(vector);
        }
        else {
            return this.reusableVector2.copy(vector);
        }
    }
    static encode3d(vector) {
        return [vector.x, vector.y, vector.z].join(',');
    }
    static encode2d(vector) {
        return [vector.x, vector.z].join(',');
    }
    static decode3d(vector) {
        const [x, y, z] = vector.split(',');
        return {
            x: Number(x),
            y: Number(y),
            z: Number(z),
        };
    }
    static decode2d(vector) {
        const [x, z] = vector.split(',');
        return {
            x: Number(x),
            y: 0,
            z: Number(z),
        };
    }
}
VectorUtils.zeroVector3 = new Vector3();
VectorUtils.zeroVector2 = new Vector2();
VectorUtils.reusableVector3 = new Vector3();
VectorUtils.reusableVector2 = new Vector2();
