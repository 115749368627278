import { EventStream } from '~/shared/core/event-stream';
export class InputKeyboard {
    static addListeners() {
        this.listenContextMenu();
        this.listenKeyUp();
        this.listenKeyDown();
    }
    static listenContextMenu() {
        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });
    }
    static listenKeyUp() {
        document.addEventListener('keyup', (event) => {
            this.events.onKeyUp.invoke(event);
        });
    }
    static listenKeyDown() {
        document.addEventListener('keydown', (event) => {
            if (event.repeat) {
                return;
            }
            this.events.onKeyDown.invoke(event);
        });
    }
}
InputKeyboard.events = {
    onKeyUp: new EventStream(),
    onKeyDown: new EventStream(),
};
