import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SKILL_ICON } from '../../skill-icon/const';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Device } from '~/client/core/device';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Interactive } from '~/client/core/ui/components/interactive';
import { DeviceType } from '~/shared/core/device/types';
import { SkillType } from '~/shared/rooms/battle/entity/unit/player/skill/types';
import styles from './styles.module.scss';
export const Slot = ({ variant, index, skill }) => {
    const battle = useRoom();
    const audio = useAudio();
    const language = useLanguage();
    const [progress, setProgress] = useState(false);
    const refTimeout = useRef(null);
    const empty = !variant || !skill;
    const usable = !empty && !progress && skill.type === SkillType.Active;
    const IconComponent = variant && SKILL_ICON[variant];
    const handleClick = useCallback(() => {
        var _a;
        if (empty) {
            return;
        }
        audio.play(AudioType.UseSkill);
        battle.getSelfPlayer().useSkill(variant);
        setProgress(true);
        refTimeout.current = setTimeout(() => {
            setProgress(false);
            refTimeout.current = null;
        }, (_a = skill === null || skill === void 0 ? void 0 : skill.recovery) !== null && _a !== void 0 ? _a : 0);
    }, []);
    const handleKeyDown = (event) => {
        if (battle.state.paused) {
            return;
        }
        const key = Number(event.key);
        if (key === index + 1) {
            handleClick();
        }
    };
    useEffect(() => {
        if (Device.type === DeviceType.Mobile || !usable) {
            return;
        }
        const eventKeyDown = InputKeyboard.events.onKeyDown.on(handleKeyDown);
        return () => {
            eventKeyDown.off();
        };
    }, []);
    useEffect(() => {
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
            }
        };
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, disabled: !usable, className: cn(styles.container, empty ? styles.empty : styles[skill.type.toLowerCase()], {
            [styles.usable]: usable,
        }) }, empty ? (React.createElement("div", { className: styles.empty })) : (React.createElement(React.Fragment, null,
        Device.type === DeviceType.Desktop && (React.createElement("div", { className: styles.info },
            React.createElement("div", { className: styles.head }, language(`${variant}Name`)),
            React.createElement("div", { className: styles.description }, language(`${variant}Description`)),
            React.createElement("div", { className: styles.addon }, skill.type === SkillType.Active
                ? language('SkillDuration', [skill.duration / 1000])
                : language('PassiveSkill')))),
        progress && (React.createElement("div", { className: styles.timeout, style: { animationDuration: `${skill.recovery}ms` } })),
        IconComponent && (React.createElement("div", { className: styles.icon },
            React.createElement(IconComponent, null))),
        Device.type === DeviceType.Desktop && (React.createElement("div", { className: styles.hotkey }, usable ? index + 1 : '·'))))));
};
