import React from 'react';
import { Section } from '../../../../../core/ui/components/section';
import styles from './styles.module.scss';
export const CardsList = ({ icon, label, children }) => {
    const IconComponent = icon;
    return (React.createElement(Section, { direction: "vertical", align: "center", gap: 32 },
        React.createElement(Section, { direction: "horizontal", align: "center", gap: 16 },
            React.createElement(IconComponent, null),
            React.createElement("div", { className: styles.label }, label)),
        React.createElement(Section, { direction: "vertical", gap: 6 }, children)));
};
