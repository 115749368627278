import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioBreak from './break.mp3';
import audioHit1 from './hit1.mp3';
import audioHit2 from './hit2.mp3';
import audioHit3 from './hit3.mp3';
import audioHit4 from './hit4.mp3';
import audioHit5 from './hit5.mp3';
import audioRepair from './repair.mp3';
import audioUpgrade from './upgrade.mp3';
Assets.addAudio(AudioType.BuildingHit, {
    path: [audioHit1, audioHit2, audioHit3, audioHit4, audioHit5],
    mode: AudioMode.Track3D,
    volume: 0.5,
    poolSize: 4,
    single: true,
});
Assets.addAudio(AudioType.BuildingBreak, {
    path: audioBreak,
    mode: AudioMode.Track3D,
    volume: 0.3,
    poolSize: 3,
    single: true,
});
Assets.addAudio(AudioType.BuildingUpgrade, {
    path: audioUpgrade,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
Assets.addAudio(AudioType.BuildingRepair, {
    path: audioRepair,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
