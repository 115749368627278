import { PlaneGeometry, Mesh } from 'three';
import { FOG_PLANE_SIZE } from './const';
import { SceneLayer } from '../../scene/types';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { TERRAIN_SIZE } from '~/shared/rooms/battle/terrain/const';
import './resources';
const _PlaneGeometry = new PlaneGeometry(FOG_PLANE_SIZE.x, FOG_PLANE_SIZE.y);
export class Fog {
    constructor(battle) {
        this.lights = new Set();
        this.battle = battle;
        const material = Assets.getMaterial(MaterialType.Fog);
        material.onBeforeRender = () => {
            this.update(material);
        };
        this.fog = new Mesh(_PlaneGeometry, material);
        this.fog.position.set(TERRAIN_SIZE.x / 2, 1.2, TERRAIN_SIZE.z / 2);
        this.fog.rotateX(-0.5 * Math.PI);
        this.fog.layers.set(SceneLayer.Fog);
        this.battle.scene.add(this.fog);
    }
    destroy() {
        this.battle.scene.remove(this.fog);
    }
    update(material) {
        Array.from(this.lights).forEach((light, i) => {
            material.uniforms.maskRadius.value[i] = light.radius;
            material.uniforms.maskPosition.value[i] = light.getPosition();
        });
        material.uniforms.lightsCount.value = this.lights.size;
    }
    addLight(light) {
        this.lights.add(light);
    }
    removeLight(light) {
        this.lights.delete(light);
    }
}
