import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { Slot } from './slot';
import { useSelfPlayerSchema } from '../../../../../../ui/hooks/use-self-player-schema';
import { SKILL_MAX_SLOTS } from '~/shared/rooms/battle/entity/unit/player/skill/const';
import styles from './styles.module.scss';
export const SkillPanel = () => {
    const player = useSelfPlayerSchema();
    const skills = useSchemaValue(player, 'skills');
    const emptySlots = SKILL_MAX_SLOTS - skills.size;
    const list = Array.from(skills).sort((a, b) => (a[1].type.localeCompare(b[1].type)));
    return (React.createElement("div", { className: styles.wrapper },
        list.map(([variant, skill], index) => (React.createElement(Slot, { key: variant, variant: variant, skill: skill, index: index }))),
        Array(emptySlots).fill(null).map((_, index) => (React.createElement(Slot, { key: index, index: index })))));
};
