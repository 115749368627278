import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioAttack from './attack.mp3';
import audioLevelUp from './level-up.mp3';
import audioStep from './step.mp3';
import audioUpgrade from './upgrade.mp3';
import audioUseSkill from './use-skill.mp3';
Assets.addAudio(AudioType.Attack, {
    path: audioAttack,
    mode: AudioMode.Track3D,
    volume: 1.0,
    poolSize: 2,
    single: true,
});
Assets.addAudio(AudioType.Step, {
    path: audioStep,
    mode: AudioMode.Track3D,
    volume: 0.5,
    poolSize: 2,
});
Assets.addAudio(AudioType.LevelUp, {
    path: audioLevelUp,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
Assets.addAudio(AudioType.UseSkill, {
    path: audioUseSkill,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
Assets.addAudio(AudioType.Upgrade, {
    path: audioUpgrade,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
