import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioHitBite1 from './bite1.mp3';
import audioHitBite2 from './bite2.mp3';
import audioHitFire1 from './fire1.mp3';
import audioHitFire2 from './fire2.mp3';
import audioHitPunch1 from './punch1.mp3';
import audioHitPunch2 from './punch2.mp3';
Assets.addAudio(AudioType.UnitHitPunch, {
    path: [audioHitPunch1, audioHitPunch2],
    mode: AudioMode.Track3D,
    volume: 0.5,
    poolSize: 6,
    single: true,
});
Assets.addAudio(AudioType.UnitHitFire, {
    path: [audioHitFire1, audioHitFire2],
    mode: AudioMode.Track3D,
    volume: 0.25,
    poolSize: 4,
    single: true,
});
Assets.addAudio(AudioType.UnitHitBite, {
    path: [audioHitBite1, audioHitBite2],
    mode: AudioMode.Track3D,
    volume: 0.5,
    poolSize: 4,
    single: true,
});
