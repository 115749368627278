import { Assets } from '~/client/core/assets';
import { ModelType } from '~/client/core/assets/types';
import bin from './player.bin';
import gltf from './player.gltf';
Assets.addModel(ModelType.Player, {
    gltf,
    bin: {
        origin: 'player.bin',
        path: bin,
    },
});
