import { Vector3, Box3, Quaternion, Euler, Mesh, AnimationMixer, Vector2 } from 'three';
import { RENDER_CAMERA_Y_ANGLE_MULTIPLIER, RENDER_ITEM_OBJECT_PREFIX } from './const';
import { Assets } from '../assets';
import { Device } from '../device';
import { VectorUtils } from '~/shared/core/vector-utils';
export class RenderItem {
    get id() { return this.object.uuid; }
    get position() { return this.object.position; }
    get visible() { return this.object.visible; }
    constructor(scene, { position, rotation, scale, object, visible = true, }) {
        this.size = new Vector3();
        this.scene = scene;
        this.object = object;
        this.object.name = `${RENDER_ITEM_OBJECT_PREFIX}${this.constructor.name}`;
        this.object.visible = visible;
        if (position) {
            this.setPosition(position);
        }
        if (rotation) {
            this.setRotation(rotation);
        }
        if (scale) {
            this.setScale(scale);
        }
        this.updateSize();
        this.scene.add(this.object);
    }
    destroy() {
        this.object.removeFromParent();
    }
    update() { }
    setVisible(visible) {
        this.object.visible = visible;
    }
    setPosition(vector) {
        this.object.position.copy(vector);
    }
    setRotation(vector) {
        this.object.rotation.setFromVector3(VectorUtils.reuse(vector));
    }
    rotate(angle, lerp = 1.0) {
        this.object.quaternion.slerp(new Quaternion().setFromEuler(new Euler(0, angle, 0)), lerp);
    }
    setScale(vector) {
        this.object.scale.copy(vector);
        this.updateSize();
    }
    updateSize() {
        const boundingBox = new Box3().setFromObject(this.object);
        boundingBox.getSize(this.size);
    }
    getPositionOnScreen(offset = 0.0) {
        const screenSize = Device.getScreenSize();
        const halfSize = new Vector2().copy(screenSize).divideScalar(2);
        const position = this.object.position.clone()
            .add({ x: 0, y: offset, z: 0 });
        position.project(this.scene.camera);
        position.x = (position.x * halfSize.x) + halfSize.x;
        position.y = -(position.y * halfSize.y) + halfSize.y;
        return position;
    }
    getSizeOnScreen() {
        const distance = this.scene.camera.position.distanceTo(this.object.position);
        const fov = (this.scene.camera.fov * Math.PI / 180) * RENDER_CAMERA_Y_ANGLE_MULTIPLIER;
        const screenHeight = 2 * Math.tan(fov / 2) * distance;
        const screenWidth = screenHeight * this.scene.camera.aspect;
        const screenSize = Device.getScreenSize();
        return {
            x: (this.size.x / screenWidth) * screenSize.x,
            y: (this.size.y / screenHeight) * screenSize.y,
        };
    }
    getMaterial() {
        const meshes = [];
        this.eachMeshes((mesh) => {
            meshes.push(mesh);
        });
        return meshes[0].material;
    }
    setMaterial(type, meshName) {
        const material = Assets.getMaterial(type);
        this.eachMeshes((mesh) => {
            if (mesh.name.indexOf(RENDER_ITEM_OBJECT_PREFIX) !== 0 &&
                (!meshName || mesh.name === meshName)) {
                mesh.material = material;
            }
        });
    }
    eachMeshes(callback) {
        this.object.traverse((mesh) => {
            if (mesh instanceof Mesh) {
                callback(mesh);
            }
        });
    }
    disableAutoUpdate() {
        this.object.matrixAutoUpdate = false;
    }
    clone() {
        return this.object.clone();
    }
    createAnimationMixer() {
        return new AnimationMixer(this.object);
    }
}
