var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ErrorCode } from 'colyseus.js';
import { ROOM_RECONNECTION_INTERVAL } from './const';
import { Logger } from '../logger';
import { Request } from '../request';
import { Interface } from '../ui';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';
import { Client } from '~/client/core/client';
import { ROOM_PING_ROUTE_PATH } from '~/shared/core/room/const';
import { SERVER_SHUTTING_DOWN_CODE } from '~/shared/core/server/const';
import { Utils } from '~/shared/core/utils';
export class Room {
    get state() { return this.origin.state; }
    get id() { return this.origin.roomId; }
    get sessionId() { return this.origin.sessionId; }
    constructor(room) {
        this.requests = new Map();
        this.shuttingDown = false;
        this.origin = room;
        this.origin.onMessage('*', (type, { requestId, error, response } = {}) => {
            const promise = requestId && this.requests.get(requestId);
            if (!promise) {
                return;
            }
            this.requests.delete(requestId);
            if (error) {
                promise.reject(new Error(error));
            }
            else {
                promise.resolve(response);
            }
        });
        this.origin.onError((code, message) => {
            if (code === SERVER_SHUTTING_DOWN_CODE) {
                this.shuttingDown = true;
            }
            else if (code === ErrorCode.APPLICATION_ERROR) {
                Logger.renderError(message);
            }
        });
        this.origin.onLeave((code) => __awaiter(this, void 0, void 0, function* () {
            this.destroy();
            Logger.debug(`Leave the room with code ${code}`);
            const consented = code === 4000;
            const connected = yield this.checkConnection(!consented);
            if (!connected) {
                Client.setLastRoomId(this.origin.roomId);
                this.tryReconnect();
            }
        }));
    }
    destroy() {
        Interface.unmount();
    }
    checkConnection() {
        return __awaiter(this, arguments, void 0, function* (join = true) {
            try {
                yield Request.get(ROOM_PING_ROUTE_PATH);
                if (join || this.shuttingDown) {
                    Client.connect();
                    yield Client.findAndJoinRoom();
                }
                return true;
            }
            catch (_a) {
                return false;
            }
        });
    }
    tryReconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            const error = this.shuttingDown
                ? SystemMessageUtils.render('restarting', {
                    type: SystemMessageType.Info,
                    sign: '🔄',
                    title: 'Restarting',
                    message: [
                        'Game server is restarting',
                        'Please, wait...',
                    ],
                })
                : SystemMessageUtils.render('connection-lost', {
                    type: SystemMessageType.Error,
                    sign: '📡',
                    title: 'Connection Lost',
                    message: 'Trying to reconnect...',
                });
            const interval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                const connected = yield this.checkConnection();
                if (connected) {
                    clearInterval(interval);
                    error.remove();
                }
            }), ROOM_RECONNECTION_INTERVAL);
        });
    }
    sendRequest(type, payload) {
        if (!this.origin.connection.isOpen) {
            if (__MODE === 'development') {
                Logger.warn(`Unable to send request '${type}' from disconnected room`);
            }
            // @ts-ignore
            return Promise.resolve();
        }
        const requestId = Utils.uuid();
        this.origin.send(type, { payload, requestId });
        return new Promise((resolve, reject) => {
            this.requests.set(requestId, { resolve, reject });
        });
    }
    onStateChange(callback) {
        const listener = this.origin.onStateChange(callback);
        return {
            unsubscribe: () => {
                listener.clear();
            },
        };
    }
}
Room.default = false;
Room.allowReconnection = false;
