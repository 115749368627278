import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Vector2 } from 'three';
import { Device } from '~/client/core/device';
import { InputTouch } from '~/client/core/input/touch';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Joystick = () => {
    const battle = useRoom();
    const [active, setActive] = useState(false);
    const refDeadzone = useRef(null);
    const refBase = useRef(null);
    const refStick = useRef(null);
    const setStickPosition = (position) => {
        const stick = refStick.current;
        if (!stick) {
            return;
        }
        stick.style.left = `${position.x}px`;
        stick.style.top = `${position.y}px`;
        const vector = position
            .normalize()
            .rotateAround({ x: 0, y: 0 }, -Math.PI / 4);
        battle.getSelfPlayer().setMovingVector({
            x: vector.x,
            y: 0.0,
            z: vector.y,
        });
    };
    const calculateStickPosition = (touch) => {
        const base = refBase.current;
        if (!base) {
            return;
        }
        const position = touch.position.clone();
        const bound = base.getBoundingClientRect();
        position.sub(Device.getPositionOnScreen({
            x: bound.left + bound.width / 2,
            y: bound.top + bound.height / 2,
        }));
        const distance = position.distanceTo({ x: 0, y: 0 });
        const maxDistance = 100;
        if (distance > maxDistance) {
            position.multiplyScalar(maxDistance / distance);
        }
        setStickPosition(position);
    };
    const handleTouch = (touch) => {
        if (touch.target === refDeadzone.current ||
            touch.target === refBase.current) {
            touch.takeUp();
        }
        if (touch.target === refBase.current) {
            setActive(true);
            calculateStickPosition(touch);
            touch.events.onMove.on(() => {
                calculateStickPosition(touch);
            });
            touch.events.onRelease.on(() => {
                setActive(false);
                setStickPosition(new Vector2());
            });
        }
    };
    useEffect(() => {
        if (active) {
            return;
        }
        const eventTouch = InputTouch.events.onTouch.on(handleTouch);
        return () => {
            eventTouch.off();
        };
    }, [active]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { ref: refDeadzone, className: styles.deadzone }),
        React.createElement("div", { ref: refBase, className: styles.base },
            React.createElement("div", { ref: refStick, className: cn(styles.stick, {
                    [styles.active]: active,
                }) }))));
};
