import React, { useEffect, useState } from 'react';
import { useBuilding } from '../../hooks/use-building';
import styles from './styles.module.scss';
import IconAlert from './icons/alert.svg';
export const AlertLabel = () => {
    const building = useBuilding();
    const [label, setLabel] = useState(building.alert);
    useEffect(() => {
        const eventChangeAlert = building.events.onChangeAlert.on(setLabel);
        return () => {
            eventChangeAlert.off();
        };
    }, []);
    return label && (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.icon },
            React.createElement(IconAlert, null)),
        React.createElement("div", { className: styles.label }, label)));
};
