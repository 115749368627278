import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { NEW_FEATURES_NOTIFICATION_HIDE_TIMEOUT } from './const';
import { useSelfPlayerSchema } from '../../../../hooks/use-self-player-schema';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
import IconBuilder from './icons/builder.svg';
export const NewBuildingsAvailable = () => {
    const battle = useRoom();
    const player = useSelfPlayerSchema();
    const language = useLanguage();
    const level = useSchemaValue(player, 'level');
    const [visible, setVisible] = useState(false);
    const [hidding, setHidding] = useState(false);
    const refTimeout = useRef(null);
    const refAvailables = useRef([]);
    const refPrevLevel = useRef(level);
    const showNotification = () => {
        setVisible(true);
        refTimeout.current = setTimeout(() => {
            setHidding(true);
            refTimeout.current = setTimeout(() => {
                refTimeout.current = null;
                setHidding(false);
                setVisible(false);
            }, 500);
        }, NEW_FEATURES_NOTIFICATION_HIDE_TIMEOUT);
    };
    useEffect(() => {
        const availables = battle.builder.getAvailableBuildings();
        if (level > refPrevLevel.current &&
            availables.some((variant) => !refAvailables.current.includes(variant))) {
            showNotification();
        }
        refPrevLevel.current = level;
        refAvailables.current = availables;
    }, [level]);
    useEffect(() => {
        return () => {
            if (refTimeout.current) {
                clearTimeout(refTimeout.current);
                refTimeout.current = null;
            }
        };
    }, []);
    return visible ? (React.createElement("div", { className: cn(styles.container, {
            [styles.hidding]: hidding,
        }) },
        React.createElement(IconBuilder, null),
        React.createElement("div", { className: styles.text }, language('NewBuildingsAvailable')))) : null;
};
