export class Delay {
    constructor(duration = 0) {
        this.timestamp = 0;
        this.scale = 1.0;
        this.duration = duration;
    }
    next(duration = null) {
        if (duration !== null) {
            this.duration = duration;
        }
        this.timestamp = performance.now() + (this.duration * this.scale);
    }
    isPast() {
        return this.getLeft() <= 0;
    }
    getLeft() {
        return Math.max(0, this.timestamp - performance.now());
    }
    reset() {
        this.timestamp = 0;
    }
}
