import { Bullet } from '..';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import './resources';
export class BulletFrost extends Bullet {
    constructor(battle, schema) {
        super(battle, {
            material: MaterialType.BulletFrost,
            audioEffect: AudioType.ShotFrost,
        }, schema);
    }
}
