import { Vector3 } from 'three';
import { BuildingCategory, BuildingVariant } from './types';
import { BattleMode } from '../../types';
export const BUILDING_SIZE = new Vector3(1.0, 1.0, 1.0);
export const BUILDING_DEFAULT_RADIUS = 4;
export const BUILDINGS_SHARED_CONFIG = {
    [BuildingVariant.Ammunition]: {
        category: BuildingCategory.Resources,
        cost: 100,
        minLevel: 3,
    },
    [BuildingVariant.Wall]: {
        category: BuildingCategory.Defense,
        cost: 30,
        minLevel: 2,
    },
    [BuildingVariant.Generator]: {
        category: BuildingCategory.Resources,
        cost: 60,
        restricted: true,
        limit: 4,
    },
    [BuildingVariant.Trap]: {
        category: BuildingCategory.Defense,
        cost: 140,
        minLevel: 4,
    },
    [BuildingVariant.SpawnerSmall]: {
        category: BuildingCategory.Spawners,
        cost: 100,
        mode: BattleMode.Online,
    },
    [BuildingVariant.SpawnerMedium]: {
        category: BuildingCategory.Spawners,
        cost: 200,
        minLevel: 5,
        mode: BattleMode.Online,
    },
    [BuildingVariant.SpawnerLarge]: {
        category: BuildingCategory.Spawners,
        cost: 300,
        minLevel: 9,
        mode: BattleMode.Online,
    },
    [BuildingVariant.Radar]: {
        category: BuildingCategory.Defense,
        cost: 80,
        minLevel: 8,
    },
    [BuildingVariant.TowerFire]: {
        category: BuildingCategory.Towers,
        cost: 80,
    },
    [BuildingVariant.TowerFrost]: {
        category: BuildingCategory.Towers,
        cost: 100,
        minLevel: 4,
    },
    [BuildingVariant.TowerLaser]: {
        category: BuildingCategory.Towers,
        cost: 180,
        minLevel: 6,
    },
};
