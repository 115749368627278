import cn from 'classnames';
import React from 'react';
import { Interactive } from '../../../../../core/ui/components/interactive';
import styles from './styles.module.scss';
export const Button = ({ children, onClick, disabled, icon, view = 'default', size = 'medium', }) => {
    const IconComponent = icon;
    return (React.createElement(Interactive, { disabled: disabled, onClick: onClick, className: cn(styles.button, styles[size], styles[view], {
            [styles.disabled]: disabled,
        }) },
        IconComponent && (React.createElement(IconComponent, null)),
        children));
};
