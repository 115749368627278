import { BuildingSpawner } from '..';
import { ModelType } from '../../../../../../../core/assets/types';
import './resources';
export class BuildingSpawnerLarge extends BuildingSpawner {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingSpawnerLarge,
        }, schema);
    }
}
