import React from 'react';
import { useDroid } from '../../hooks/use-droid';
import { Indicator } from '~/client/rooms/battle/entity/ui/components/indicator';
import { DroidMessage } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
import IconBoost from './icons/boost.svg';
export const Indicators = () => {
    const droid = useDroid();
    return (React.createElement(Indicator, { entity: droid, message: DroidMessage.Boost },
        React.createElement(IconBoost, null)));
};
