import { Vector3, Raycaster, DirectionalLight } from 'three';
import { BATTLE_SCENE_BACKGROUND_COLOR, BATTLE_SCENE_CAMERA_ANGLE, BATTLE_SCENE_LIGHT, BATTLE_SCENE_CAMERA_DISTANCE, BATTLE_SCENE_CAMERA_TARGET_SCREEN_HEIGHT, } from './const';
import { SceneLayer } from './types';
import { Device } from '../../../core/device';
import { Scene } from '../../../core/scene';
import { Snap } from '../../../core/snap';
import { BUILDING_MODELS } from '../entity/building/const';
import { BuildingFactory } from '../entity/building/factory';
import { DroidFactory } from '../entity/unit/npc/droid/factory';
import { MobFactory } from '../entity/unit/npc/mob/factory';
import { Player } from '../entity/unit/player';
import { MaterialType } from '~/client/core/assets/materials/types';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { DeviceType } from '~/shared/core/device/types';
export class BattleScene extends Scene {
    constructor(battle) {
        super({
            light: BATTLE_SCENE_LIGHT,
            backgroundColor: BATTLE_SCENE_BACKGROUND_COLOR,
        });
        this.buildingsLinks = new Map();
        this.selectedBuilding = null;
        this.hoveredBuilding = null;
        this.links = new Map();
        this.snaps = new Map();
        this.raycaster = new Raycaster();
        this.cameraTarget = null;
        this.onMouseClick = this.onMouseClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchWorld = this.onTouchWorld.bind(this);
        this.onScreenRelease = this.onScreenRelease.bind(this);
        this.onBattleStart = this.onBattleStart.bind(this);
        this.onBattleFinish = this.onBattleFinish.bind(this);
        this.battle = battle;
        this.raycaster.layers.enable(SceneLayer.Building);
        this.camera.layers.disable(SceneLayer.Marker);
        this.generateBuildingsPreview();
        this.addLocalLight();
        this.battle.events.onStart.on(this.onBattleStart);
        this.battle.events.onFinish.on(this.onBattleFinish);
        this.battle.state.listen('paused', (paused) => {
            this.paused = paused;
        });
        Device.events.onScreenRelease.on(this.onScreenRelease);
    }
    destroy() {
        this.toggleMouseControls(false);
        this.removeEntities();
        Device.events.onScreenRelease.off(this.onScreenRelease);
        this.battle.events.onStart.off(this.onBattleStart);
        this.battle.events.onFinish.off(this.onBattleFinish);
        super.destroy();
    }
    onBattleStart() {
        this.toggleMouseControls(true);
    }
    onBattleFinish() {
        this.toggleMouseControls(false);
    }
    toggleMouseControls(state) {
        const method = state ? 'on' : 'off';
        if (Device.type === DeviceType.Mobile) {
            InputTouch.events.onTouchWorld[method](this.onTouchWorld);
        }
        else {
            InputMouse.events.onMouseClickWorld[method](this.onMouseClick);
            InputMouse.events.onMouseMoveWorld[method](this.onMouseMove);
        }
    }
    setCameraTarget(target) {
        this.cameraTarget = target;
    }
    syncCamera() {
        if (!this.cameraTarget) {
            return;
        }
        const screenSize = Device.getScreenSize();
        const zoom = Math.sqrt(screenSize.y / BATTLE_SCENE_CAMERA_TARGET_SCREEN_HEIGHT);
        const distance = BATTLE_SCENE_CAMERA_DISTANCE * Math.min(1.0, zoom);
        const target = Device.type === DeviceType.Mobile
            ? this.cameraTarget.position.clone().sub({ x: 0.5, y: 0, z: 0.5 })
            : this.cameraTarget.position;
        this.camera.position
            .copy(this.cameraTarget.position)
            .add({
            x: distance * Math.sin(BATTLE_SCENE_CAMERA_ANGLE),
            y: distance,
            z: distance * Math.cos(BATTLE_SCENE_CAMERA_ANGLE),
        });
        this.camera.lookAt(target);
    }
    linkEntity(id, entity) {
        this.links.set(id, entity);
    }
    unlinkEntity(id) {
        this.links.delete(id);
    }
    getEntity(id) {
        var _a;
        return ((_a = this.links.get(id)) !== null && _a !== void 0 ? _a : null);
    }
    onScreenRelease() {
        // To apply actual schema
        setTimeout(() => {
            this.links.forEach((entity) => {
                entity.synchronize();
            });
        });
    }
    onTouchWorld(touch) {
        const building = this.getBuildingByNormalizedPosition(touch.normalizedPosition);
        if (building === this.selectedBuilding) {
            return;
        }
        touch.events.onRelease.on(() => {
            if (touch.shifted) {
                return;
            }
            if (this.selectedBuilding) {
                this.selectedBuilding.clickOutside();
                this.selectedBuilding = null;
            }
            if (building &&
                // TODO: Unsubscribe from event on building destroy
                !building.disposed) {
                const toHandle = building.click();
                if (toHandle) {
                    this.selectedBuilding = building;
                }
            }
        });
    }
    onMouseClick() {
        if (this.hoveredBuilding) {
            if (this.hoveredBuilding === this.selectedBuilding) {
                return;
            }
            if (this.selectedBuilding) {
                this.selectedBuilding.clickOutside();
                this.selectedBuilding = null;
            }
            const toHandle = this.hoveredBuilding.click();
            if (toHandle) {
                this.selectedBuilding = this.hoveredBuilding;
            }
        }
        else if (this.selectedBuilding) {
            this.selectedBuilding.clickOutside();
            this.selectedBuilding = null;
        }
    }
    onMouseMove() {
        const building = this.getBuildingByNormalizedPosition(InputMouse.normalizedPosition);
        if (building === this.hoveredBuilding) {
            return;
        }
        if (this.hoveredBuilding) {
            this.hoveredBuilding.unhover();
            this.hoveredBuilding = null;
        }
        if (building) {
            this.hoveredBuilding = building;
            this.hoveredBuilding.hover();
        }
        InputMouse.setCursorPointer(Boolean(this.hoveredBuilding));
    }
    getBuildingByNormalizedPosition(position) {
        var _a;
        let building = null;
        this.raycaster.setFromCamera(position, this.camera);
        const [intersect] = this.raycaster.intersectObject(this);
        if (intersect) {
            let parent = intersect.object;
            while (!building) {
                if (!parent) {
                    break;
                }
                building = (_a = this.buildingsLinks.get(parent.uuid)) !== null && _a !== void 0 ? _a : null;
                parent = parent.parent;
            }
        }
        return building;
    }
    bindSchemaWithEntities() {
        this.battle.state.players.onAdd((player) => {
            if (!player.bot) {
                new Player(this.battle, player);
            }
        });
        this.battle.state.droids.onAdd((droid) => {
            DroidFactory.create(this.battle, droid);
        });
        this.battle.state.mobs.onAdd((mob) => {
            MobFactory.create(this.battle, mob);
        });
        this.battle.state.buildings.onAdd((building) => {
            BuildingFactory.create(this.battle, building);
        });
    }
    removeEntities() {
        this.links.forEach((entity) => {
            entity.destroy();
        });
        this.links.clear();
    }
    addLocalLight() {
        const directionalLight = new DirectionalLight(0xffffff, 1.0);
        directionalLight.position.set(0, 10, 5);
        this.add(directionalLight);
    }
    generateBuildingsPreview() {
        const snap = new Snap({
            camera: new Vector3(6, 3, 6),
            width: 60,
            height: 80,
        });
        Object.entries(BUILDING_MODELS).forEach(([variant, model]) => {
            const object = snap.addModel({
                model,
                material: MaterialType.Building,
            });
            this.snaps.set(variant, snap.export());
            snap.remove(object);
        });
        snap.remove();
    }
}
