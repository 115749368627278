import { CORPSE_DESTROY_DELAY } from './const';
import { MaterialType } from '~/client/core/assets/materials/types';
import { Model } from '~/client/core/render-item/model';
import { Delay } from '~/shared/core/delay';
export class Corpse extends Model {
    constructor(battle, config) {
        super(battle.scene, Object.assign(Object.assign({}, config), { material: MaterialType.Unit }));
        this.destroyDelay = new Delay(CORPSE_DESTROY_DELAY);
        this.battle = battle;
        this.destroyDelay.next();
        this.animator.play('die', {
            repeat: false,
            clamp: true,
        });
        this.onSceneUpdate = this.onSceneUpdate.bind(this);
        this.battle.scene.events.onUpdate.on(this.onSceneUpdate);
    }
    destroy() {
        this.battle.scene.events.onUpdate.off(this.onSceneUpdate);
        super.destroy();
    }
    onSceneUpdate() {
        this.update();
        if (this.destroyDelay.isPast()) {
            this.destroy();
        }
    }
}
