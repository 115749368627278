import { Assets } from '~/client/core/assets';
import { AudioMode, AudioType } from '~/client/core/audio/types';
import audioGameover from './gameover.mp3';
import audioWin from './win.mp3';
Assets.addAudio(AudioType.Win, {
    path: audioWin,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
Assets.addAudio(AudioType.GameOver, {
    path: audioGameover,
    mode: AudioMode.Track2D,
    volume: 1.0,
});
