import { Request } from '../request';
import { SystemMessageUtils } from '../ui/components/system-message/utils';
import { SystemMessageType } from '../ui/components/system-message/utils/types';
import { LOGGER_ROUTE_PATH } from '~/shared/core/logger/const';
import { LoggerUtils } from '~/shared/core/logger/utils';
export class Logger {
    static addListeners() {
        window.addEventListener('error', (event) => {
            event.preventDefault();
            this.error('System error', event.error);
            this.renderError(event.message);
        });
        window.addEventListener('unhandledrejection', (event) => {
            var _a;
            event.preventDefault();
            this.error('System error', event.reason);
            this.renderError((_a = event.reason) === null || _a === void 0 ? void 0 : _a.message);
        });
    }
    static renderError(message) {
        SystemMessageUtils.render('system-error', {
            type: SystemMessageType.Error,
            sign: '☠️',
            title: 'System Error',
            message,
        });
    }
    static debug(message) {
        console.debug(message);
    }
    static warn(message) {
        console.warn(message);
        this.send({
            type: 'warn',
            message,
        });
    }
    static error(message, error) {
        const fullMessage = LoggerUtils.formatErrorMessage(message, error);
        console.error(fullMessage);
        this.send({
            type: 'error',
            message,
            error: error && {
                message: error.message,
                stack: error.stack,
            },
        });
    }
    static send(payload) {
        Request.post(LOGGER_ROUTE_PATH, payload)
            .catch(() => { });
    }
}
