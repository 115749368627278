import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { SCREEN_BUTTON_HOTKEYS, SCREEN_BUTTON_ICON_MAP } from './const';
import { ActiveScreen } from '../../../../state';
import { Device } from '~/client/core/device';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Interactive } from '~/client/core/ui/components/interactive';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
export const ScreenButton = ({ type }) => {
    const [screen, setScreen] = useRecoilState(ActiveScreen);
    const language = useLanguage();
    const IconComponent = SCREEN_BUTTON_ICON_MAP[type];
    const hotkey = SCREEN_BUTTON_HOTKEYS[type];
    const active = screen === type;
    const handleClick = useCallback(() => {
        setScreen(active ? null : type);
    }, [active]);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.button, {
            [styles.active]: active,
        }) },
        IconComponent && React.createElement(IconComponent, null),
        Device.type === DeviceType.Desktop && hotkey && !active && (React.createElement("div", { className: styles.hotkey },
            language('OrHold'),
            " ",
            React.createElement("b", null, hotkey)))));
};
