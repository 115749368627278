import cn from 'classnames';
import React, { useCallback } from 'react';
import { useBuilding } from '../../../../hooks/use-building';
import { Device } from '~/client/core/device';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Cost } from '~/client/rooms/battle/ui/components/cost';
import { useSelfPlayerSchema } from '~/client/rooms/battle/ui/hooks/use-self-player-schema';
import { DeviceType } from '~/shared/core/device/types';
import styles from './styles.module.scss';
import IconLock from './icons/lock.svg';
export const Control = ({ icon, message, hotkey, cost, disabled, minLevel, }) => {
    const player = useSelfPlayerSchema();
    const building = useBuilding();
    const hasLevel = !minLevel || minLevel <= player.level;
    const IconComponent = icon;
    const handleClick = useCallback(() => {
        building.doAction(message);
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, disabled: disabled || !hasLevel, className: cn(styles.container, {
            [styles.disabled]: disabled || !hasLevel,
        }) },
        disabled ? (React.createElement(IconComponent, null)) : (React.createElement(Section, { direction: 'vertical', align: 'center', gap: 8 }, hasLevel ? (React.createElement(React.Fragment, null,
            React.createElement(IconComponent, null),
            !!cost && (React.createElement("div", { className: styles.addon },
                React.createElement(Cost, { amount: cost }))))) : (React.createElement(React.Fragment, null,
            React.createElement(IconLock, null),
            React.createElement("div", { className: styles.minLevel },
                React.createElement("b", null, minLevel),
                " LVL"))))),
        Device.type === DeviceType.Desktop && hotkey && (React.createElement("div", { className: styles.hotkey },
            "KEY ",
            React.createElement("b", null, hotkey.replace('Key', ''))))));
};
