import { ShaderMaterial, Vector2 } from 'three';
import { SHADER_FOG_FRAGMENT } from './shader/fragment';
import { SHADER_FOG_VERTEX } from './shader/vertex';
import { FOG_MAX_LIGHTS } from '../../const';
import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
Assets.addMaterial(MaterialType.Fog, new ShaderMaterial({
    vertexShader: SHADER_FOG_VERTEX,
    fragmentShader: SHADER_FOG_FRAGMENT,
    depthTest: false,
    transparent: true,
    uniforms: {
        maskRadius: { value: Array(FOG_MAX_LIGHTS).fill(1.0) },
        maskPosition: { value: Array(FOG_MAX_LIGHTS).fill(new Vector2()) },
        lightsCount: { value: 0 },
    },
}));
