import { Object3D, PlaneGeometry } from 'three';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { TILE_FACES, TILE_OFFSET } from './const';
import { VectorUtils } from '~/shared/core/vector-utils';
import { TERRAIN_SIZE } from '~/shared/rooms/battle/terrain/const';
export class Tile {
    constructor(battle, schema) {
        this.schema = schema;
        this.terrain = battle.terrain;
        const indexes = this.getVisibleIndexes();
        if (indexes.length === 0) {
            return;
        }
        const facesKey = indexes.join('-');
        let group = this.terrain.tileGroups.find((group) => (group.biomeType === this.schema.biome
            && group.biomeIndex === this.schema.index
            && group.facesKey === facesKey));
        if (!group) {
            group = {
                positionY: this.schema.position.y,
                biomeType: this.schema.biome,
                biomeIndex: this.schema.index,
                facesKey,
                children: [],
                geometry: this.makeGeometry(indexes),
            };
            this.terrain.tileGroups.push(group);
        }
        group.children.push(this);
    }
    destroy() {
        this.mesh.removeFromParent();
        this.mesh.dispose();
    }
    initialize(mesh, index) {
        this.mesh = mesh;
        this.index = index;
        this.updateMatrix();
    }
    updateMatrix() {
        const object = new Object3D();
        object.position.copy(this.schema.position);
        object.updateMatrix();
        this.mesh.setMatrixAt(this.index, object.matrix);
    }
    makeGeometry(indexes) {
        const faces = indexes.map((index) => this.makeFace(index));
        return mergeGeometries(faces);
    }
    getVisibleIndexes() {
        const indexes = [];
        TILE_FACES.forEach(({ p }, index) => {
            const hidden = this.hasNeighbor({
                x: p.x * 2,
                y: p.y * 2,
                z: p.z * 2,
            });
            if (!hidden) {
                indexes.push(index);
            }
        });
        if (indexes.length === 0) {
            return [];
        }
        if (indexes.includes(1) || indexes.includes(2)) {
            return [0, 1, 2];
        }
        else {
            return [0];
        }
    }
    makeFace(index) {
        const { p, r } = TILE_FACES[index];
        const face = new PlaneGeometry();
        face.rotateX(r.x);
        face.rotateY(r.y);
        face.rotateZ(r.z);
        face.translate(p.x, p.y, p.z);
        return face;
    }
    hasNeighbor(offset) {
        const position = VectorUtils.reuse(this.schema.position).add(offset);
        if (position.x >= TERRAIN_SIZE.x
            || position.x < 0
            || position.z >= TERRAIN_SIZE.z
            || position.z < 0) {
            return true;
        }
        const tile = this.terrain.battle.state.terrain.tiles.get(VectorUtils.encode3d(position));
        return Boolean(tile && (TILE_OFFSET[tile.biome] === TILE_OFFSET[this.schema.biome]));
    }
}
