import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { MenuButton } from './menu-button';
import { MenuScreen } from './menu-screen';
import { NewBuildingsAvailable } from './new-buildings-available';
import { ScreenButton } from './screen-button';
import { ActiveScreen } from '../../../state';
import { ScreenType } from '../../../types';
import { Debug } from '../../debug';
import { Device } from '~/client/core/device';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Section } from '~/client/core/ui/components/section';
import { BuilderScreen } from '~/client/rooms/battle/builder/ui/components/builder-screen';
import { ButtonAttack } from '~/client/rooms/battle/entity/unit/player/ui/components/button-attack';
import { DamageEffect } from '~/client/rooms/battle/entity/unit/player/ui/components/damage-effect';
import { PlayerInfoPanel } from '~/client/rooms/battle/entity/unit/player/ui/components/info-panel';
import { Joystick } from '~/client/rooms/battle/entity/unit/player/ui/components/joystick';
import { LevelPanel } from '~/client/rooms/battle/entity/unit/player/ui/components/level-panel';
import { SkillPanel } from '~/client/rooms/battle/entity/unit/player/ui/components/skill-panel';
import { UpgradesScreen } from '~/client/rooms/battle/entity/unit/player/ui/components/upgrades-screen';
import { Minimap } from '~/client/rooms/battle/minimap/ui';
import { WaveInfoPanel } from '~/client/rooms/battle/wave/ui/components/info-panel';
import { DeviceType } from '~/shared/core/device/types';
import { BattleMode } from '~/shared/rooms/battle/types';
import styles from './styles.module.scss';
export const StageStarted = () => {
    const battle = useRoom();
    const [screen, setScreen] = useRecoilState(ActiveScreen);
    const front = battle.state.mode === BattleMode.Single;
    const active = Boolean(screen);
    const refSkip = useRef(true);
    useEffect(() => {
        return () => {
            setScreen(null);
        };
    }, []);
    useEffect(() => {
        if (refSkip.current) {
            refSkip.current = false;
            return;
        }
        battle.togglePause(active);
    }, [active]);
    return (React.createElement("div", { className: styles.screen },
        React.createElement("div", { className: styles.grid },
            React.createElement("div", { className: cn(styles.column, styles.left) },
                React.createElement(Section, { direction: "vertical", gap: 32, className: styles.front },
                    React.createElement(LevelPanel, null),
                    React.createElement("div", { className: styles.buttons },
                        React.createElement(ScreenButton, { type: ScreenType.Upgrades }),
                        React.createElement(ScreenButton, { type: ScreenType.Builder }))),
                React.createElement(Debug, null),
                Device.type === DeviceType.Mobile && (React.createElement("div", { className: cn({ [styles.front]: front }) },
                    React.createElement(Joystick, null)))),
            React.createElement("div", { className: cn(styles.column, styles.center) },
                React.createElement(Section, { direction: 'vertical', gap: 16 },
                    React.createElement(Section, { direction: 'horizontal', gap: 16, className: styles.front },
                        React.createElement(PlayerInfoPanel, null),
                        battle.state.mode === BattleMode.Single && (React.createElement(WaveInfoPanel, null))),
                    React.createElement(NewBuildingsAvailable, null)),
                Device.type === DeviceType.Desktop && React.createElement(SkillPanel, null)),
            React.createElement("div", { className: cn(styles.column, styles.right) },
                React.createElement(Section, { direction: "vertical", className: styles.front },
                    React.createElement(MenuButton, null),
                    React.createElement(Minimap, null)),
                Device.type === DeviceType.Mobile && (React.createElement("div", { className: cn({ [styles.front]: front }) },
                    React.createElement(ButtonAttack, null),
                    React.createElement(SkillPanel, null))))),
        React.createElement("div", { className: cn(styles.overlay, screen && styles[screen.toLowerCase()], { [styles.visible]: screen }) },
            React.createElement(BuilderScreen, null),
            React.createElement(UpgradesScreen, null),
            React.createElement(MenuScreen, null)),
        React.createElement(DamageEffect, null)));
};
