import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { INVENTORY_ITEM_ICON_MAP } from '../../const';
import { useInventorySlot } from '../../hooks/use-inventory-slot';
import { ActiveInventorySelection } from '../../state';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const InventorySlot = ({ type, slot }) => {
    const language = useLanguage();
    const item = useInventorySlot(type, slot);
    const [, setActiveInventorySelection] = useRecoilState(ActiveInventorySelection);
    const IconComponent = item && INVENTORY_ITEM_ICON_MAP[item];
    const name = item && language(`${item}Name`);
    const description = item && language(`${item}Description`);
    const handleClickSlot = useCallback(() => {
        setActiveInventorySelection({ type, slot });
    }, []);
    return (React.createElement(Interactive, { className: styles.wrapper, onClick: handleClickSlot },
        React.createElement("div", { className: cn(styles.frame, {
                [styles.empty]: !item,
            }) }, IconComponent ? (React.createElement(IconComponent, { className: styles.icon })) : (React.createElement("div", { className: styles.dot }))),
        React.createElement("div", { className: styles.info }, item ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.name }, name),
            React.createElement("div", { className: styles.description }, description))) : (React.createElement("div", { className: styles.placeholder }, "Empty slot")))));
};
