import { Object3D } from 'three';
import { DECORATION_PRESETS } from './const';
import { Logger } from '../../../../core/logger';
import { Utils } from '~/shared/core/utils';
import './resources';
export class Decoration {
    constructor(battle, schema) {
        const { models, scale } = DECORATION_PRESETS[schema.type];
        const model = models[schema.index];
        if (!model) {
            Logger.warn('Invalid decoration model index');
            return;
        }
        this.schema = schema;
        this.rotation = 2 * Math.PI * Math.random();
        this.scale = Utils.randomFloatBetween(...scale);
        let list = battle.terrain.decorations.get(model);
        if (!list) {
            list = [];
            battle.terrain.decorations.set(model, list);
        }
        list.push(this);
        this.schema.onRemove(() => {
            // Instanced mesh can't be removed
            this.scale = 0.001;
            this.updateMatrix();
            this.mesh.instanceMatrix.needsUpdate = true;
        });
    }
    destroy() {
        this.mesh.removeFromParent();
        this.mesh.dispose();
    }
    initialize(mesh, index) {
        this.mesh = mesh;
        this.index = index;
        this.updateMatrix();
    }
    updateMatrix() {
        const object = new Object3D();
        object.scale.set(this.scale, this.scale, this.scale);
        object.position.copy(this.schema.position);
        object.rotateY(this.rotation);
        object.updateMatrix();
        this.mesh.setMatrixAt(this.index, object.matrix);
    }
}
