import cn from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
export const Parameter = ({ label, value, warn, alert }) => (React.createElement("div", { className: cn(styles.parameter, {
        [styles.warn]: warn,
        [styles.alert]: alert,
    }) },
    value,
    ' ',
    label));
