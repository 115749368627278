import { Building } from '../..';
import { BUILDING_SPAWNER_ENEMIES_LIMIT } from '~/shared/rooms/battle/entity/building/variants/spawner/const';
export class BuildingSpawner extends Building {
    constructor(battle, config, schema) {
        super(battle, config, schema);
        if (this.selfOwn) {
            this.addParameter(schema, 'spawned', {
                transform: (value) => `${value}/${BUILDING_SPAWNER_ENEMIES_LIMIT}`,
            });
        }
    }
}
