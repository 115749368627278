export var UpgradeVariant;
(function (UpgradeVariant) {
    UpgradeVariant["AttackDamage"] = "AttackDamage";
    UpgradeVariant["AttackRecovery"] = "AttackRecovery";
    UpgradeVariant["AttackRadius"] = "AttackRadius";
    UpgradeVariant["MainMaxHealth"] = "MainMaxHealth";
    UpgradeVariant["MainSpeed"] = "MainSpeed";
    UpgradeVariant["MainDroid"] = "MainDroid";
    UpgradeVariant["BuildSpeed"] = "BuildSpeed";
    UpgradeVariant["BuildRadius"] = "BuildRadius";
})(UpgradeVariant || (UpgradeVariant = {}));
export var UpgradeCategory;
(function (UpgradeCategory) {
    UpgradeCategory["Main"] = "Main";
    UpgradeCategory["Attack"] = "Attack";
    UpgradeCategory["Build"] = "Build";
})(UpgradeCategory || (UpgradeCategory = {}));
