import cn from 'classnames';
import React, { useCallback } from 'react';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const Option = ({ value, active, onClick, }) => {
    const handleClick = useCallback(onClick, []);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.value, {
            [styles.active]: active,
        }) }, value));
};
