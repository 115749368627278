import { Assets } from '../../../../../../../core/assets';
import { ModelType } from '../../../../../../../core/assets/types';
import bin from './ammunition.bin';
import gltf from './ammunition.gltf';
Assets.addModel(ModelType.BuildingAmmunition, {
    gltf,
    bin: {
        origin: 'ammunition.bin',
        path: bin,
    },
});
