export var SettingsType;
(function (SettingsType) {
    SettingsType["FpsLimit"] = "FpsLimit";
    SettingsType["Resolution"] = "Resolution";
    SettingsType["VisualEffects"] = "VisualEffects";
    SettingsType["AudioEffects"] = "AudioEffects";
    SettingsType["Tutorial"] = "Tutorial";
    SettingsType["Fullscreen"] = "Fullscreen";
    SettingsType["Language"] = "Language";
})(SettingsType || (SettingsType = {}));
export var SettingsSwitch;
(function (SettingsSwitch) {
    SettingsSwitch["On"] = "On";
    SettingsSwitch["Off"] = "Off";
})(SettingsSwitch || (SettingsSwitch = {}));
