import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ActiveOverlay } from '../../overlays/state';
import { OverlayType } from '../../overlays/types';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
import IconLogin from './icons/login.svg';
export const LoginButton = () => {
    const [, setOverlay] = useRecoilState(ActiveOverlay);
    const language = useLanguage();
    const handleClick = useCallback(() => {
        setOverlay(OverlayType.Login);
    }, []);
    return (React.createElement(Interactive, { onClick: handleClick, className: cn(styles.container, styles.primary) },
        React.createElement("div", { className: styles.icon },
            React.createElement(IconLogin, null)),
        React.createElement("div", { className: styles.label }, language('Profile'))));
};
