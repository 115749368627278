import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { usePlayer } from '../../hooks/use-player';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { Indicator } from '~/client/rooms/battle/entity/ui/components/indicator';
import { EntityMessage } from '~/shared/rooms/battle/entity/types';
import { PlayerMessage } from '~/shared/rooms/battle/entity/unit/player/types';
import styles from './styles.module.scss';
import IconHeal from './icons/heal.svg';
import IconNoConnect from './icons/no-connect.svg';
export const Indicators = () => {
    const player = usePlayer();
    const audio = useAudio();
    const connected = useSchemaValue(player.schema, 'connected');
    const onLevelUp = () => {
        if (player.selfOwn) {
            audio.play(AudioType.LevelUp);
        }
    };
    const onHeal = () => {
        if (player.selfOwn) {
            // audio.play(AudioType.Repair);
        }
    };
    return (React.createElement(React.Fragment, null,
        !connected && (React.createElement("div", { className: styles.noConnect },
            React.createElement(IconNoConnect, null))),
        React.createElement(Indicator, { entity: player, message: PlayerMessage.LevelUp, onDisplay: onLevelUp }, "LEVEL UP"),
        React.createElement(Indicator, { entity: player, message: EntityMessage.Heal, onDisplay: onHeal },
            React.createElement(IconHeal, null))));
};
