import { BuildingTower } from '..';
import { ModelType } from '../../../../../../../core/assets/types';
import './resources';
export class BuildingTowerFire extends BuildingTower {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingTowerFire,
        }, schema);
        if (this.selfOwn) {
            this.addParameter(schema.weapon.shot, 'damage');
            this.addParameter(schema.weapon.shot, 'speed', {
                transform: (value) => value * 10,
            });
        }
    }
}
