import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect } from 'react';
import { formatTime } from './utils/format-time';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const WaveInfoPanel = () => {
    const battle = useRoom();
    const audio = useAudio();
    const waves = battle.state.wave;
    if (!waves) {
        return null;
    }
    const going = useSchemaValue(waves, 'going');
    const number = useSchemaValue(waves, 'number');
    const timeleft = useSchemaValue(waves, 'timeleft');
    const mobsLeft = useSchemaValue(waves, 'mobsLeft');
    const paused = useSchemaValue(waves, 'paused');
    const timeleftSeconds = Math.ceil(timeleft / 1000);
    useEffect(() => {
        if (!paused && timeleftSeconds <= 3 && timeleftSeconds > 0) {
            audio.play(AudioType.WaveTick);
        }
    }, [paused, timeleftSeconds]);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: cn(styles.number, {
                [styles.going]: going,
            }) }, number),
        React.createElement("div", { className: styles.info }, going ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.value }, mobsLeft.toString().padStart(2, '0')),
            React.createElement("div", { className: styles.label },
                mobsLeft > 1 ? 'enemies' : 'enemy',
                React.createElement("br", null),
                "left"))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.value }, formatTime(timeleft)),
            React.createElement("div", { className: styles.label },
                "until to",
                React.createElement("br", null),
                "wave start"))))));
};
