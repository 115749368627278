import { RepeatWrapping, TextureLoader } from 'three';
export class AssetsMaterials {
    static add(type, material) {
        if (this.list.has(type)) {
            throw Error(`Material '${type}' is already registered`);
        }
        material.name = type;
        this.list.set(type, material);
    }
    static get(type) {
        const material = this.list.get(type);
        if (!material) {
            throw Error(`Unregistered material '${type}'`);
        }
        return material;
    }
    static createTexture(path) {
        const texture = this.loader.load(path);
        texture.colorSpace = 'srgb';
        texture.generateMipmaps = false;
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.flipY = false;
        return texture;
    }
}
AssetsMaterials.loader = new TextureLoader();
AssetsMaterials.list = new Map();
