import { BuildingTower } from '..';
import { ModelType } from '../../../../../../../core/assets/types';
import './resources';
export class BuildingTowerFrost extends BuildingTower {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.BuildingTowerFrost,
        }, schema);
        if (this.selfOwn) {
            this.addParameter(schema.weapon.shot, 'freeze', {
                transform: (value) => `${(value / 1000).toFixed(1)} s`,
            });
            this.addParameter(schema.weapon.shot, 'speed', {
                transform: (value) => value * 10,
            });
        }
    }
}
