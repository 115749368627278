import { Assets } from '../../../../../../../../../core/assets';
import { ModelType } from '../../../../../../../../../core/assets/types';
import bin from './golem.bin';
import gltf from './golem.gltf';
Assets.addModel(ModelType.MobGolem, {
    gltf,
    bin: {
        origin: 'golem.bin',
        path: bin,
    },
});
