var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AudioLoader } from 'three';
export class AssetsAudios {
    static add(type, config) {
        if (this.list.has(type)) {
            throw Error(`Audio '${type}' is already registered`);
        }
        this.list.set(type, config);
    }
    static get() {
        return this.audios;
    }
    static load(manager) {
        const loaderAudio = new AudioLoader(manager);
        const tasks = [];
        this.list.forEach((_a, type) => {
            var { path } = _a, data = __rest(_a, ["path"]);
            const entries = typeof path === 'string' ? [path] : path;
            entries.forEach((entry) => {
                tasks.push(loaderAudio.loadAsync(entry).then((buffer) => {
                    const audio = this.audios.get(type);
                    if (audio) {
                        audio.buffer.push(buffer);
                    }
                    else {
                        this.audios.set(type, Object.assign({ buffer: [buffer] }, data));
                    }
                }));
            });
        });
        return tasks;
    }
}
AssetsAudios.audios = new Map();
AssetsAudios.list = new Map();
