import { ActionArea } from './action-area';
import { BUILDING_HOTKEYS, BUILDING_MODEL_UPGRADE_PREFIX } from './const';
import { Platform } from './platform';
import { BuildingPanelVisible } from './types';
import { BuildingUI } from './ui';
import { Entity } from '..';
import { SceneLayer } from '../../scene/types';
import { Light } from '../../terrain/fog/light';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { Logger } from '~/client/core/logger';
import { Model } from '~/client/core/render-item/model';
import { EventStream } from '~/shared/core/event-stream';
import { BUILDING_DEFAULT_RADIUS } from '~/shared/rooms/battle/entity/building/const';
import { BattleMode } from '~/shared/rooms/battle/types';
import './resources';
export class Building extends Entity {
    constructor(battle, config, schema) {
        const renderItem = new Model(battle.scene, Object.assign(Object.assign({}, config), { material: MaterialType.Building }));
        renderItem.eachMeshes((mesh) => {
            mesh.layers.set(SceneLayer.Building);
        });
        super(battle, renderItem, schema);
        this.parameters = [];
        this.light = null;
        this.actionArea = null;
        this.platform = null;
        this.panelVisible = BuildingPanelVisible.None;
        this.alert = null;
        this.events = {
            onChangeAlert: new EventStream(),
            onChangePanelVisible: new EventStream(),
            onUpdate: new EventStream(),
        };
        this.onBattleFinish = this.onBattleFinish.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.setUI(BuildingUI);
        if (this.battle.state.mode === BattleMode.Online) {
            this.createPlatform();
        }
        this.createMarker();
        this.listenReadyState();
        this.listenUpgradeLevel();
        if (this.selfOwn) {
            this.createLight();
            this.addDefaultParameters();
            this.listenSchemaRadius();
        }
        this.battle.scene.buildingsLinks.set(this.renderItem.id, this);
        this.battle.events.onFinish.on(this.onBattleFinish);
    }
    destroy() {
        this.removePlatform();
        this.battle.scene.audio.play3D(AudioType.BuildingBreak, {
            parent: this.battle.scene,
            position: this.renderItem.position,
        });
        if (this.selfOwn) {
            this.removeLight();
            this.removeActionArea();
            InputKeyboard.events.onKeyDown.off(this.onKeyDown);
        }
        if (this.battle.scene.selectedBuilding === this) {
            this.battle.scene.selectedBuilding = null;
        }
        if (this.battle.scene.hoveredBuilding === this) {
            this.battle.scene.hoveredBuilding = null;
        }
        this.battle.scene.buildingsLinks.delete(this.renderItem.id);
        this.battle.events.onFinish.off(this.onBattleFinish);
        super.destroy();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        this.updatePlatform();
    }
    onBattleFinish() {
        this.setPanelVisible(BuildingPanelVisible.None);
        this.renderItem.animator.paused = true;
    }
    createLight() {
        var _a;
        if (!this.light) {
            this.light = new Light(this.battle, {
                radius: (_a = this.schema.radius) !== null && _a !== void 0 ? _a : BUILDING_DEFAULT_RADIUS,
                target: this.renderItem,
            });
        }
    }
    updateLight(radius) {
        var _a;
        (_a = this.light) === null || _a === void 0 ? void 0 : _a.setRadius(radius !== null && radius !== void 0 ? radius : BUILDING_DEFAULT_RADIUS);
    }
    removeLight() {
        if (this.light) {
            this.light.destroy();
            this.light = null;
        }
    }
    createPlatform() {
        if (!this.platform) {
            this.platform = new Platform(this);
        }
    }
    updatePlatform() {
        var _a;
        (_a = this.platform) === null || _a === void 0 ? void 0 : _a.update();
    }
    removePlatform() {
        if (this.platform) {
            this.platform.destroy();
            this.platform = null;
        }
    }
    addDefaultParameters() {
        this.addParameter(this.schema.live, 'health');
        // Push to end
        setTimeout(() => {
            if (this.schema.delay) {
                this.addParameter(this.schema, 'delay', {
                    transform: (value) => `${(value / 1000).toFixed(1)} s`,
                });
            }
            if (this.schema.radius) {
                this.addParameter(this.schema, 'radius', {
                    transform: (value) => `${value} m`,
                });
            }
        });
    }
    addParameter(schema, prop, config = {}) {
        const parameter = Object.assign(Object.assign({}, config), { prop,
            schema });
        // @ts-ignore
        this.parameters.push(parameter);
    }
    onReady() {
        if (this.selfOwn) {
            InputKeyboard.events.onKeyDown.on(this.onKeyDown);
        }
    }
    listenReadyState() {
        const unlisten = this.schema.listen('buildProgress', (buildProgress) => {
            if (buildProgress >= 100) {
                this.onReady();
                setTimeout(() => unlisten());
            }
        });
    }
    listenUpgradeLevel() {
        const root = this.renderItem.getRoot();
        if (root) {
            this.schema.listen('level', (level) => {
                root.children.forEach((object) => {
                    if (object.name.indexOf(BUILDING_MODEL_UPGRADE_PREFIX) === 0) {
                        const targetLevel = Number(object.name.replace(BUILDING_MODEL_UPGRADE_PREFIX, ''));
                        if (targetLevel >= 1 && targetLevel <= this.schema.maxLevel) {
                            object.visible = level >= targetLevel;
                        }
                    }
                });
            });
        }
        else {
            Logger.warn(`Invalid root node of model for building '${this.schema.variant}'`);
        }
    }
    listenSchemaRadius() {
        this.schema.listen('radius', (radius) => {
            this.updateLight(radius);
            if (radius) {
                if (this.actionArea) {
                    this.updateActionArea(radius);
                }
                else {
                    this.createActionArea(radius);
                }
            }
            else {
                this.removeActionArea();
            }
        });
    }
    createActionArea(radius) {
        if (!this.actionArea) {
            this.actionArea = new ActionArea(this, radius);
        }
    }
    updateActionArea(radius) {
        var _a;
        (_a = this.actionArea) === null || _a === void 0 ? void 0 : _a.setRadius(radius);
    }
    removeActionArea() {
        if (this.actionArea) {
            this.actionArea.destroy();
            this.actionArea = null;
        }
    }
    setPanelVisible(state) {
        this.panelVisible = state;
        if (this.actionArea) {
            const actionAreaVisible = state !== BuildingPanelVisible.None;
            this.actionArea.setVisible(actionAreaVisible);
        }
        this.events.onChangePanelVisible.invoke(state);
    }
    onKeyDown(event) {
        if (this.battle.state.paused) {
            return;
        }
        if (this.battle.scene.selectedBuilding) {
            if (this.battle.scene.selectedBuilding !== this) {
                return;
            }
        }
        else if (this.battle.scene.hoveredBuilding !== this) {
            return;
        }
        const message = BUILDING_HOTKEYS[event.code];
        if (message) {
            event.preventDefault();
            this.doAction(message);
        }
    }
    doAction(action) {
        this.messages.send(action, void {});
    }
    click() {
        if (!this.selfOwn ||
            this.schema.buildProgress < 100 ||
            this.battle.builder.isBuild()) {
            return false;
        }
        this.setPanelVisible(BuildingPanelVisible.Full);
        this.battle.scene.audio.play2D(AudioType.Click);
        return true;
    }
    clickOutside() {
        if (this.panelVisible === BuildingPanelVisible.Full) {
            this.setPanelVisible(BuildingPanelVisible.None);
        }
    }
    hover() {
        if (this.battle.builder.isBuild()
            || this.panelVisible !== BuildingPanelVisible.None) {
            return;
        }
        this.setPanelVisible(BuildingPanelVisible.Compact);
    }
    unhover() {
        if (this.panelVisible !== BuildingPanelVisible.Compact) {
            return;
        }
        this.setPanelVisible(BuildingPanelVisible.None);
    }
    addAlert(label) {
        this.alert = label;
        this.events.onChangeAlert.invoke(label);
    }
    removeAlert() {
        this.alert = null;
        this.events.onChangeAlert.invoke(null);
    }
    onDamage() {
        this.battle.scene.audio.play3D(AudioType.BuildingHit, {
            parent: this.battle.scene,
            position: this.renderItem.position,
        });
    }
}
