/**
 * Camera
 */
export const BATTLE_SCENE_CAMERA_DISTANCE = 60;
export const BATTLE_SCENE_CAMERA_TARGET_SCREEN_HEIGHT = 800;
export const BATTLE_SCENE_CAMERA_ANGLE = Math.PI / 4;
/**
 * Misc
 */
export const BATTLE_SCENE_LIGHT = 0xffffff;
export const BATTLE_SCENE_BACKGROUND_COLOR = 0x4a456b;
