import React, { useEffect, useState } from 'react';
import { Leader } from './leader';
import { Record } from './record';
import { useRoom } from '../../../../../../core/room/ui/hooks/use-room';
import { useUserData } from '../../../hooks/use-user-data';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Scrollbar } from '~/client/core/ui/components/scrollbar';
import { RelayRequest } from '~/shared/rooms/relay/types';
import styles from './styles.module.scss';
export const PageLeaderboard = () => {
    const room = useRoom();
    const authorized = useUserData('authorized');
    const language = useLanguage();
    const [currentPlace, setCurrentPlace] = useState(0);
    const [total, setTotal] = useState(0);
    const [leaders, setLeaders] = useState([]);
    const [others, setOthers] = useState([]);
    useEffect(() => {
        room
            .sendRequest(RelayRequest.GetLeaderboard)
            .then((result) => {
            setCurrentPlace(result.currentPlace);
            setTotal(result.total);
            setLeaders(result.records.splice(0, 3));
            setOthers(result.records);
        });
    }, []);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.leaders }, leaders.map((leader) => (React.createElement(Leader, Object.assign({ key: leader.place }, leader))))),
        authorized && (React.createElement("div", { className: styles.current },
            language('YouRanked'),
            React.createElement("div", { className: styles.value }, currentPlace),
            language('PlaceOf'),
            React.createElement("div", { className: styles.value }, total))),
        React.createElement(Scrollbar, null,
            React.createElement("div", { className: styles.list }, others.map((other) => (React.createElement(Record, Object.assign({ key: other.place }, other))))))));
};
