import React from 'react';
import { Button } from '../../../../button';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import styles from './styles.module.scss';
export const Confirmation = ({ text, onConfirm, onDecline, }) => {
    const language = useLanguage();
    return (React.createElement(Section, { direction: "vertical", gap: 24, align: "center" },
        React.createElement("div", { className: styles.text }, text),
        React.createElement(Section, { direction: "horizontal", gap: 4 },
            React.createElement(Button, { onClick: onConfirm, size: "large" }, language('Yes')),
            React.createElement(Button, { onClick: onDecline, size: "large" }, language('No')))));
};
