import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { Entities } from './components/entities';
import { BATTLE_STAGE_COMPONENT_MAP } from './const';
import { Device } from '../../../core/device';
import { useRoom } from '../../../core/room/ui/hooks/use-room';
import { useRelativeScreen } from '../../../core/ui/hooks/use-relative-screen';
import { BuildControls } from '../builder/ui/components/build-controls';
import { DeviceType } from '~/shared/core/device/types';
import { BattleStage } from '~/shared/rooms/battle/types';
import styles from './styles.module.scss';
export const BattleScreen = () => {
    var _a;
    const { state } = useRoom();
    const stage = (_a = useSchemaValue(state, 'stage')) !== null && _a !== void 0 ? _a : BattleStage.Preparing;
    const StageComponent = BATTLE_STAGE_COMPONENT_MAP[stage];
    const refScreen = useRelativeScreen();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: refScreen, className: styles.screen }, StageComponent && React.createElement(StageComponent, null)),
        stage !== BattleStage.Finished && (React.createElement(React.Fragment, null,
            React.createElement(Entities, null),
            Device.type === DeviceType.Mobile && (React.createElement(BuildControls, null))))));
};
