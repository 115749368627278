import React from 'react';
import { StateIcon } from './state-icon';
import { useMob } from '../../hooks/use-mob';
import { Indicator } from '~/client/rooms/battle/entity/ui/components/indicator';
import { MobMessage } from '~/shared/rooms/battle/entity/unit/npc/mob/types';
import IconBoost from './icons/boost.svg';
export const Indicators = () => {
    const mob = useMob();
    return (React.createElement(React.Fragment, null,
        React.createElement(Indicator, { entity: mob, message: MobMessage.Boost },
            React.createElement(IconBoost, null)),
        React.createElement(StateIcon, null)));
};
