import { Mob } from '../..';
import { ModelType } from '~/client/core/assets/types';
import { Delay } from '~/shared/core/delay';
import IconHeal from './icons/heal.svg';
import './resources';
export class MobDemon extends Mob {
    constructor(battle, schema) {
        super(battle, {
            model: ModelType.MobDemon,
        }, schema);
        this.healDelay = new Delay(500);
        this.listenSchemaHealth();
    }
    onSceneUpdate() {
        super.onSceneUpdate();
        if (this.stateIcon && this.healDelay.isPast()) {
            this.removeStateIcon();
        }
    }
    listenSchemaHealth() {
        let prevHealth = this.schema.live.health;
        this.schema.live.listen('health', (health) => {
            if (health > prevHealth) {
                this.healDelay.next();
                this.setStateIcon(IconHeal);
            }
            prevHealth = health;
        });
    }
}
