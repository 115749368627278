import { Assets } from '../../../../../../../core/assets';
import { ModelType } from '../../../../../../../core/assets/types';
import bin from './generator.bin';
import gltf from './generator.gltf';
Assets.addModel(ModelType.BuildingGenerator, {
    gltf,
    bin: {
        origin: 'generator.bin',
        path: bin,
    },
});
