import { useEffect, useRef, useState } from 'react';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
export function useError() {
    const audio = useAudio();
    const [active, setActive] = useState(false);
    const [message, setMessage] = useState(null);
    const refTimeout = useRef();
    const hide = () => {
        setActive(false);
        // setMessage('');
        if (refTimeout.current) {
            clearTimeout(refTimeout.current);
            refTimeout.current = null;
        }
    };
    const show = (message) => {
        hide();
        audio.play(AudioType.Error);
        setActive(true);
        setMessage(message || null);
        refTimeout.current = setTimeout(hide, 500);
    };
    useEffect(() => {
        return () => {
            hide();
        };
    }, []);
    return {
        active,
        message,
        show,
        hide,
    };
}
