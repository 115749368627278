import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { Param } from '..';
import { Settings } from '~/client/core/settings';
import { Interactive } from '~/client/core/ui/components/interactive';
import styles from './styles.module.scss';
export const ParamBoolean = ({ type, defaultValue, store = true, onChange, }) => {
    const [value, setValue] = useState(defaultValue);
    const handleSwitch = useCallback(() => {
        setValue(!value);
        onChange(!value);
        if (store) {
            Settings.setBoolean(type, !value);
        }
    }, []);
    return (React.createElement(Param, { type: type },
        React.createElement(Interactive, { onClick: handleSwitch, className: cn(styles.switch, {
                [styles.active]: value,
            }) },
            React.createElement("div", { className: styles.thumb }))));
};
