var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Vector3, BufferGeometry } from 'three';
// @ts-ignore
import { MeshLine } from 'three.meshline';
import { Shape } from '..';
import { VectorUtils } from '~/shared/core/vector-utils';
export class Line extends Shape {
    constructor(scene, _a) {
        var { position, positionEnd, material } = _a, config = __rest(_a, ["position", "positionEnd", "material"]);
        const buffer = new BufferGeometry()
            .setFromPoints([]);
        const line = new MeshLine();
        line.setGeometry(buffer);
        super(scene, Object.assign(Object.assign({}, config), { material,
            position, geometry: line }));
        this.positionEnd = new Vector3();
        this.geometry = null;
        this.line = null;
        this.line = line;
        this.geometry = buffer;
        this.positionEnd.copy(positionEnd);
        this.updateGeometry();
    }
    destroy() {
        var _a;
        (_a = this.geometry) === null || _a === void 0 ? void 0 : _a.dispose();
        this.line.dispose();
        super.destroy();
    }
    setPosition(position) {
        super.setPosition(position);
        this.updateGeometry();
    }
    setPositionEnd(position) {
        this.positionEnd.copy(position);
        this.updateGeometry();
    }
    updateGeometry() {
        if (!this.geometry || !this.line) {
            return;
        }
        this.geometry.setFromPoints([
            VectorUtils.zeroVector3,
            this.positionEnd.sub(this.object.position),
        ]);
        this.line.setGeometry(this.geometry);
    }
}
