import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';
import { Parameter } from './parameter';
import { useRoom } from '../../../../../../../../core/room/ui/hooks/use-room';
import { useSelfPlayerSchema } from '../../../../../../ui/hooks/use-self-player-schema';
import { BattleMode } from '~/shared/rooms/battle/types';
import styles from './styles.module.scss';
export const PlayerInfoPanel = () => {
    const battle = useRoom();
    const player = useSelfPlayerSchema();
    const health = useSchemaValue(player.live, 'health');
    const resources = useSchemaValue(player, 'resources');
    const mobs = useSchemaValue(player, 'mobsCount');
    const onlineMode = battle.state.mode === BattleMode.Online;
    return (React.createElement("div", { className: styles.parameters },
        React.createElement(Parameter, { type: "health", value: health }),
        React.createElement(Parameter, { type: "resources", value: resources }),
        onlineMode && React.createElement(Parameter, { type: "mobs", value: mobs })));
};
