export class Progression {
    constructor({ defaultValue, growth, maxLevel, maxValue, }) {
        this.maxLevel = null;
        this.defaultValue = defaultValue;
        if (maxLevel) {
            this.maxLevel = maxLevel;
        }
        if (maxLevel && maxValue) {
            this.growth = (maxValue - defaultValue) / (maxLevel - 1);
        }
        else if (growth) {
            this.growth = defaultValue * growth;
        }
    }
    get(level) {
        if (this.maxLevel && level > this.maxLevel) {
            level = this.maxLevel;
        }
        const value = this.defaultValue + (this.growth * (level - 1));
        return value;
    }
}
