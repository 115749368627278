var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SkinnedMesh } from 'three';
import { Animator } from './animator';
import { MODEL_ROOT } from './const';
import { RenderItem } from '..';
import { Assets } from '../../assets';
export class Model extends RenderItem {
    constructor(scene, _a) {
        var { model, scale, material } = _a, config = __rest(_a, ["model", "scale", "material"]);
        super(scene, Object.assign(Object.assign({}, config), { object: Assets.getClonedModel(model), scale: scale ? {
                x: scale,
                y: scale,
                z: scale,
            } : undefined }));
        this.setMaterial(material);
        this.type = model;
        this.animator = new Animator(this);
    }
    destroy() {
        this.eachMeshes((mesh) => {
            if (mesh instanceof SkinnedMesh) {
                mesh.skeleton.dispose();
            }
        });
        super.destroy();
    }
    update() {
        super.update();
        this.animator.update(this.scene.deltaTime);
    }
    getRoot() {
        return this.object.children.find((object) => object.name === MODEL_ROOT);
    }
}
