var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HOST } from '~/client/const';
export class Request {
    static get(path) {
        return __awaiter(this, void 0, void 0, function* () {
            return fetch(this.getHost() + path).then((res) => res.json());
        });
    }
    static post(path, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return fetch(this.getHost() + path, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
        });
    }
    // TODO: Move to class property and define __MODE for tests
    static getHost() {
        return __MODE === 'production' ? `https://${HOST}` : window.origin;
    }
}
