import { useEffect, useRef, useState } from 'react';
import { Device } from '../../device';
import { InputMouse } from '../../input/mouse';
import { InputTouch } from '../../input/touch';
import { DeviceType } from '~/shared/core/device/types';
export function useDraggable({ active, target, control, onStart, onDrag }) {
    const [dragging, setDragging] = useState(false);
    const refMouseMove = useRef(null);
    const refMouseRelease = useRef(null);
    const handleTouch = (touch) => {
        if (!target.current || !touch.targets.includes(target.current)) {
            return;
        }
        onStart === null || onStart === void 0 ? void 0 : onStart();
        setDragging(true);
        touch.takeUp();
        touch.events.onMove.on(() => {
            const distance = touch.beginPosition.y - touch.position.y;
            onDrag(distance);
        });
        touch.events.onRelease.on(() => {
            setDragging(false);
        });
    };
    const handleMouseClick = (event) => {
        if (!control.current || !event.composedPath().includes(control.current)) {
            return;
        }
        onStart === null || onStart === void 0 ? void 0 : onStart();
        setDragging(true);
        const beginPosition = InputMouse.position.y;
        refMouseMove.current = InputMouse.events.onMouseMove.on(() => {
            const distance = InputMouse.position.y - beginPosition;
            onDrag(distance);
        });
        refMouseRelease.current = InputMouse.events.onMouseRelease.on(() => {
            setDragging(false);
        });
    };
    useEffect(() => {
        if (!active) {
            return;
        }
        if (Device.type === DeviceType.Mobile) {
            const eventTouch = InputTouch.events.onTouch.on(handleTouch);
            return () => {
                eventTouch.off();
            };
        }
        else {
            const eventMouseClick = InputMouse.events.onMouseClick.on(handleMouseClick);
            return () => {
                eventMouseClick.off();
            };
        }
    }, [active]);
    useEffect(() => {
        if (!dragging || Device.type === DeviceType.Mobile) {
            return;
        }
        return () => {
            var _a, _b;
            (_a = refMouseMove.current) === null || _a === void 0 ? void 0 : _a.off();
            refMouseMove.current = null;
            (_b = refMouseRelease.current) === null || _b === void 0 ? void 0 : _b.off();
            refMouseRelease.current = null;
        };
    }, [dragging]);
    return { dragging };
}
