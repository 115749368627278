import { useSchemaListener } from 'colyseus-schema-hooks';
import { useState } from 'react';
import { useUserData } from '../../../../hooks/use-user-data';
import { useBatch } from '~/client/core/ui/hooks/use-batch';
export function useInventoryItems() {
    const inventory = useUserData('inventory');
    const getItems = () => (new Set(inventory.items.values()));
    const [items, setItems] = useState(getItems);
    const update = useBatch(() => {
        setItems(getItems());
    });
    useSchemaListener(inventory.items, {
        onAdd: update,
        onChange: update,
        onRemove: update,
    });
    return items;
}
