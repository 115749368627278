import { Building } from '../..';
import { ShotFactory } from '~/client/rooms/battle/entity/shot/factory';
import { BUILDING_TOWER_MAX_AMMO } from '~/shared/rooms/battle/entity/building/variants/tower/const';
export class BuildingTower extends Building {
    constructor(battle, config, schema) {
        super(battle, config, schema);
        this.shot = ShotFactory.create(battle, schema.weapon.shot);
        if (this.selfOwn) {
            this.addParameter(schema, 'ammo', {
                transform: (value) => `${value}/${BUILDING_TOWER_MAX_AMMO}`,
                alert: (value) => value === 0,
            });
            this.listenSchemaAmmo();
        }
    }
    destroy() {
        this.shot.destroy();
        super.destroy();
    }
    listenSchemaAmmo() {
        this.schema.listen('ammo', (ammo) => {
            if (ammo === 0) {
                this.addAlert('No ammo');
            }
            else {
                this.removeAlert();
            }
        });
    }
}
