import { OverlayLogin } from './login';
import { OverlayRegistration } from './registration';
import { OverlaySettings } from './settings';
import { OverlayType } from './types';
export const OVERLAY_CLOSE_KEY = 'Escape';
export const OVERLAYS = {
    [OverlayType.Settings]: OverlaySettings,
    [OverlayType.Login]: OverlayLogin,
    [OverlayType.Registration]: OverlayRegistration,
};
