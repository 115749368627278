import cn from 'classnames';
import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Device } from '~/client/core/device';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import styles from './styles.module.scss';
export const Translator = ({ renderItem, className, head, body }) => {
    const room = useRoom();
    const refContainer = useRef(null);
    const handleSceneRender = () => {
        if (!refContainer.current) {
            return;
        }
        if (!renderItem.visible) {
            refContainer.current.style.display = 'none';
            return;
        }
        refContainer.current.style.display = '';
        refContainer.current.style.opacity = String(renderItem.getMaterial().opacity);
        const position = renderItem.getPositionOnScreen();
        refContainer.current.style.left = `${position.x}px`;
        refContainer.current.style.top = `${position.y}px`;
        const scale = Math.pow(Device.getScale(), 0.75);
        const size = renderItem.getSizeOnScreen();
        refContainer.current.style.transform = `translate(-50%, -100%) scale(${scale})`;
        refContainer.current.style.width = `${size.x}px`;
        refContainer.current.style.height = `${size.y}px`;
    };
    useLayoutEffect(() => {
        if (refContainer.current && !renderItem.visible) {
            refContainer.current.style.display = 'none';
        }
    }, []);
    useEffect(() => {
        const eventSceneRender = room.scene.events.onRender.on(handleSceneRender);
        return () => {
            eventSceneRender.off();
        };
    }, []);
    return (React.createElement("div", { ref: refContainer, className: cn(styles.translator, className) },
        React.createElement("div", { className: styles.wrapper },
            head && React.createElement("div", { className: styles.head }, head),
            body && React.createElement("div", { className: styles.body }, body))));
};
