import { PageType } from '../../../rooms/relay/ui/components/pages/types';
import { SettingsType } from '../../settings/types';
import { LanguageType } from '../types';
import { OverlayType } from '~/client/rooms/relay/ui/components/overlays/types';
import { UserAuthError } from '~/shared/core/user/auth/types';
import { InventoryItemType } from '~/shared/core/user/inventory/item/types';
import { BuildingCategory, BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
import { DroidVariant } from '~/shared/rooms/battle/entity/unit/npc/droid/types';
import { SkillVariant } from '~/shared/rooms/battle/entity/unit/player/skill/types';
import { TutorialStep } from '~/shared/rooms/battle/entity/unit/player/tutorial/types';
import { UpgradeCategory, UpgradeVariant } from '~/shared/rooms/battle/entity/unit/player/upgrades/types';
import { BattleDifficult } from '~/shared/rooms/battle/types';
const PHRASES = {
    [PageType.Play]: 'Play',
    [PageType.Inventory]: 'Inventory',
    [PageType.Leaderboard]: 'Leaders',
    [OverlayType.Settings]: 'Settings',
    [OverlayType.Login]: 'Log In',
    [OverlayType.Registration]: 'Sign Up',
    Profile: 'Profile',
    LogIn: 'Log In',
    SignUp: 'Sign Up',
    Username: 'Username',
    Password: 'Password',
    [UserAuthError.AlreadyAuthorized]: 'You are already authorized',
    [UserAuthError.ExistUsername]: 'The specified login is taken',
    [UserAuthError.InvalidToken]: 'Invalid authorization token',
    [UserAuthError.InvalidCredentials]: 'Incorrect login or password',
    [UserAuthError.InvalidUsername]: 'Invalid login specified',
    [UserAuthError.InvalidPassword]: 'Invalid password specified',
    MainMenu: 'Main menu',
    Back: 'Back',
    Continue: 'Continue',
    Restart: 'Restart',
    Settings: 'Settings',
    RestartConfirm: 'Do you confirm restart this battle?',
    LeaveConfirm: 'Do you confirm leave this battle?',
    PlayAgain: 'Play again',
    WaitingOpponent: 'Waiting the opponent...',
    OpponentDisconnected: 'The opponent left the battle',
    YouWin: 'You win',
    YouLose: 'You lose',
    YouDied: 'You died',
    [`Difficult${BattleDifficult.Normal}`]: 'Normal mode',
    [`Difficult${BattleDifficult.Normal}Description`]: 'For beginners',
    [`Difficult${BattleDifficult.Hard}`]: 'Hard mode',
    [`Difficult${BattleDifficult.Hard}Description`]: 'For skilled players',
    PlayerOnline: 'Player online',
    PlayersOnline: 'Players online',
    YouRanked: 'You are ranked',
    PlaceOf: 'place out of',
    Earn: 'Earn',
    UnlockOnlineModes: 'to unlock online modes',
    SearchingOpponent: 'Searching for\nan opponent',
    PlaySingle: 'Single play',
    PlaySingleDescription: 'Play infinitive waves mode',
    PlayOnline: 'Online play',
    PlayOnlineDescription: 'Play with a random opponent',
    PlayOnlinePrivate: 'Private play',
    PlayOnlinePrivateDescription: 'Play with your friend',
    YourId: 'Your ID',
    FriendId: 'Friend ID',
    Used: 'Used',
    Select: 'Select',
    Selected: 'Selected',
    Disable: 'Disable',
    Buy: 'Buy',
    OrHold: 'Or hold',
    Yes: 'Yes',
    No: 'No',
    On: 'On',
    Off: 'Off',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    Press: 'Press',
    Hold: 'Hold',
    HintAttack: 'to\nattack',
    [`Hint${TutorialStep.BuildGenerator}`]: 'to\nbuild generator',
    [`Hint${TutorialStep.BuildSpawner}`]: 'to\nbuild spawner',
    [`Hint${TutorialStep.BuildAmmunition}`]: 'to\nbuild ammunition',
    [`Hint${TutorialStep.BuildRadar}`]: 'to\nbuild radar',
    [`Hint${TutorialStep.BuildTower}`]: 'to\nbuild fire tower',
    Experience: 'Experience',
    Upgrades: 'Upgrades',
    SkillDuration: 'Duration %1 s',
    SkillRecovery: 'Recovery %1 s',
    PassiveSkill: 'Passive',
    NewBuildingsAvailable: 'New buildings available',
    [`Category${InventoryItemType.Droid}`]: 'Droid',
    [`Category${InventoryItemType.Skill}`]: 'Skills',
    [`${DroidVariant.Combat}Name`]: 'Combat',
    [`${DroidVariant.Combat}Description`]: 'Attacks enemy mobs within a certain radius',
    [`${DroidVariant.Medic}Name`]: 'Medic',
    [`${DroidVariant.Medic}Description`]: 'Heals the character at a certain interval',
    [`${DroidVariant.Mechanic}Name`]: 'Mechanic',
    [`${DroidVariant.Mechanic}Description`]: 'Repairs damaged buildings within a certain radius',
    [`${SkillVariant.Vampire}Name`]: 'Vampirism',
    [`${SkillVariant.Vampire}Description`]: 'Restores health by 10% of damage dealt',
    [`${SkillVariant.BoostSpeed}Name`]: 'Boost speed',
    [`${SkillVariant.BoostSpeed}Description`]: 'Increases speed by 50%',
    [`${SkillVariant.InspirationDroid}Name`]: 'Inspiration droid',
    [`${SkillVariant.InspirationDroid}Description`]: 'Increases droid characteristics by 30%',
    [`${SkillVariant.InspirationMobs}Name`]: 'Inspiration mobs',
    [`${SkillVariant.InspirationMobs}Description`]: 'Increases mobs damage and speed by 10%',
    [`${SkillVariant.InspirationTowers}Name`]: 'Inspiration towers',
    [`${SkillVariant.InspirationTowers}Description`]: 'Increases towers damage and attack speed by 20%',
    [`${SkillVariant.Sacrifice}Name`]: 'Sacrifice',
    [`${SkillVariant.Sacrifice}Description`]: 'Kills your mobs and increase your health',
    [`${SkillVariant.DiscountWalls}Name`]: 'Discount walls',
    [`${SkillVariant.DiscountWalls}Description`]: 'Reduces walls cost by 50%',
    [`${SkillVariant.DamageReflection}Name`]: 'Damage reflection',
    [`${SkillVariant.DamageReflection}Description`]: 'Reflects 10% of damage taken',
    [`${SkillVariant.BoostGenerators}Name`]: 'Boost generators',
    [`${SkillVariant.BoostGenerators}Description`]: 'Increases resource generation speed by 10%',
    [`${SkillVariant.CallingMobs}Name`]: 'Calling mobs',
    [`${SkillVariant.CallingMobs}Description`]: 'Spawns mobs near character',
    [`${SkillVariant.AttackRecovery}Name`]: 'Fast attacks',
    [`${SkillVariant.AttackRecovery}Description`]: 'Increases attack recovery speed by 15%',
    [`${SkillVariant.HighDamage}Name`]: 'High damage',
    [`${SkillVariant.HighDamage}Description`]: 'Increases character damage by 15%',
    [BuildingCategory.Defense]: 'Defense',
    [BuildingCategory.Spawners]: 'Spawners',
    [BuildingCategory.Resources]: 'Resources',
    [BuildingCategory.Towers]: 'Towers',
    [`${BuildingVariant.Wall}Name`]: 'Wall',
    [`${BuildingVariant.Wall}Description`]: '',
    [`${BuildingVariant.Ammunition}Name`]: 'Ammunition',
    [`${BuildingVariant.Ammunition}Description`]: '',
    [`${BuildingVariant.Radar}Name`]: 'Radar',
    [`${BuildingVariant.Radar}Description`]: '',
    [`${BuildingVariant.Trap}Name`]: 'Trap',
    [`${BuildingVariant.Trap}Description`]: '',
    [`${BuildingVariant.Generator}Name`]: 'Generator',
    [`${BuildingVariant.Generator}Description`]: '',
    [`${BuildingVariant.SpawnerSmall}Name`]: 'Spawner',
    [`${BuildingVariant.SpawnerSmall}Description`]: '',
    [`${BuildingVariant.SpawnerMedium}Name`]: 'Extra Spawner',
    [`${BuildingVariant.SpawnerMedium}Description`]: '',
    [`${BuildingVariant.SpawnerLarge}Name`]: 'Ultra Spawner',
    [`${BuildingVariant.SpawnerLarge}Description`]: '',
    [`${BuildingVariant.TowerFire}Name`]: 'Fire Tower',
    [`${BuildingVariant.TowerFire}Description`]: '',
    [`${BuildingVariant.TowerFrost}Name`]: 'Frost Tower',
    [`${BuildingVariant.TowerFrost}Description`]: '',
    [`${BuildingVariant.TowerLaser}Name`]: 'Laser Tower',
    [`${BuildingVariant.TowerLaser}Description`]: '',
    [UpgradeCategory.Main]: 'Main',
    [UpgradeCategory.Attack]: 'Attack',
    [UpgradeCategory.Build]: 'Build',
    [UpgradeVariant.MainMaxHealth]: 'Max Health',
    [UpgradeVariant.MainSpeed]: 'Movement Speed',
    [UpgradeVariant.MainDroid]: 'Droid Efficiency',
    [UpgradeVariant.AttackRecovery]: 'Attack Recovery',
    [UpgradeVariant.AttackRadius]: 'Attack Radius',
    [UpgradeVariant.AttackDamage]: 'Damage',
    [UpgradeVariant.BuildSpeed]: 'Build Speed',
    [UpgradeVariant.BuildRadius]: 'Build Radius',
    [SettingsType.FpsLimit]: 'FPS limit',
    [SettingsType.Resolution]: 'Graphic quality',
    [SettingsType.VisualEffects]: 'Visual effects',
    [SettingsType.AudioEffects]: 'Audio effects',
    [SettingsType.Tutorial]: 'Tutorial',
    [SettingsType.Fullscreen]: 'Fullscreen',
    [SettingsType.Language]: 'Language',
    [LanguageType.EN]: 'English',
    [LanguageType.RU]: 'Русский',
    [LanguageType.PT]: 'Português',
    [LanguageType.FR]: 'Français',
};
export default PHRASES;
