import { Vector3 } from 'three';
import { ENTITY_SPEED_MULTIPLIER } from '../const';
export class EntityUtils {
    static getNextPosition(position, velocity, speed, delta) {
        return new Vector3()
            .copy(velocity)
            .normalize()
            .multiplyScalar(delta
            * speed
            * ENTITY_SPEED_MULTIPLIER)
            .add(position);
    }
}
