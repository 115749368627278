export class EventStream {
    constructor() {
        this.handlers = new Set();
    }
    on(handler) {
        this.handlers.add(handler);
        return {
            off: () => {
                this.off(handler);
            },
        };
    }
    off(handler) {
        this.handlers.delete(handler);
    }
    invoke(args) {
        Array.from(this.handlers).forEach((handler) => {
            handler(args);
        });
    }
    clear() {
        this.handlers.clear();
    }
}
