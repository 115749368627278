var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Client as OriginClient } from 'colyseus.js';
import { CLIENT_INSTANCE_CHECK_TIMEOUT } from './const';
import { ClientInstanceMessage } from './types';
import { Device } from '../device';
import { Storage } from '../storage';
import { HOST } from '~/client/const';
export class Client {
    static get mainInstance() {
        return this.instanceId === 0;
    }
    static get sessionId() {
        if (!this.room) {
            throw Error('Undefined current room');
        }
        return this.room.sessionId;
    }
    static connect() {
        const host = __MODE === 'production'
            ? `wss://${HOST}/`
            : `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/`;
        this.client = new OriginClient(host);
    }
    static detectInstance() {
        return __awaiter(this, void 0, void 0, function* () {
            const channel = new BroadcastChannel('DetectInstance');
            channel.addEventListener('message', (event) => {
                if (event.data === ClientInstanceMessage.Check) {
                    if (this.instanceId === 0) {
                        channel.postMessage(ClientInstanceMessage.Detect);
                    }
                }
                else if (event.data === ClientInstanceMessage.Detect) {
                    this.instanceId++;
                }
            });
            channel.postMessage(ClientInstanceMessage.Check);
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(this.instanceId);
                }, CLIENT_INSTANCE_CHECK_TIMEOUT);
            });
        });
    }
    static defineRoom(type, room) {
        this.definedRooms.set(type, room);
    }
    static getDefinedRoom(type) {
        const RoomInstance = this.definedRooms.get(type);
        if (!RoomInstance) {
            throw Error(`Undefined room type ${type}`);
        }
        return RoomInstance;
    }
    static leaveCurrentRoom() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.room || !this.room.connection.isOpen) {
                return;
            }
            const PrevRoomInstance = this.getDefinedRoom(this.room.name);
            if (PrevRoomInstance.allowReconnection) {
                this.removeReconnectionToken();
            }
            yield this.room.leave(true);
        });
    }
    static setRoom(origin) {
        const RoomInstance = this.getDefinedRoom(origin.name);
        if (RoomInstance.allowReconnection) {
            this.setReconnectionToken(origin.reconnectionToken);
        }
        this.room = origin;
        return new RoomInstance(origin);
    }
    static findAndJoinRoom() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastRoomid = this.getLastRoomId();
            if (lastRoomid) {
                this.removeLastRoomId();
                const joined = yield this.joinRoomById(lastRoomid);
                if (joined) {
                    return;
                }
            }
            const token = this.getReconnectionToken();
            if (token) {
                this.removeReconnectionToken();
                const joined = yield this.restoreRoom(token);
                if (joined) {
                    return;
                }
            }
            yield this.joinDefaultRoom();
        });
    }
    static restoreRoom(token) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.setLoading();
                const room = yield this.client.reconnect(token);
                this.setRoom(room);
                return true;
            }
            catch (_a) {
                return false;
            }
        });
    }
    static joinRoomByType(type) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setLoading();
            yield this.leaveCurrentRoom();
            const room = yield this.client.joinOrCreate(type, this.getJoinPayload());
            this.setRoom(room);
        });
    }
    static joinRoomById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.setLoading();
                yield this.leaveCurrentRoom();
                const room = yield this.client.joinById(id, this.getJoinPayload());
                this.setRoom(room);
                return true;
            }
            catch (_a) {
                return false;
            }
        });
    }
    static joinDefaultRoom() {
        return __awaiter(this, void 0, void 0, function* () {
            const defaultRoom = Array.from(this.definedRooms.entries())
                .find(([, RoomInstance]) => RoomInstance.default);
            if (defaultRoom) {
                yield this.joinRoomByType(defaultRoom[0]);
            }
        });
    }
    static getJoinPayload() {
        return {
            device: Device.type,
            token: this.getAuthToken(),
            newbie: this.isNewbie(),
        };
    }
    static getAuthToken() {
        return Storage.get('AuthToken');
    }
    static setAuthToken(token) {
        Storage.set('AuthToken', token);
    }
    static removeAuthToken() {
        Storage.remove('AuthToken');
    }
    static getReconnectionToken() {
        return this.mainInstance
            ? Storage.get('ReconnectionToken')
            : null;
    }
    static setReconnectionToken(token) {
        Storage.set('ReconnectionToken', token);
    }
    static removeReconnectionToken() {
        Storage.remove('ReconnectionToken');
    }
    static getLastRoomId() {
        return Storage.get('LastRoomId');
    }
    static setLastRoomId(token) {
        Storage.set('LastRoomId', token);
    }
    static removeLastRoomId() {
        Storage.remove('LastRoomId');
    }
    static isNewbie() {
        return Storage.get('Newbie') !== 'No';
    }
    static unmarkNewbie() {
        Storage.set('Newbie', 'No');
    }
    static hideLoading() {
        const screen = document.getElementById('game-loading');
        if (!screen) {
            return;
        }
        screen.classList.add('hidding');
        setTimeout(() => screen.classList.add('hidden'), 1000);
    }
    static setLoading(state = 'Loading') {
        const screen = document.getElementById('game-loading');
        if (!screen) {
            return;
        }
        screen.classList.remove('hidding', 'hidden');
        const status = screen.querySelector('.status');
        if (status) {
            status.innerHTML = state;
        }
    }
}
Client.room = null;
Client.definedRooms = new Map();
Client.instanceId = 0;
