import { SkillVariant } from '~/shared/rooms/battle/entity/unit/player/skill/types';
import iconAttackRecovery from './icons/attack-recovery.svg';
import iconBoostGenerators from './icons/boost-generators.svg';
import iconBoostSpeed from './icons/boost-speed.svg';
import iconCallingMobs from './icons/calling-mobs.svg';
import iconDamageReflection from './icons/damage-reflection.svg';
import iconDiscountWalls from './icons/discount-walls.svg';
import iconHighDamage from './icons/high-damage.svg';
import iconInspirationDroid from './icons/inspiration-droid.svg';
import iconInspirationMobs from './icons/inspiration-mobs.svg';
import iconInspirationTowers from './icons/inspiration-towers.svg';
import iconSacrifice from './icons/sacrifice.svg';
import iconVampire from './icons/vampire.svg';
export const SKILL_ICON = {
    [SkillVariant.DiscountWalls]: iconDiscountWalls,
    [SkillVariant.Sacrifice]: iconSacrifice,
    [SkillVariant.BoostGenerators]: iconBoostGenerators,
    [SkillVariant.DamageReflection]: iconDamageReflection,
    [SkillVariant.BoostSpeed]: iconBoostSpeed,
    [SkillVariant.InspirationDroid]: iconInspirationDroid,
    [SkillVariant.InspirationTowers]: iconInspirationTowers,
    [SkillVariant.InspirationMobs]: iconInspirationMobs,
    [SkillVariant.HighDamage]: iconHighDamage,
    [SkillVariant.CallingMobs]: iconCallingMobs,
    [SkillVariant.Vampire]: iconVampire,
    [SkillVariant.AttackRecovery]: iconAttackRecovery,
};
