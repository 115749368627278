import { Vector3 } from 'three';
import { BUILDINGS_SHARED_CONFIG } from '../../entity/building/const';
import { BuildingVariant } from '../../entity/building/types';
import { SkillVariant } from '../../entity/unit/player/skill/types';
import { UpgradeVariant } from '../../entity/unit/player/upgrades/types';
import { BUILDER_RADIUS, BUILDER_RESTRICTION_LEVEL_GRADE } from '../const';
import { VectorUtils } from '~/shared/core/vector-utils';
export class BuilderUtils {
    static isLimitExceeded(variant, player, buildings) {
        const { limit } = BUILDINGS_SHARED_CONFIG[variant];
        return limit
            ? this.getBuildingsCount(variant, player, buildings) >= limit
            : false;
    }
    static getRestrictionLevel(variant, player, buildings) {
        const { minLevel, restricted } = BUILDINGS_SHARED_CONFIG[variant];
        if (restricted) {
            const count = this.getBuildingsCount(variant, player, buildings);
            return count * BUILDER_RESTRICTION_LEVEL_GRADE;
        }
        return minLevel !== null && minLevel !== void 0 ? minLevel : 0;
    }
    static hasResourcesToBuild(variant, player) {
        let cost = BUILDINGS_SHARED_CONFIG[variant].cost;
        if (variant === BuildingVariant.Wall) {
            const skill = player.skills.get(SkillVariant.DiscountWalls);
            if (skill) {
                cost *= skill.multiplier;
            }
        }
        return cost <= player.resources;
    }
    static isInsideRadius(position, player) {
        var _a;
        const level = (_a = player.upgrades.get(UpgradeVariant.BuildRadius)) !== null && _a !== void 0 ? _a : 1;
        const distance = VectorUtils.reuse(position).distanceTo(player.position);
        return distance <= BUILDER_RADIUS.get(level);
    }
    static getBuildingsCount(variant, player, buildings) {
        return Array.from(buildings.values()).filter((building) => (building.variant === variant
            && building.ownerId === player.id)).length;
    }
    static isTouchUnit(position, units) {
        const tilePosition = new Vector3().copy(position).round().setY(1);
        return units.some((unit) => (tilePosition.equals(VectorUtils.reuse(unit.position).round().setY(1))));
    }
}
