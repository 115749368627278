import { Assets } from '../../../../../../../core/assets';
import { ModelType } from '../../../../../../../core/assets/types';
import binMushroom1 from './mushroom1.bin';
import gltfMushroom1 from './mushroom1.gltf';
import binMushroom2 from './mushroom2.bin';
import gltfMushroom2 from './mushroom2.gltf';
Assets.addModel(ModelType.Mushroom1, {
    gltf: gltfMushroom1,
    bin: {
        origin: 'mushroom1.bin',
        path: binMushroom1,
    },
});
Assets.addModel(ModelType.Mushroom2, {
    gltf: gltfMushroom2,
    bin: {
        origin: 'mushroom2.bin',
        path: binMushroom2,
    },
});
