import { Vector3, Raycaster, PlaneGeometry, Mesh } from 'three';
import { BuildArea } from './build-area';
import { BuildingPreview } from './building-preview';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { Messages } from '~/client/core/messages';
import { DeviceType } from '~/shared/core/device/types';
import { EventStream } from '~/shared/core/event-stream';
import { VectorUtils } from '~/shared/core/vector-utils';
import { BuilderMessage } from '~/shared/rooms/battle/builder/types';
import { BuilderUtils } from '~/shared/rooms/battle/builder/utils';
import { BUILDINGS_SHARED_CONFIG } from '~/shared/rooms/battle/entity/building/const';
import { BuildingVariant } from '~/shared/rooms/battle/entity/building/types';
import { TERRAIN_SIZE } from '~/shared/rooms/battle/terrain/const';
import './resources';
export class Builder {
    constructor(battle) {
        this.previewBuilding = null;
        this.buildArea = null;
        this.cursor = new Vector3();
        this.variant = null;
        this.events = {
            onToggleBuild: new EventStream(),
            onChangeValidState: new EventStream(),
            onChangeVariant: new EventStream(),
        };
        this.raycaster = new Raycaster();
        this.validState = null;
        this.battle = battle;
        this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);
        this.createGroundHelper();
        this.onSceneUpdate = this.onSceneUpdate.bind(this);
        this.onBattleFinish = this.onBattleFinish.bind(this);
        this.onMouseMoveWorld = this.onMouseMoveWorld.bind(this);
        this.onMouseClickWorld = this.onMouseClickWorld.bind(this);
        this.onTouchWorld = this.onTouchWorld.bind(this);
        this.battle.events.onFinish.on(this.onBattleFinish);
    }
    destroy() {
        this.removeBuildArea();
        this.removePreview();
        this.battle.scene.events.onUpdate.off(this.onSceneUpdate);
        this.battle.events.onFinish.off(this.onBattleFinish);
        if (Device.type === DeviceType.Mobile) {
            InputTouch.events.onTouchWorld.off(this.onTouchWorld);
        }
        else {
            InputMouse.events.onMouseMoveWorld.off(this.onMouseMoveWorld);
            InputMouse.events.onMouseClickWorld.off(this.onMouseClickWorld);
        }
    }
    onSceneUpdate() {
        this.updateBuildState();
    }
    onBattleFinish() {
        this.setVariant(null);
    }
    getAvailableBuildings() {
        const variants = Object.values(BuildingVariant);
        const player = this.battle.getSelfPlayerSchema();
        return variants.filter((variant) => (this.isBuildingEnabled(variant) &&
            !BuilderUtils.isLimitExceeded(variant, player, this.battle.state.buildings) &&
            player.level >= BuilderUtils.getRestrictionLevel(variant, player, this.battle.state.buildings)));
    }
    isBuild() {
        return Boolean(this.variant);
    }
    isBuildingEnabled(variant) {
        const { mode } = BUILDINGS_SHARED_CONFIG[variant];
        return !mode || mode === this.battle.state.mode;
    }
    setVariant(variant) {
        if (variant) {
            const player = this.battle.getSelfPlayerSchema();
            if (BuilderUtils.isLimitExceeded(variant, player, this.battle.state.buildings) ||
                BuilderUtils.getRestrictionLevel(variant, player, this.battle.state.buildings) > player.level ||
                !BuilderUtils.hasResourcesToBuild(variant, player)) {
                return;
            }
        }
        const prevVariant = this.variant;
        this.variant = variant;
        this.validState = null;
        if (this.variant) {
            if (prevVariant) {
                this.removePreview();
                this.createPreview();
            }
            else {
                this.createBuildArea();
                this.createPreview();
                if (Device.type === DeviceType.Mobile) {
                    InputTouch.events.onTouchWorld.on(this.onTouchWorld);
                }
                else {
                    InputMouse.events.onMouseMoveWorld.on(this.onMouseMoveWorld);
                    InputMouse.events.onMouseClickWorld.on(this.onMouseClickWorld);
                }
                this.battle.scene.events.onUpdate.on(this.onSceneUpdate);
                this.events.onToggleBuild.invoke(true);
                if (this.battle.scene.selectedBuilding) {
                    this.battle.scene.selectedBuilding.clickOutside();
                    this.battle.scene.selectedBuilding = null;
                }
            }
        }
        else if (prevVariant) {
            this.removeBuildArea();
            this.removePreview();
            if (Device.type === DeviceType.Mobile) {
                InputTouch.events.onTouchWorld.off(this.onTouchWorld);
            }
            else {
                InputMouse.events.onMouseMoveWorld.off(this.onMouseMoveWorld);
                InputMouse.events.onMouseClickWorld.off(this.onMouseClickWorld);
            }
            this.battle.scene.events.onUpdate.off(this.onSceneUpdate);
            this.events.onToggleBuild.invoke(false);
        }
        this.events.onChangeVariant.invoke(variant);
    }
    createPreview() {
        if (!this.variant || this.previewBuilding) {
            return;
        }
        this.previewBuilding = new BuildingPreview(this.battle, this.variant);
        if (Device.type === DeviceType.Mobile) {
            const player = this.battle.getSelfPlayer();
            this.setPosition(player.renderItem.position.clone().round().add({ x: 1, y: 0, z: 1 }));
        }
        else {
            this.updatePreviewPosition(InputMouse.normalizedPosition);
        }
    }
    updatePreviewPosition(position) {
        if (!this.previewBuilding) {
            return;
        }
        this.raycaster.setFromCamera(position, this.battle.scene.camera);
        const [intersect] = this.raycaster.intersectObject(this.groundHelper);
        if (intersect) {
            this.setPosition(intersect.point.round());
        }
    }
    setPosition(position) {
        if (!this.previewBuilding) {
            return;
        }
        this.cursor.copy(position);
        this.previewBuilding.setPosition(this.cursor);
    }
    removePreview() {
        if (this.previewBuilding) {
            this.previewBuilding.destroy();
            this.previewBuilding = null;
        }
    }
    build() {
        if (!this.variant || !this.validState) {
            return;
        }
        this.battle.scene.audio.play2D(AudioType.Build);
        this.messages.send(BuilderMessage.Build, {
            variant: this.variant,
            position: this.cursor,
        });
        this.setVariant(null);
    }
    createBuildArea() {
        if (this.buildArea) {
            return;
        }
        const player = this.battle.getSelfPlayer();
        this.buildArea = new BuildArea(player);
    }
    removeBuildArea() {
        if (!this.buildArea) {
            return;
        }
        this.buildArea.destroy();
        this.buildArea = null;
    }
    updateBuildState() {
        var _a;
        const validState = (BuilderUtils.isInsideRadius(this.cursor, this.battle.getSelfPlayerSchema())
            && !this.battle.state.terrain.matrix.has(VectorUtils.encode2d(this.cursor))
            && !BuilderUtils.isTouchUnit(this.cursor, [
                ...Array.from(this.battle.state.mobs.values()),
                ...Array.from(this.battle.state.players.values()),
            ]));
        if (this.validState !== validState) {
            this.validState = validState;
            (_a = this.previewBuilding) === null || _a === void 0 ? void 0 : _a.setMaterial(validState ? MaterialType.Building : MaterialType.BuildingInvalid);
            this.events.onChangeValidState.invoke(validState);
        }
    }
    onTouchWorld(touch) {
        touch.events.onMove.on(() => {
            const position = touch.normalizedPosition.clone().add({ x: 0, y: 0.2 });
            this.updatePreviewPosition(position);
        });
    }
    onMouseMoveWorld() {
        this.updatePreviewPosition(InputMouse.normalizedPosition);
    }
    onMouseClickWorld(event) {
        if (event.button === 0) {
            this.build();
        }
        else if (event.button === 2) {
            this.setVariant(null);
        }
    }
    createGroundHelper() {
        this.groundHelper = new Mesh(new PlaneGeometry(TERRAIN_SIZE.x * 2, TERRAIN_SIZE.z * 2));
        this.groundHelper.visible = false;
        this.groundHelper.rotateX(-0.5 * Math.PI);
        this.groundHelper.position.set(TERRAIN_SIZE.x, 1.0, TERRAIN_SIZE.z);
        this.battle.scene.add(this.groundHelper);
    }
}
