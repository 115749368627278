import { Assets } from '~/client/core/assets';
import { AudioType, AudioMode } from '~/client/core/audio/types';
import audioClick from './click.mp3';
import audioError from './error.mp3';
Assets.addAudio(AudioType.Error, {
    path: audioError,
    mode: AudioMode.Track2D,
    volume: 0.4,
});
Assets.addAudio(AudioType.Click, {
    path: audioClick,
    mode: AudioMode.Track2D,
    volume: 0.5,
});
