import { PlayerMessage } from './types';
import { UPGRADE_MAX_LEVEL } from './upgrades/const';
import { Progression } from '../../../../../core/progression';
/**
 * Attack
 */
export const PLAYER_ATTACK_DAMAGE = new Progression({
    defaultValue: 20,
    maxValue: 100,
    maxLevel: UPGRADE_MAX_LEVEL,
});
export const PLAYER_ATTACK_DISTANCE = new Progression({
    defaultValue: 2.0,
    maxValue: 3.0,
    maxLevel: UPGRADE_MAX_LEVEL,
});
export const PLAYER_ATTACK_AREA_DURATION = 400;
/**
 * Misc
 */
export const PLAYER_VISIBLE_DISTANCE = 6;
export const PLAYER_FORCE_MESSAGES = [
    PlayerMessage.Upgrade,
    PlayerMessage.ChangeMovingVector,
    PlayerMessage.ToggleTutorial,
];
