import { Progression } from '../../../core/progression';
import { BuildingVariant } from '../entity/building/types';
import { TutorialStep } from '../entity/unit/player/tutorial/types';
import { UPGRADE_MAX_LEVEL } from '../entity/unit/player/upgrades/const';
import { BattleMode } from '../types';
export const BUILDER_RADIUS = new Progression({
    defaultValue: 3.0,
    maxValue: 5.0,
    maxLevel: UPGRADE_MAX_LEVEL,
});
export const BUILDER_RESTRICTION_LEVEL_GRADE = 3;
export const BUILDER_TUTORIAL_FLOW = {
    [TutorialStep.BuildTower]: {
        need: BuildingVariant.TowerFire,
        strict: true,
        next: TutorialStep.BuildGenerator,
    },
    [TutorialStep.BuildGenerator]: {
        need: BuildingVariant.Generator,
        strict: true,
        next: {
            step: TutorialStep.BuildSpawner,
            onlyIf: BattleMode.Online,
        },
    },
    [TutorialStep.BuildSpawner]: {
        need: BuildingVariant.SpawnerSmall,
        strict: true,
    },
    [TutorialStep.BuildRadar]: {
        need: BuildingVariant.Radar,
    },
    [TutorialStep.BuildAmmunition]: {
        need: BuildingVariant.Ammunition,
    },
};
