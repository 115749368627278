import React, { useState } from 'react';
import { Control } from './control';
import { Section } from '../../../../../../../../core/ui/components/section';
import { BUILDING_HOTKEY_UPGRADE, BUILDING_HOTKEY_REPAIR } from '../../../../const';
import { useBuilding } from '../../../hooks/use-building';
import { useEntityUpdate } from '~/client/rooms/battle/entity/ui/hooks/use-entity-update';
import { BuildingMessage } from '~/shared/rooms/battle/entity/building/types';
import { BuildingUtils } from '~/shared/rooms/battle/entity/building/utils';
import IconDestroy from './icons/destroy.svg';
import IconRepair from './icons/repair.svg';
import IconUpgrade from './icons/upgrade.svg';
export const Controls = () => {
    const building = useBuilding();
    const [upgradeCost, setUpgradeCost] = useState(0);
    const [upgradeDisabled, setUpgradeDisabled] = useState(true);
    const [upgradeMinLevel, setUpgradeMinLevel] = useState(0);
    const [repairCost, setRepairCost] = useState(0);
    const [repairDisabled, setRepairDisabled] = useState(true);
    useEntityUpdate(building, () => {
        setUpgradeCost(BuildingUtils.getUpgradeCost(building.schema));
        setUpgradeDisabled(BuildingUtils.isMaxLevel(building.schema));
        setUpgradeMinLevel(BuildingUtils.getLevelToUpgrade(building.schema));
        setRepairCost(BuildingUtils.getRepairCost(building.schema));
        setRepairDisabled(building.schema.live.health === building.schema.live.maxHealth);
    });
    return (React.createElement(Section, { direction: "horizontal", gap: 1 },
        React.createElement(Control, { message: BuildingMessage.DoUpgrade, icon: IconUpgrade, hotkey: BUILDING_HOTKEY_UPGRADE, cost: upgradeCost, disabled: upgradeDisabled, minLevel: upgradeMinLevel }),
        React.createElement(Control, { message: BuildingMessage.DoRepair, icon: IconRepair, hotkey: BUILDING_HOTKEY_REPAIR, cost: repairCost, disabled: repairDisabled }),
        React.createElement(Control, { message: BuildingMessage.DoBreak, icon: IconDestroy })));
};
